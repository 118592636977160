import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-app-being-processed-dialog",
  templateUrl: "./app-being-processed-dialog.component.html",
  styleUrls: ["./app-being-processed-dialog.component.scss"],
})
export class AppBeingProcessedDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _matDialogRef: MatDialogRef<AppBeingProcessedDialogComponent>
  ) {}

  ngOnInit(): void {}

  close() {
    this._matDialogRef.close();
  }
}
