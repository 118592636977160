<div class="content-d">
    <img src="assets/images/subscriptions/subscribe.png">
  <div translate class="title">subsDialog.needSubscribe</div>
  <div *ngIf="!data || data !== 'submission'" translate class="text bottom-margin">subsDialog.featureBlocked</div>
  <div *ngIf="data && data === 'submission'" translate class="text bottom-margin">dashboard.subscribeText</div>
  <div class="buttons">
    <app-button
    class="button "
    (click)="later()"
    [pinkOnWhite]="true"
    label="{{ 'subsDialog.later' | translate }}"
  ></app-button>
    <app-button
    class="button "
    (onClick)="subscribe()"
    label="{{ 'subsDialog.subscribe' | translate }}"
  ></app-button>
  </div>

</div>
