import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-leaving-confirmation-modal",
  templateUrl: "./leaving-confirmation-modal.component.html",
  styleUrls: ["./leaving-confirmation-modal.component.scss"],
})
export class LeavingConfirmationModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public bodyText: string,
    private dialogRef: MatDialogRef<LeavingConfirmationModalComponent>,
  ) {}

  leave() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}


