import { Injectable } from "@angular/core";
import { Location } from "@angular/common";
import { BehaviorSubject } from "rxjs";
import { PermissionsService } from "./permissions.service";
import { UserService } from "./user.service";

interface IMenuBarItem {
  children?: Array<IMenuBarItem>;
  icon?: string;
  selectedIcon?: string;
  lockedIconSrc?: string;
  id?: string;
  label: string;
  selected?: boolean;
  route?: string;
  locked?: any;
}
@Injectable({
  providedIn: "root",
})

export class NavigationService {
  forceHideBottomNavigationBar = new BehaviorSubject<boolean>(false);
  generalMenuVisible = new BehaviorSubject<boolean>(false);
  languagesMenuVisible = new BehaviorSubject<boolean>(false);
  accountsMenuVisible = new BehaviorSubject<boolean>(false);
  notificationsMenuVisible = new BehaviorSubject<boolean>(false);
  
  menuListContent: Array<IMenuBarItem> = [];

  constructor(
    private location: Location, 
    private permissionService: PermissionsService, 
    private userService: UserService
  ) {
    this.userService.loggedUser.subscribe({
      next: (user: any) => {
        this.populateMenuListContent();
      }
    });
  }

  back(): void {
    this.location.back();
  }

  closeAllNavigationDropdowns() {
    this.generalMenuVisible.next(false);
    this.languagesMenuVisible.next(false);
    this.accountsMenuVisible.next(false);
    this.notificationsMenuVisible.next(false);
  }

  populateMenuListContent() {
    this.menuListContent = [
        {
            id: "dashboard",
            route: "/dashboard",
            label: "layout.sidebar.dashboard",
            icon: "/assets/images/menu-dashboard.svg",
            selectedIcon: "/assets/images/menu-dashboard_pink.svg",
            selected: false,
        },
    ];

    if (this.permissionService.checkMenuItemDashboardPermissions()) {
        this.menuListContent.push({
            id: "statistics",
            route: "statistics",
            label: "layout.sidebar.statistics",
            icon: "/assets/images/menu-insights.svg",
            selectedIcon: "/assets/images/menu-insights_pink.svg",
            selected: false,
        });
    }

    if (this.permissionService.checkMenuItemMyAppsPermissions()) {
        this.menuListContent.push({
            id: "myApps",
            route: "myApps",
            label: "layout.sidebar.myApps",
            icon: "/assets/images/menu-manage-apps.svg",
            selectedIcon: "/assets/images/menu-manage-apps_pink.svg",
            selected: false,
        });
    }

    if (this.permissionService.checkMenuItemWalletPermissions()) {
        this.menuListContent.push({
            id: "wallet",
            route: "/wallet",
            label: "layout.sidebar.wallet",
            icon: "/assets/images/menu-wallet.svg",
            selectedIcon: "/assets/images/menu-wallet_pink.svg",
            selected: false,
        });
    }
}
}
