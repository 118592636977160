import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class MetadataViewService {

  marketInformation: BehaviorSubject<any> = new BehaviorSubject<any>({});

  constructor() { }

}
