import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AutocompleteModule } from '@app/shared/autocomplete/autocomplete.module';
import { ButtonModule } from '@app/shared/button/button.module';
import { DragDropUploadModule } from '@app/shared/drag-drop-upload/drag-drop-upload.module';
import { LoadingSpinnerModule } from '@app/shared/spinner/loading-spinner.module';
import { TextInputModule } from '@app/shared/text-input/text-input.module';
import { TranslateModule } from '@ngx-translate/core';
import { AccountHolderDetailsComponent } from './account-information-form.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatDividerModule,
    TextInputModule,
    AutocompleteModule,
    LoadingSpinnerModule,
    DragDropUploadModule,
    ButtonModule,
    ReactiveFormsModule,
    MatTooltipModule
  ],
  declarations: [
    AccountHolderDetailsComponent
  ],
  exports: [AccountHolderDetailsComponent]
})
export class AccountInformation { }