import { NgModule } from "@angular/core";
import { TextInput } from "./text-input.component";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";


@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [TextInput],
  exports: [TextInput]
})
export class TextInputModule {}
