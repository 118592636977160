<app-loading-spinner 
  [class.onTopBar]="onTopBar" 
  *ngIf="(loadingAccountSwitch | async) && !isMobile" 
  align="horizontal" 
  size="small"> 
</app-loading-spinner>
<div 
  class="button-container" 
  [class.onTopBar]="onTopBar"
  *ngIf="
    accountsList && 
    accountsList.length > 1 &&
    !(loadingAccountSwitch | async)
  "
  (click)="toggleDropdown()"
>
  <div class="icon" *ngIf="!isMobile">
    <img [src]="'/assets/images/switch-account.svg'" />
  </div>

  <div
    *ngIf="showDropdown | async"
    class="dropdown-content"
    [@enterAnimation]
    [delayClickOutsideInit]="true"
    (clickOutside)="closeDropdown()"
  >
      <div 
        *ngIf="isMobile" 
        class="menu-item"
        (click)="onBackClick()"
      >
        <p class="back xxxs" translate><label><</label>common.back</p>
      </div>
      <div
        *ngFor="let account of accountsList"
        class="menu-item"
        [class.selected]="selectedAccount.uid === account.uid"
        (click)="switchAccount(account)"
      >
        <p>
          {{ account.label }}
        </p>
        <img
          *ngIf="selectedAccount.uid === account.uid"
          [src]="isMobile ? '/assets/images/checkmark_on.svg' : '/assets/images/selected-language.svg'"
        />
      </div>
  </div>
</div>
