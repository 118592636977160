import { Observable, of } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from "@angular/common/http";
import { tap } from "rxjs/operators";
import { CacheService } from '@app/core/services/cache.service';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

  constructor(
    public cacheService: CacheService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method !== "GET") {
      this.cacheService.verifyDependenciesGroup(request.url);
      return next.handle(request);
    }

    if (!request.params.get('nocache')) {
      const response = this.cacheService.get(request.urlWithParams);
      if (response) {
        return of(response);
      }
    }

    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          if (!request.urlWithParams.includes('nocache')) {
            this.cacheService.set(request.urlWithParams, event);
          }
        }
      })
    );
  }
}
