import { DecimalPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { Country } from "@app/core/services/countries.service";
import { getFlagUrl } from "@app/core/services/utils.service";
import { DashboardCardsListItem } from "@app/modules/dashboard/dashboard.service";
import { FormCard } from "@app/modules/my-apps/submission/submission.service";
import { environment } from "@env/environment";
import * as moment from "moment";

export interface App {
    name: string;
    packageName: string;
    iconUrl: string;
}

@Pipe({
    name: "joinArrayPrint",
})
export class JoinArrayPrintPipe implements PipeTransform {
    transform(input: Array<any>, sep = ","): string {
        return input.join(sep);
    }
}

@Pipe({
    name: "total",
})
export class TotalPipe implements PipeTransform {
    constructor(private decimalPipe: DecimalPipe) {}
    transform(value: any, useAbbreviations: boolean = true, decimalPlaces: number = 2, absoluteValue: boolean = false): any {
        if (!value) {
            return this.decimalPipe.transform(0, `1.${decimalPlaces}-${decimalPlaces}`);
        }

        const formattedValue: any = (() => {
            if (absoluteValue) {
                return Math.abs(value);
            }

            return value;
        })();

        if (useAbbreviations) {
            const absoluteValue: any = Math.abs(formattedValue);
            if (absoluteValue >= 10000000) {
                return this.decimalPipe.transform(formattedValue / 1000000, "1.0-0") + "M";
            } else if (absoluteValue >= 1000000) {
                return this.decimalPipe.transform(formattedValue / 1000000, "1.1-1") + "M";
            } else if (absoluteValue >= 10000) {
                return this.decimalPipe.transform(formattedValue / 1000, "1.0-0") + "K";
            } else if (absoluteValue >= 1000) {
                return this.decimalPipe.transform(formattedValue / 1000, "1.1-1") + "K";
            } else if (absoluteValue >= 10) {
                return this.decimalPipe.transform(formattedValue, `1.${decimalPlaces}-${decimalPlaces}`);
            }
        }

        return this.decimalPipe.transform(formattedValue, `1.${decimalPlaces}-${decimalPlaces}`);
    }
}

@Pipe({
    name: "skeletonArray",
})
export class SkeletonArrayPipe implements PipeTransform {
    transform(loading: boolean, quantity: number = 5): any[] {
        if (loading) {
            const array = [];
            for (let i = 0; i < quantity; i++) {
                array.push(i);
            }
            return array;
        }
        return [];
    }
}

@Pipe({
    name: "isCurrency",
})
export class IsCurrencyPipe implements PipeTransform {
    transform(unit): boolean {
        return unit && (unit.toLocaleLowerCase() === "appc" || unit.toLocaleLowerCase() === "usd");
    }
}

@Pipe({
    name: "filesDownloadPipe",
})
export class FilesDownloadPipe implements PipeTransform {
    constructor() {}
    transform(url: string): string {
        return environment.name === "china" ? "https://ws.catappult.io/api/utils/fileDownloadPipe?url=" + url : url;
    }
}
@Pipe({
    name: "titleOrPackageNameAppsListFilter",
    pure: false,
})
export class TitleOrPackageNameAppsListFilterPipe implements PipeTransform {
    transform(items: any[], filter: string): any {
        if (!items || !filter) {
            return items;
        }

        return items.filter(
            (item) =>
                (item?.accountApplication?.extras?.title?.toLocaleLowerCase()?.indexOf(filter.toLocaleLowerCase()) !== -1 && item?.accountApplication?.extras?.title) ||
                item?.application?.name?.toLocaleLowerCase()?.indexOf(filter.toLocaleLowerCase()) !== -1
        );
    }
}

@Pipe({
    name: "catappultDate",
    pure: false,
})
export class CatappultDatePipe implements PipeTransform {
    transform(value: any): any {
        return moment(value).format("DD/MM/yyyy");
    }
}

@Pipe({
    name: "catappultTime",
    pure: false,
})
export class CatappultTimePipe implements PipeTransform {
    transform(value: any): any {
        return moment(value).format("HH:mm:ss");
    }
}
@Pipe({
    name: "catappultDateAndTime",
    pure: false,
})
export class CatappultDateAndTimePipe implements PipeTransform {
    transform(value: any): any {
        return moment(value).format("DD/MM/yyyy HH:mm:ss");
    }
}

@Pipe({
    name: "submissionFormCardInteractivityState",
    pure: false,
})
export class SubmissionFormCardInteractivityState implements PipeTransform {
    transform(formCards: FormCard[], cardIndex: number): boolean {
        if (cardIndex === 0) {
            return false;
        }

        if (formCards.slice(0, cardIndex).every((element) => element.done)) {
            return false;
        }

        return true;
    }
}

@Pipe({
    name: "displayType",
})
export class DisplayTypePipe implements PipeTransform {
    transform(type: string): string {
        if (type) {
            switch (type.toLocaleLowerCase()) {
                case "iap":
                    return "wallet.transactionTypes.inAppPurchase";
                case "eskills":
                    return "wallet.transactionTypes.eskills";
                case "topup":
                    return "wallet.transactionTypes.topup";
                case "withdraw":
                    return "wallet.transactionTypes.withdraw";
                case "fee":
                    return "wallet.transactionTypes.fee";
                case "revertediap":
                    return "wallet.transactionTypes.revertedInAppPurchase";
                case "refundediap":
                    return "wallet.transactionTypes.refundedInAppPurchase";
                case "subscription":
                    return "wallet.transactionTypes.subscription";
                case "revertedsubscription":
                    return "wallet.transactionTypes.revertedSubscription";
                case "refundedsubscription":
                    return "wallet.transactionTypes.refundedSubscription";
                case "transfer":
                    return "wallet.transactionTypes.subscriptionPayment";
                case "custompayment":
                    return "wallet.transactionTypes.customPayment";
                default:
                    break;
            }
        }

        return "Unknown";
    }
}

@Pipe({
    name: "toLocaleStringValue",
})
export class ToLocaleStringPipe implements PipeTransform {
    transform(value: any, nrDecimals: number): string {
        return value
            ? value.toLocaleString(undefined, {
                  minimumFractionDigits: nrDecimals,
                  maximumFractionDigits: nrDecimals,
              })
            : "";
    }
}

@Pipe({
    name: "isDebit",
})
export class IsDebitPipe implements PipeTransform {
    transform(type: string): boolean {
        return (
            type &&
            [
                "withdraw",
                "fee",
                "refundediap",
                "revertediap",
                "revertedsubscription",
                "refundedsubscription",
                "transfer",
                "withdrawwallet",
                "withdrawpaypal",
                "withdrawbankaccount",
                "withdrawbankiban",
                "unknownwithdraw",
            ].includes(type.toLowerCase())
        );
    }
}

@Pipe({
    name: "isApproved",
})
export class IsApprovedPipe implements PipeTransform {
    transform(status: string): boolean {
        return status && ["completed", "cancelled"].includes(status.toLowerCase());
    }
}

@Pipe({
    name: "isPending",
})
export class IsPendingPipe implements PipeTransform {
    transform(status: string): boolean {
        return status && ["pending", "pendingapproval"].includes(status.toLowerCase());
    }
}

@Pipe({
    name: "isRejected",
})
export class IsRejectedPipe implements PipeTransform {
    transform(status: string): boolean {
        return status && ["cancelling", "rejected"].includes(status.toLowerCase());
    }
}

@Pipe({
    name: "showPackageInfo",
})
export class ShowPackageInfoPipe implements PipeTransform {
    transform(type: string): boolean {
        return type && ["iap", "subscription", "revertediap", "refundediap", "revertedsubscription", "refundedsubscription"].includes(type.toLowerCase());
    }
}

@Pipe({
    name: "countryCodeFlagIconUrl",
})
export class CountryCodeFlagIconUrlPipe implements PipeTransform {
    transform(countryCode: string): string {
        return getFlagUrl(countryCode);
    }
}

@Pipe({
    name: "appIcon",
})
export class AppIconPipeOld implements PipeTransform {
    transform(package_name: string): string {
        return environment.baseNodeUrlServices + `/api/applications/${package_name}/icon`;
    }
}
@Pipe({
    name: "labelByUid",
})
export class LabelByUidPipe implements PipeTransform {
    transform(uid: string, accounts: any[]): string {
        if (!uid || !accounts) {
            return null;
        }
        const account = accounts.find((x) => x.uid === uid);
        return account ? account.label : "Unknown";
    }
}

@Pipe({
    name: "iconByUid",
})
export class IconByUidPipe implements PipeTransform {
    transform(uid: string, accounts: any[]): string {
        if (!uid || !accounts) {
            return "/assets/images/unknown-square.svg";
        }

        const account = accounts.find((x) => x.uid.includes(uid));
        return account ? account.icon : "/assets/images/unknown-square.svg";
    }
}

@Pipe({
    name: "partnerDisplayName",
})
export class PartnerDisplayNamePipe implements PipeTransform {
    transform(item: any, partners: any[]): string {
        if (!item || !partners.length) {
            return "Unknown";
        }

        const partner = partners.find((partner) => partner.uid.includes(item.name));

        return partner ? partner.label : item.label;
    }
}

@Pipe({
    name: "getLocaleDataFromVersion",
})
export class GetLocaleDataFromVersionPipe implements PipeTransform {
    transform(apk: any, locale: string, prop: string): any {
        if (!apk || !apk.metadata || !apk.metadata.locales || !apk.metadata.locales.length || !locale || !prop) {
            return null;
        }

        return apk.metadata.locales.filter((l) => {
            return l.locale.name === locale;
        })[0]?.[prop];
    }
}

@Pipe({
    name: "isValidNumber",
})
export class isValidNumberPipe implements PipeTransform {
    transform(value: any): boolean {
        return !isNaN(value) && value != Infinity && value != "Infinity";
    }
}

@Pipe({
    name: "countryIcon",
})
export class CountryIconPipe implements PipeTransform {
    transform(_id: string, _values: Country[]): string {
        const value = _values.find((x) => x?.id?.toLowerCase() === _id?.toLowerCase());
        return value ? value.iconUrl : null;
    }
}

@Pipe({
    name: "countryName",
})
export class CountryNamePipe implements PipeTransform {
    transform(_id: string, _values: Country[]): string {
        const value = _values.find((x) => x?.id?.toLowerCase() === _id?.toLowerCase());
        return value ? value.name : _id;
    }
}

@Pipe({
    name: "camelCase",
})
export class CamelCase implements PipeTransform {
    transform(value: string): string {
        let first = value.substr(0, 1).toUpperCase();
        return first + value.substr(1).toLocaleLowerCase();
    }
}
@Pipe({
    name: "countryObject",
})
export class CountryObjectPipe implements PipeTransform {
    transform(_id: string, _values: Country[]): any {
        const value = _values.find((x) => x.id === _id);

        return value ? { id: value.id, label: value.name } : null;
    }
}
@Pipe({
    name: "storeNameList",
})
export class storeNameListPipe implements PipeTransform {
    transform(_id: string, _values: any[]): string {
        const value = _values.find((x) => x?.id?.toLowerCase() === _id?.toLowerCase());
        return value ? value.label : null;
    }
}

@Pipe({
    name: "appIcon",
})
export class AppIconPipe implements PipeTransform {
    transform(packageName: string): string {
        return `${environment.baseNodeUrlServices}/api/applications/${packageName}/icon`;
    }
}

@Pipe({
    name: "appName",
})
export class AppNamePipe implements PipeTransform {
    transform(packageName: string, _values: App[]): string {
        const value = _values.find((x) => x.packageName === packageName);
        return value ? value.name : packageName;
    }
}

@Pipe({
    name: "countriesFilterPipe",
})
export class CountriesFilterPipe implements PipeTransform {
    transform(countries: any[], text: string): any[] {
        return countries.filter((country: any) => country.name.toLocaleLowerCase().includes(text.toLocaleLowerCase()));
    }
}

@Pipe({
    name: "currencySymbol",
})
export class CurrencySimbolPipe implements PipeTransform {
    transform(currency: string): string {
        if (!currency) {
            return "";
        }
        if (currency?.toLowerCase() === "appc") {
            return "APPC";
        }
        return (0).toLocaleString("en", { style: "currency", currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\d/g, "").trim();
    }
}

@Pipe({
    name: "validDateFormat",
})
export class ValidDateFormat implements PipeTransform {
    transform(dateString: any): string {
        if (dateString) {
            return dateString.replace(/\s/, "T");
        }

        return null;
    }
}

@Pipe({
    name: "iconType",
})
export class IconType implements PipeTransform {
    transform(type: any): string {
        switch (type) {
            case "app":
                return "/assets/images/notification-app-icon.svg";
            case "ads":
                return "/assets/images/notification-ads-icon.svg";
            case "wallet":
                return "/assets/images/notification-wallet-icon.svg";
            default:
                return "";
        }
    }
}

@Pipe({
    name: "showIfDateDiffGreaterThan",
})
export class ShowIfDateDiffGreaterThanDaysNumberPipe implements PipeTransform {
    transform(daysLimit: number, fromDate: Date, toDate: Date): boolean {
        if (!fromDate || !toDate || !daysLimit) {
            return false;
        }

        let diff = Math.abs(toDate.getTime() - fromDate.getTime());
        var diffInDays = Math.ceil(diff / (1000 * 3600 * 24));

        if (diffInDays > daysLimit) {
            return true;
        }

        return false;
    }
}

@Pipe({
    name: "showIfDateMonthAndYearAreDiff",
})
export class ShowIfDateMonthAndYearAreDiff implements PipeTransform {
    transform(fromDate: Date, toDate: Date): boolean {
        if (!fromDate || !toDate) {
            return false;
        }

        if (toDate.getMonth() != fromDate.getMonth() || toDate.getFullYear() != fromDate.getFullYear()) {
            return true;
        }

        return false;
    }
}

@Pipe({
    name: "showDashboardCard",
})
export class showDashboardCardPipe implements PipeTransform {
    transform(dashboardCardName: string, dashboardCardList: DashboardCardsListItem[]): boolean {
        if (!dashboardCardName || !dashboardCardList || !dashboardCardList.length) {
            return false;
        }

        if (dashboardCardList.find((x) => x.name === dashboardCardName)) {
            return true;
        }

        return false;
    }
}
