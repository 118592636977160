import { Injectable } from "@angular/core";
import { APIRequestsService } from "@app/core/services/api.requests.service";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class ApplicationsPerformanceCardService {
    loadingSelectedPackageStatisticsSummary: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    errorPackageStatisticsSummary: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    packageStatisticsSummary: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    selectedPackageStatisticsSummary: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    appPerformanceSelected: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private requestsService: APIRequestsService) {}

    getStatisticsSummary() {
        this.loadingSelectedPackageStatisticsSummary.next(true);

        const url = `/api/dashboard/developers/applicationsPerformance`;

        this.requestsService.get(url).subscribe({
            next: (res) => {
                this.packageStatisticsSummary.next(res);
                this.selectedPackageStatisticsSummary.next(this.packageStatisticsSummary?.value[0]);
                this.appPerformanceSelected.subscribe({
                    next: (res) => {
                        if (res) {
                            this.selectedPackageStatisticsSummary.next(this.packageStatisticsSummary?.value.filter((p) => p.packageName === res)[0]);
                        }
                    },
                });
                this.selectedPackageStatisticsSummary.next(this.packageStatisticsSummary?.value[0]);
                this.loadingSelectedPackageStatisticsSummary.next(false);
                this.errorPackageStatisticsSummary.next(false);
            },
            error: (err) => {
                this.errorPackageStatisticsSummary.next(true);
                this.loadingSelectedPackageStatisticsSummary.next(false);
            },
        });
    }
}
