import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";

@Component({
  selector: "app-missing-data-modal",
  templateUrl: "./missing-data-modal.component.html",
  styleUrls: ["./missing-data-modal.component.scss"],
})
export class MissingDataModalComponent implements OnInit {
  loading: boolean= false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialogRef: MatDialogRef<MissingDataModalComponent>,
    private router: Router
  ) {}

  ngOnInit(): void {}

  cancel() {
    this.matDialogRef.close();
    this.router.navigate(["dashboard"]);
  }

  goToAccountSettings() {
    this.matDialogRef.close();
    this.router.navigate(
      ["settings/account"],
      this.data.referer ? { queryParams: { referer: this.data.referer } } : null
    );
  }
}
