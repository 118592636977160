import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { humanize } from "@app/core/services/utils.service";
import { BehaviorSubject } from "rxjs";

@Component({
    selector: "app-drag-drop-upload",
    templateUrl: "./drag-drop-upload.component.html",
    styleUrls: ["./drag-drop-upload.component.scss"],
})
export class DragDropUploadComponent implements OnInit {
    files: any[] = [];
    @Input() uploadedFiles: any[] = [];
    @Input() uploadedFile: any;
    @Input() acceptedFiles: string = "";
    @Input() imgSrc: string = "";
    @Input() title: string = "";
    @Input() horizontal: boolean = false;
    @Input() hideUploader: boolean = false;
    @Input() disabled: boolean = false;
    @Input() showError: boolean = false;
    @Input() multiple: boolean = true;
    @Input() subtitle: string = "";
    @Input() acceptedFilesText: string = "";
    @Input() whiteBackgroundOnUploadedFile = false;
    @Input() clearUploadedFile: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    @Input() loading: false;
    @Input() readOnly: false;
    @Output() sendFiles: EventEmitter<any> = new EventEmitter();
    @Output() deleteUploaded: EventEmitter<any> = new EventEmitter();
    @Output() deleteMultipleUploadedFiles: EventEmitter<any> = new EventEmitter();
    @Output() downloadUploaded: EventEmitter<any> = new EventEmitter();

    ngOnInit() {
        this.clearUploadedFile.subscribe({
            next: (clear: boolean) => {
                if (clear === true) {
                    this.deleteUploadedFile();
                }
            },
        });
    }

    onFileDropped($event) {
        if (this.disabled) {
            return;
        }

        this.prepareFilesList($event);
    }

    fileBrowseHandler(files) {
        if (this.disabled) {
            return;
        }

        this.prepareFilesList(files);
    }

    deleteFile(index: number) {
        if (this.disabled) {
            return;
        }
        this.files.splice(index, 1);
        this.sendFiles.emit(this.files);
    }

    deleteUploadedFile() {
        if (this.uploadedFile || this.files.length > 0) {
            this.uploadedFile = null;
            this.files = [];
            this.deleteUploaded.emit();
        }
    }

    deleteUploadedFiles(index: number) {
        if (this.disabled) {
            return;
        }
        this.uploadedFiles.splice(index, 1);
        this.deleteMultipleUploadedFiles.emit(this.uploadedFiles);
    }

    downloadUploadedFile() {
        this.downloadUploaded.emit();
    }

    humanize(name: any): string {
        return humanize(name);
    }

    prepareFilesList(files: Array<any>) {
        if (!this.multiple) {
            this.files = [];
        }

        for (const item of files) {

          const extension = item.name.split('.').pop();
    
          let extensionFound = false;
    
          for (const acceptedFile of this.acceptedFiles.split(",")) {
            if (acceptedFile.includes(extension) ) {
              extensionFound = true;
            }
          }
    
          if (!extensionFound) {
            continue;
          }
    
          this.files.push(item);
    
          if (!this.multiple) {
            break;
          }
        }

        this.sendFiles.emit(this.files);
    }

    formatBytes(bytes, decimals) {
        if (bytes === 0) {
            return "0 Bytes";
        }

        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals || 2;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }
}
