import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";

@Component({
  selector: "app-payment-needed",
  templateUrl: "./payment-needed.component.html",
  styleUrls: ["./payment-needed.component.scss"],
})
export class PaymentNeededComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data = "",
    private dialogRef: MatDialogRef<PaymentNeededComponent>
  ) {}

  ngOnInit(): void {}

  later() {
    this.dialogRef.close("later");
  }

  subscribe(){
    this.dialogRef.close("subscribe");
  }
}
