import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CacheService {
  cache = new Map<string, any>();

  constructor() {
    // REFRESH CACHED REQUESTS EVERY X SECONDS/MINUTES?
  }

  get(_key: string) {
    return this.cache.get(_key);
  }

  set(_key: string, _value: any) {
    this.cache.set(_key, _value);
  }

  verifyDependenciesGroup(key: string) {
   if (key.includes("/api/wallet")) {
      this.removeCacheEntries("/api/wallet");
    } else if (key.includes("/api/users/notifications")) {
      this.removeCacheEntries("/api/users/notifications");
    } else if (key.includes("/api/applications")) {
      this.removeCacheEntries("/api/applications");
    }
  }

  removeCacheEntries(entry: string) {
    let tempCache = [];

    this.cache.forEach((item: any) => {
      if (item.url.includes(entry)) {
        tempCache.push(item);
      }
    });

    for (let key of tempCache) {
      this.cache.delete(key.url);
    }
  }
}
