import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonDropdownComponent, ButtonDropdownItemComponent } from "./button-dropdown.component";
import { NgClickOutsideDirective } from "ng-click-outside2";

@NgModule({
    declarations: [ButtonDropdownComponent, ButtonDropdownItemComponent],
    imports: [CommonModule, TranslateModule, NgClickOutsideDirective],
    exports: [ButtonDropdownComponent, ButtonDropdownItemComponent],
})
export class ButtonDropdownModule {}
