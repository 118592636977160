import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MobileNavigationBarComponent } from "./mobile-navigation-bar.component";
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [MobileNavigationBarComponent],
  exports: [MobileNavigationBarComponent],
})

export class MobileNavigationBarModule {}
