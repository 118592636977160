import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-its-on-our-side-modal",
  templateUrl: "./its-on-our-side-modal.component.html",
  styleUrls: ["./its-on-our-side-modal.component.scss"],
})
export class ItsOnOurSideComponentModal {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ItsOnOurSideComponentModal>,
  ) {}

  gotIt(){
    this.dialogRef.close("gotIt");
  }
}

