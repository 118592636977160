import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";
import { environment } from "@env/environment";

export interface RequestParameters {
    label: string;
    data: any;
}

@Injectable({
    providedIn: "root",
})
export class APIRequestsService {
    constructor(private httpClient: HttpClient) {}

    get(url: string, parameters: RequestParameters[] = [], nocache: boolean = false, doCatchError: boolean = false, responseType: any = "json") {
        url = environment.baseNodeUrlServices + url;
        let httpParams = new HttpParams();
        for (const parameter of parameters) {
            httpParams = httpParams.set(parameter.label, parameter.data);
        }
        if (nocache) {
            httpParams = httpParams.set("nocache", true.toString());
        }
        if (doCatchError) {
            return this.httpClient.get(url, { params: httpParams }).pipe(
                catchError((err: any) => {
                    return of([]);
                })
            );
        }

        return this.httpClient.get(url, {
            params: httpParams,
            responseType: responseType,
        });
    }

    post(url: string, parameters: RequestParameters[] = [], nocache: boolean = false, responseType: any = "json") {
        url = environment.baseNodeUrlServices + url;

        const formData = new FormData();
        for (const parameter of parameters) {
            if (["boolean", "number"].includes(typeof parameter.data)) {
                formData.append(parameter.label, parameter.data.toString());
                continue;
            }
            formData.append(parameter.label, parameter.data);
        }
        let httpParams = new HttpParams();
        if (nocache) {
            httpParams = httpParams.set("nocache", true.toString());
        }
        return this.httpClient.post(url, formData, {
            params: httpParams,
            responseType: responseType,
        });
    }

    patch(url: string, parameters: RequestParameters[] = [], nocache: boolean = false, responseType: any = "json") {
        url = environment.baseNodeUrlServices + url;

        const formData = new FormData();
        for (const parameter of parameters) {
            if (["boolean", "number"].includes(typeof parameter.data)) {
                formData.append(parameter.label, parameter.data.toString());
                continue;
            }
            formData.append(parameter.label, parameter.data);
        }
        let httpParams = new HttpParams();
        if (nocache) {
            httpParams = httpParams.set("nocache", true.toString());
        }
        return this.httpClient.patch(url, formData, {
            params: httpParams,
            responseType: responseType,
        });
    }

    delete(url: string, parameters: RequestParameters[] = [], nocache: boolean = false, doCatchError: boolean = false, responseType: any = "json") {
        url = environment.baseNodeUrlServices + url;
        let httpParams = new HttpParams();
        for (const parameter of parameters) {
            httpParams = httpParams.set(parameter.label, parameter.data);
        }
        if (nocache) {
            httpParams = httpParams.set("nocache", true.toString());
        }
        if (doCatchError) {
            return this.httpClient.get(url, { params: httpParams }).pipe(
                catchError((err: any) => {
                    return of([]);
                })
            );
        }

        return this.httpClient.delete(url, {
            params: httpParams,
            responseType: responseType,
        });
    }
}
