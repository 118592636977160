<a
  *ngIf="targetExternal && !disabled"
  tabindex="0"
  [href]="targetExternal"
  target="_blank"
  rel="noopener"
  [ngClass]="classes"
  [class.noClick]="showButtonSpinner || readOnly"
  [attr.disabled]="disabled ? 'disabled' : null"
  (click)="callOnClick()"
>
  <div class="text-container">
    <img *ngIf="imageSrc && !showButtonSpinner" [src]="imageSrc" />
    <ng-lottie [class.small]="small" *ngIf="showButtonSpinner" [options]="button_spinner"></ng-lottie>
    <span *ngIf="!showButtonSpinner" translate>{{ label }}</span> 
  </div>
</a>

<a
  *ngIf="targetInternal && !disabled"
  tabindex="0"
  [routerLink]="targetInternal"
  [ngClass]="classes"
  [class.noClick]="showButtonSpinner || readOnly"
  [attr.disabled]="disabled ? 'disabled' : null"
  (click)="callOnClick()"
>
  <div class="text-container" >
    <img *ngIf="imageSrc && !showButtonSpinner" [src]="imageSrc" />
    <ng-lottie [class.small]="small" *ngIf="showButtonSpinner" [options]="button_spinner"></ng-lottie>
    <span *ngIf="!showButtonSpinner" translate>{{ label }}</span> 
  </div>
</a>

<div
  *ngIf="(!targetExternal && !targetInternal) || disabled"
  tabindex="0"
  [ngClass]="classes"
  [class.noClick]="showButtonSpinner || readOnly"
  [attr.disabled]="disabled ? 'disabled' : null"
  (click)="callOnClick()"
>
  <div class="text-container" >
    <img *ngIf="imageSrc && !showButtonSpinner" [src]="imageSrc" />
    <ng-lottie [class.small]="small" *ngIf="showButtonSpinner" [options]="button_spinner"></ng-lottie>
    <span *ngIf="!showButtonSpinner" translate>{{ label }}</span> 
  </div>
</div>
