import { NgModule } from "@angular/core";
import { DndDirective } from "./dnd.directive";

@NgModule({
    declarations: [
        DndDirective,
    ],
    imports: [],
    exports: [
        DndDirective
    ]
})
export class DirectivesModule {}
