<div class="content">
    <img src="assets/images/subscriptions/subscribe.png">
  <div translate class="title">subsDialog.launched.title</div>
  <div translate class="text bottom-margin">subsDialog.appReady</div>
 

</div>
 <div class="buttons">
    <app-button
    class="button"
    (onClick)="later()"
    [pinkOnWhite]="true"
    label="{{ 'subsDialog.later' | translate }}"
  ></app-button>
    <app-button
    class="button"
    (onClick)="subscribe()"
    label="{{ 'subsDialog.subscribe' | translate }}"
  ></app-button>
  </div>