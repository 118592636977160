import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { BehaviorSubject, Observable } from "rxjs";

const httpOptions = {
    headers: new HttpHeaders({
        "Content-Type": "application/json",
    }),
};

@Injectable({
    providedIn: "root",
})
export class ApiService {
    baseUrl = environment.baseNodeUrlServices;
    currency = new BehaviorSubject<string>("usd");

    constructor(private http: HttpClient) {}

    getPaymentMethods(accountUid: string): Observable<any> {
        const url: string = (() => {
            if (accountUid) {
                return `/api/accounts/subscriptions/payments/methods?accountUid=${accountUid}`;
            }
            return "/api/accounts/subscriptions/payments/methods";
        })();

        return this.http.get(this.baseUrl + url);
    }

    getCurrencies(accountUid: string): Observable<any> {
        const url: string = (() => {
            if (accountUid) {
                return `/api/accounts/subscriptions/payments/currencies?accountUid=${accountUid}`;
            }
            return "/api/accounts/subscriptions/payments/currencies";
        })();

        return this.http.get(this.baseUrl + url);
    }

    initiatePayment(data: any): Observable<any> {
        if (!["wallet", "amazonPay"].includes(data.source)) {
            data.source = "adyen";
        }
        data.currency = this.currency.value;
        return this.http.post(this.baseUrl + "/api/accounts/subscriptions/payments", data);
    }

    submitAdditionalDetails(data: any, uid: any): Observable<any> {
        return this.http.patch(this.baseUrl + `/api/accounts/subscriptions/payments/${uid}`, data, httpOptions);
    }

    submitOwnershipRequest(data: any): Observable<any> {
        return this.http.post(this.baseUrl + "/api/applications/ownershipRequests", data);
    }

    getAmazonPayConfiguration(): Observable<any> {
        return this.http.get(this.baseUrl + `/api/accounts/subscriptions/payments/amazonPay/configuration?currency=${this.currency.value}`, {});
    }
}
