import { Injectable } from "@angular/core";
import { RequestParameters, APIRequestsService } from "@app/core/services/api.requests.service";
import { DataSetEntry } from "@app/modules/statistics/services/utils";
import * as moment from "moment";
import { BehaviorSubject, forkJoin } from "rxjs";
import { switchMap } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class SalesStatisticsPreviewCardService {
    fetch = new BehaviorSubject<boolean>(true);
    loadingRevenueData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    errorRevenueData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    revenueLabels: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    revenueDatasets: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    revenueData: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    revenueLast7Days: BehaviorSubject<any> = new BehaviorSubject<any>(0);
    revenueDelta: BehaviorSubject<any> = new BehaviorSubject<any>(0);
    dashboardRevenuesIapTotalsData: BehaviorSubject<any> = new BehaviorSubject<any>(0);

    constructor(private requestsService: APIRequestsService) {}

    init() {
        this.fetch
            .pipe(
                switchMap((x) => {
                    return this.getRevenueData();
                })
            )
            .subscribe({
                next: (data: any) => {
                    this.dashboardRevenuesIapTotalsData.next(
                        data.map((x) => {
                            return x.totals;
                        })
                    );
                    this.revenueLast7Days.next(data[0]?.totals?.volumeInUsd);
                    this.revenueDelta.next((data[0]?.totals?.volumeInUsd / data[1]?.totals?.volumeInUsd - 1) * 100);
                    this.revenueData.next(data[0]);
                    const labels = data[0]["labels"];
                    const datasets = data[0].datasets.map((_element: any, _index: number) => {
                        const label = _element.label;
                        const values = [];
                        _element.data.forEach((dataItem) => {
                            values.push(dataItem.volumeInUsd);
                        });
                        return new DataSetEntry("Revenue", values);
                    });

                    this.revenueLabels.next(labels);
                    this.revenueDatasets.next(datasets);
                    this.loadingRevenueData.next(false);
                    this.errorRevenueData.next(false);
                },
                error: (_error: any) => {
                    this.revenueLabels.next([]);
                    this.revenueDatasets.next([]);
                    this.loadingRevenueData.next(false);
                    this.errorRevenueData.next(true);
                },
            });
    }

    getRevenueData() {
        this.loadingRevenueData.next(true);
        const now = new Date();
        let parameters: RequestParameters[] = [
            {
                label: "from",
                data: moment(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 8)).format("yyyy-MM-DD"),
            },
            {
                label: "to",
                data: moment(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1)).format("yyyy-MM-DD"),
            },
        ];
        let previousParameters: RequestParameters[] = [
            {
                label: "from",
                data: moment(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 15)).format("yyyy-MM-DD"),
            },
            {
                label: "to",
                data: moment(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 8)).format("yyyy-MM-DD"),
            },
        ];

        return forkJoin([
            this.requestsService.get("/api/statistics/chart", [
                ...parameters,

                {
                    label: "type",
                    data: "revenue",
                },
            ]),
            this.requestsService.get("/api/statistics/chart", [
                ...previousParameters,
                {
                    label: "type",
                    data: "revenue",
                },
            ]),
        ]);
    }
}
