<div class="select" 
  [class.pointer]="!disabled"
>
  <div
    class="input-box"
    [class.purple]="purple && !disabled"
    [class.disabled]="disabled"
    [class.veryBig]="veryBig"
    (click)="toggleDropdown()"
    #inputBox
  > <img src="/assets/images/calendar.svg" />
    <p>
     
      <span #inputText class="veryVerySmall normal" [class.grayDark]="disabled">
        {{ selectedDate | catappultDate }}
      </span>
    </p>
    <img
      *ngIf="!purple"
      class="arrow"
      #inputArrow
      src="/assets/images/arrow-dark-blue.svg"
    />
    <img
      *ngIf="purple"
      class="arrow"
      #inputArrow
      src="/assets/images/arrow.svg"
    />
  </div>
  <ng-container *ngIf="showDropdown">
    <div
      class="dropdown-content"
      [class.left]="leftAlign"
    >
      <div class="calendar">
        <mat-calendar
          #calendarDropdown
          (selectedChange)="updateDates($event)"
          [(selected)]="selectedDate"
          [minDate]="min"
          [maxDate]="max"
        >
        </mat-calendar>
      </div>
    </div>
  </ng-container>
</div>
