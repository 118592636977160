import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ILanguageType } from "@app/modules/main/top-bar-dropdowns/top-bar-dropdowns.component";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class MainService {
    _currentLanguage = new BehaviorSubject<ILanguageType>({
        code: "en_GB",
        name: "English",
    });

    constructor(private http: HttpClient, private translate: TranslateService, private router: Router) {}

    get currentLanguage(): Observable<ILanguageType> {
        return this._currentLanguage.asObservable();
    }

    setCurrentLanguage(newLanguage: ILanguageType) {
        this._currentLanguage.next(newLanguage);
        this.translate.use(newLanguage.code); // set language;
    }

    routeTo(routes: string[]) {
        this.router.navigate(routes);
    }
}
