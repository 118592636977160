import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { trigger, style, animate, transition } from "@angular/animations";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "@env/environment";
import { UtilsService } from "@app/core/services/utils.service";
import { UserService } from "@app/core/services/user.service";
import { BehaviorSubject } from "rxjs";

@Component({
    selector: "aptd-dropdown-languages",
    templateUrl: "./dropdown-languages.component.html",
    styleUrls: ["./dropdown-languages.component.scss"],
    animations: [
        trigger("enterAnimation", [
            transition(":enter", [style({ opacity: 0 }), animate("250ms", style({ opacity: 1 }))]),
            transition(":leave", [style({ opacity: 1 }), animate("250ms", style({ opacity: 0 }))]),
        ]),
    ],
})
export class DropdownLanguagesComponent implements OnInit {
    @Input() isMobile: boolean = false;
    @Input() showDropdown: BehaviorSubject<boolean>;
    @Output() onNavigateBack: EventEmitter<any> = new EventEmitter();

    languageOptions: any[] = [
        {
            id: "en_GB",
            label: "English",
            code: "En",
        },
        {
            id: "zh_CN",
            label: "中文 (Zhōngwén)",
            code: "Cn",
        },
        {
            id: "ko_KR",
            label: "한국어",
            code: "Ko",
        },
        {
            id: "ja_JP",
            label: "日本",
            code: "Jp",
        },
    ];

    selectedLanguage: any;

    constructor(private translate: TranslateService, private userService: UserService, private utilsService: UtilsService) {}

    ngOnInit() {
        if (window.localStorage.getItem("usedLanguage")) {
            this.selectedLanguage = this.languageOptions.find((lan) => lan.id === window.localStorage.getItem("usedLanguage"));
        } else {
            this.selectedLanguage = this.languageOptions[0];
        }
        this.userService.selectedLanguageOption.next(this.selectedLanguage);
    }

    onBackClick() {
        this.onNavigateBack.emit();
    }

    toggleDropdown() {
        this.showDropdown.next(!this.showDropdown.value);
    }

    closeDropdown() {
        this.showDropdown.next(false);
    }

    switchLanguage(option: any) {
        this.selectedLanguage = option;
        this.userService.selectedLanguageOption.next(option);
        this.translate.use(option.id);
        window.localStorage.setItem("usedLanguage", option.id);
        this.userService.updateUserLanguage(option.id);
        this.utilsService.getStaticAssets();
    }
}
