import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SubmissionComponent } from '@app/modules/my-apps/submission/submission.component';
import { LeavingSubmissionConfirmationModalComponent } from '@app/shared/leaving-submission-confirmation-modal/leaving-submission-confirmation-modal.component';
import { Observable } from 'rxjs';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class NavigatingOutsideSubmissionGuard  {
  constructor (private dialog: MatDialog) {}

  canDeactivate(
    component: SubmissionComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): boolean | Observable<boolean> {
    return (
      component.canDeactivate() 
      || nextState.url === `/myApps/secretKeys`
      || nextState.url === `/myApps/${component.packageName}/products`
      || nextState.url === `/myApps/${component.packageName}/subscriptions`
    ) 
      ? true 
      : this.openDialog(component)
  }

  openDialog(component: SubmissionComponent): Observable<boolean> {
    const dialogRef = this.dialog.open(LeavingSubmissionConfirmationModalComponent, {
      disableClose: true,
      panelClass: 'leaving-submission-confirmation-modal',
    });
    component.catappultOutsideNavigationDialogRef = dialogRef;
    return dialogRef.afterClosed();
  }
}
