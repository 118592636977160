export class DataService {
  constructor(_priority: number = 0, _delay: boolean = false) {
    let timeout  = _priority * 200;
    if (_delay) {
      timeout += 2000;
    }
    setTimeout(() => {
      this.init();
    }, timeout);
  }

  init() {}
}
