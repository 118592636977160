import { RequestParameters } from "@app/core/services/api.requests.service";
import { BehaviorSubject } from "rxjs";
import {
  convertDateToRequestParameterValue,
  getPreviousPeriodDates,
} from "@app/core/services/utils.service";
import { Optional } from "@angular/core";

export class TimeFilteredService {
  dateFilters: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  dateFrom: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());
  dateTo: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());
  userSignupDate: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());

  constructor(public maxTo: "today" | "yesterday", public range = "week") {
    this.updateDates(range);
  }

   updateDates(period: string) {
    const to = new Date();
    if (this.maxTo === "yesterday") {
      to.setDate(to.getDate() - 1);
    }

    const from = new Date(to.getTime());
    switch (period) {
      case "day":
        from.setDate(from.getDate());
        break;
      case "week":
        from.setDate(from.getDate() - 6);
        break;
      case "month":
        from.setDate(from.getDate() - 29);
        break;
      case "all":
        from.setTime(this.userSignupDate.value.getTime());
        break;
    }

    this.updateDateFrom(from, false);
    this.updateDateTo(to, false);

    this.notifyDateChange();
  }

  updateDateFrom(value: Date, notify: boolean = true) {
    this.dateFrom.next(value);
    if (notify) {
      this.notifyDateChange();
    }
  }

  updateDateTo(value: Date, notify: boolean = true) {
    this.dateTo.next(value);
    if (notify) {
      this.notifyDateChange();
    }
  }

  private notifyDateChange() {
    this.dateFilters.next({
      from: this.dateFrom.value,
      to: this.dateTo.value,
    });
  }

  isAllPeriod() {
    return this.dateFrom.value.getTime() <= this.userSignupDate.value.getTime();
  }

  getDateFiltersRequestHeaders() {
    let parameters: RequestParameters[] = [
      {
        label: "from",
        data: convertDateToRequestParameterValue(this.dateFrom.value),
      },
      {
        label: "to",
        data: convertDateToRequestParameterValue(this.dateTo.value),
      },
    ];

    return parameters;
  }

  getPreviousDateFiltersRequestHeaders() {
    const previousPeriodDates = getPreviousPeriodDates(
      this.dateFrom.value,
      this.dateTo.value
    );

    let parameters: RequestParameters[] = [
      {
        label: "from",
        data: convertDateToRequestParameterValue(previousPeriodDates.from),
      },
      {
        label: "to",
        data: convertDateToRequestParameterValue(previousPeriodDates.to),
      },
    ];

    return parameters;
  }
}
