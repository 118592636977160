<div class="confirm">
  <img src="/assets/images/alert-icon.svg">
  <h5 translate> missingData.title </h5>
  <p translate> missingData.text </p>
  <div class="options">
    <app-button
      (onClick)="cancel()"
      [small]="true"
      [pinkOnWhite]="true"
      label="{{ 'missingData.notNow' | translate }}"
    ></app-button>
    <app-button
      (onClick)="goToAccountSettings()"
      [small]="true"
      label="{{ 'missingData.letsGo' | translate }}"
    ></app-button>
  </div>
</div>
