
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LeavingConfirmationModalComponent } from '@app/shared/leaving-confirmation-modal/leaving-confirmation-modal.component';
export interface ComponentCanDeactivate {
  catappultPendingChangesGuardDialogRef: MatDialogRef<any>;
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard  {
  constructor(private dialog: MatDialog) {}
  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    return component.canDeactivate() ?
      true :
      this.openDialog(component)
  }

  openDialog(component: ComponentCanDeactivate): Observable<boolean> {
    const dialogRef = this.dialog.open(LeavingConfirmationModalComponent, {
      disableClose: true,
      data: 'willNotBeSaved'
    });
    component.catappultPendingChangesGuardDialogRef = dialogRef;
    return dialogRef.afterClosed();
  }
}