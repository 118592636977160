import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DragDropUploadComponent } from "./drag-drop-upload.component";
import { DndDirective } from "./dnd.directive";
import { TranslateModule } from "@ngx-translate/core";
import { LoadingSpinnerModule } from "../spinner/loading-spinner.module";

@NgModule({
  declarations: [DragDropUploadComponent, DndDirective],
  imports: [CommonModule,TranslateModule, LoadingSpinnerModule],
  exports: [DragDropUploadComponent],
})
export class DragDropUploadModule {}
