<div class="account-holder-details">
    <div *ngIf="loadingData | async" class="main-spinner">
        <app-loading-spinner align="horizontal" size="big"></app-loading-spinner>
    </div>
    <div *ngIf="!(loadingData | async)" class="info">
        <div>
            <span class="label">
                <p translate>financialDetails.info.name</p>
                <p>*</p>
            </span>

            <app-text-input
                class="input-text"
                [value]="accountHolderDetailsService.name | async"
                (onChange)="updateName($event)"
                [showSpecificError]="(accountHolderDetailsService.displayErrors | async) && !accountHolderDetailsService.nameIsValid()"
            >
            </app-text-input>
        </div>

        <div>
            <span class="label">
                <p translate>financialDetails.info.address</p>
                <p>*</p>
            </span>
            <app-text-input
                class="input-text"
                [value]="accountHolderDetailsService.address | async"
                (onChange)="updateAddress($event)"
                [showSpecificError]="(accountHolderDetailsService.displayErrors | async) && !accountHolderDetailsService.addressIsValid()"
            >
            </app-text-input>
        </div>

        <div class="city-zip">
            <div>
                <span class="label">
                    <p translate>financialDetails.info.zipCode</p>
                    <p>*</p>
                </span>
                <app-text-input
                    class="input-text"
                    [value]="accountHolderDetailsService.zipCode | async"
                    (onChange)="updateZipCode($event)"
                    [showSpecificError]="(accountHolderDetailsService.displayErrors | async) && !accountHolderDetailsService.zipCodeIsValid()"
                >
                </app-text-input>
            </div>
            <div>
                <span class="label">
                    <p translate>financialDetails.info.city</p>
                    <p>*</p>
                </span>
                <app-text-input
                    class="input-text"
                    [value]="accountHolderDetailsService.city | async"
                    (onChange)="updateCity($event)"
                    [showSpecificError]="(accountHolderDetailsService.displayErrors | async) && !accountHolderDetailsService.cityIsValid()"
                >
                </app-text-input>
            </div>
        </div>

        <div>
            <span class="label">
                <p translate>financialDetails.info.country</p>
                <p>*</p>
            </span>
            <app-autocomplete
                [options]="countriesService.countriesAutocompleteData | async"
                [loading]="countriesService.loadingCountriesData | async"
                [selectedOption]="accountHolderDetailsService.country | async"
                (selectedOptionChange)="updateCountry($event)"
                [showSpecificError]="(accountHolderDetailsService.displayErrors | async) && !accountHolderDetailsService.countryIsValid()"
            ></app-autocomplete>
        </div>

        <div class="account-type">
            <h5 translate>financialDetails.info.accountType</h5>

            <div class="options">
                <div (click)="selectIndividual()" [class.selected]="!(accountHolderDetailsService.isCompany | async)" class="opt">
                    <p translate>financialDetails.info.individual</p>
                </div>
                <div (click)="selectCompany()" [class.selected]="!!(accountHolderDetailsService.isCompany | async)" class="opt">
                    <p translate>financialDetails.info.company</p>
                </div>
            </div>
        </div>

        <div class="data" *ngIf="!!(accountHolderDetailsService.isCompany | async) && (accountHolderDetailsService.country | async)?.id">
            <app-loading-spinner *ngIf="accountHolderDetailsService.checkingIsEU | async" class="spinner" align="horizontal" size="small"> </app-loading-spinner>

            <div *ngIf="accountHolderDetailsService.isEU | async">
                <div class="vat-label-tooltip">
                    <span class="label">
                        <p translate>financialDetails.info.vat</p>
                        <p>*</p>
                    </span>
                    <img matTooltipPosition="above" matTooltip="{{ 'financialDetails.info.vatFormat' | translate }}" class="tooltip" src="assets/images/info_tooltip.svg" />
                </div>
                <app-text-input
                    class="input-text"
                    [value]="accountHolderDetailsService.vat | async"
                    [showSpecificError]="accountHolderDetailsService.invalidVat | async"
                    specificError="{{ 'financialDetails.info.invalidVat' | translate }}"
                    (onChange)="updateVAT($event)"
                    [showSpecificError]="(accountHolderDetailsService.displayErrors | async) && !(accountHolderDetailsService.vatIsValid | async)"
                >
                </app-text-input>
            </div>

            <div *ngIf="!(accountHolderDetailsService.isEU | async)">
                <div class="business-info">
                    <span class="label">
                        <p translate>financialDetails.info.uploadBusiness</p>
                        <p>*</p>
                    </span>
                    <img matTooltipPosition="above" matTooltip="{{ 'financialDetails.info.uploadBusinessInfo' | translate }}" class="tooltip" src="assets/images/info_tooltip.svg" />
                </div>
                <app-drag-drop-upload
                    [hideUploader]="!accountHolderDetailsService.isEmpty(accountHolderDetailsService.businessCertificate)"
                    [imgSrc]="'assets/images/pdf_files.svg'"
                    [multiple]="false"
                    [acceptedFilesText]="'.pdf, .png, .jpg'"
                    [horizontal]="true"
                    [uploadedFile]="accountHolderDetailsService.businessCertificate"
                    [acceptedFiles]="'.pdf,image/png, image/jpg'"
                    [clearUploadedFile]="clearBusinessCertificateFiles"
                    (sendFiles)="uploadBusinessCertificate($event)"
                    (downloadUploaded)="downloadBusinessCertificate()"
                    (deleteUploaded)="deleteBusinessCertificate()"
                    [loading]="(uploadingBusinessCertificate | async) || (gettingBusinessCertificate | async)"
                >
                </app-drag-drop-upload>
                <div
                    class="error-box negative-top-margin"
                    *ngIf="(accountHolderDetailsService.displayErrors | async) && accountHolderDetailsService.isEmpty(accountHolderDetailsService.businessCertificate)"
                >
                    <p translate class="veryVeryVerySmall red thin">settings.businessCertificateMissing</p>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="accountHolderDetailsService.error | async" class="error-container">
        <p class="red small">
            {{ accountHolderDetailsService.error | async }}
        </p>
    </div>
</div>
