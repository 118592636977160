import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { AnimationOptions } from "ngx-lottie";

@Component({
    selector: "app-button",
    templateUrl: "./button.component.html",
    styleUrls: ["./button.component.scss"],
})
export class ButtonComponent implements OnChanges {
    @Input() label: string;
    @Input() imageSrc: string = null;
    @Input() bigTextImg: boolean = false;
    @Input() targetExternal: string = null;
    @Input() targetInternal: string = null;

    @Input() facebook = false;
    @Input() google = false;
    @Input() unity = false;
    @Input() disabled = false;
    @Input() readOnly = false;
    @Input() showButtonSpinner = false;
    @Input() big = false;
    @Input() small = false;
    @Input() smallButWide = false;
    @Input() thin = false;
    @Input() noMargins = false;
    @Input() verySmall = false;
    @Input() veryVerySmall = false;
    @Input() verySmallButWide = false;
    @Input() roundedCorners = false;
    @Input() veryRoundedCorners = false;
    @Input() attachToInputOnLeft = false;
    @Input() boldText = false;
    @Input() thinText = false;
    @Input() fullWidth = false;

    @Output() onClick = new EventEmitter<boolean>();

    @Input() darkBlueOnTransparent = false;
    @Input() grayBorder = false;
    @Input() pinkOnTransparent = false;
    @Input() whiteOnDarkBlue = false;
    @Input() pinkOnWhite = false;
    @Input() whiteOnPink = false;
    @Input() redOnWhite = false;
    @Input() pinkOnGray = false;

    classes: string[] = [];

    button_spinner: AnimationOptions = { path: "/assets/lottie/button-loading.json" };

    constructor() {}

    ngOnChanges() {
        this.classes = ["app-button"];

        const prefix = "app-button--";

        if (this.whiteOnDarkBlue) {
            this.classes.push(prefix + "whiteOnDarkBlue");
        } else if (this.darkBlueOnTransparent) {
            this.classes.push(prefix + "darkBlueOnTransparent");
        } else if (this.pinkOnTransparent) {
            this.classes.push(prefix + "pinkOnTransparent");
        } else if (this.pinkOnGray) {
            this.classes.push(prefix + "pinkOnGray");
        } else if (this.pinkOnWhite) {
            this.classes.push(prefix + "pinkOnWhite");
        } else if (this.redOnWhite) {
            this.classes.push(prefix + "redOnWhite");
        } else if (this.grayBorder) {
            this.classes.push(prefix + "grayBorder");
        } else {
            this.classes.push(prefix + "whiteOnPink");
        }

        if (this.boldText) {
            this.classes.push(prefix + "boldText");
        }

        if (this.thinText) {
            this.classes.push(prefix + "thinText");
        }

        if (this.roundedCorners) {
            this.classes.push(prefix + "roundedCorners");
        }

        if (this.veryRoundedCorners) {
            this.classes.push(prefix + "veryRoundedCorners");
        }

        if (this.attachToInputOnLeft) {
            this.classes.push(prefix + "attachToInputOnLeft");
        }

        if (this.big) {
            this.classes.push(prefix + "big");
        }

        if (this.small) {
            this.classes.push(prefix + "small");
        }
        if (this.thin) {
            this.classes.push(prefix + "thin");
        }

        if (this.smallButWide) {
            this.classes.push(prefix + "smallButWide");
        }

        if (this.verySmall) {
            this.classes.push(prefix + "verySmall");
        }

        if (this.veryVerySmall) {
            this.classes.push(prefix + "veryVerySmall");
        }

        if (this.verySmallButWide) {
            this.classes.push(prefix + "verySmallButWide");
        }

        if (this.fullWidth) {
            this.classes.push(prefix + "fullWidth");
        }

        if (this.unity || this.facebook || this.google) {
            this.classes.push(prefix + "social");
        }

        if (this.disabled) {
            this.classes.push(prefix + "disabled");
        }

        if (this.noMargins) {
            this.classes.push(prefix + "noMargins");
        }

        if (this.bigTextImg) {
            this.classes.push(prefix + "bigTextImg");
        }
    }

    callOnClick() {
        if (this.disabled || this.readOnly || this.showButtonSpinner) {
            return;
        }

        this.onClick.emit(true);
    }
}
