import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { ButtonModule } from "@app/shared/button/button.module";
import { TranslateModule } from "@ngx-translate/core";
import { AccountSubscriptionComponent } from "./account-subscription.component";

@NgModule({
  declarations: [AccountSubscriptionComponent],
  imports: [CommonModule, TranslateModule, ButtonModule, MatDialogModule],
  exports: [AccountSubscriptionComponent],
})
export class AccountSubscriptionModule {}
