<div class="being-process-dialog">
  <div class="content-dialog">
    <img src="assets/images/app-being-processed.svg" />

    <h3 class="title semiBold" translate>onOurSideDialog.title</h3>

    <p class="text s" translate>submission.appBeingProcessed</p>

    <p 
      class="text second"
      *ngIf="data && data.googlePlaySupportEmailAddress" 
      [innerHTML]="'upload.wrapperProcessFinishedText' | translate: { email: data.googlePlaySupportEmailAddress }"
    >
  </p>
  </div>
  <div class="dialog-footer">
    <div class="dialog-footer-buttons">
      <app-button
        label="{{ 'common.buttons.gotIt' | translate }}"
        class="ctp-button ctp-button-highlighted"
        (onClick)="close()"
      >
      </app-button>
    </div>
  </div>
</div>
