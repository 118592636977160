import { Observable, throwError as observableThrowError, catchError } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from "@angular/common/http";
import { environment } from "@env/environment";
import { UserService } from "@app/core/services/user.service";
import { getDomainCookie, getPortalUrl } from "@app/core/services/utils";
import { CookieService } from "ngx-cookie-service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private _cookieService: CookieService, private userService: UserService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (window.location.href.includes(".catappult.io/payments") && !this._cookieService.get(environment.catappultTokenCookie)) {
            return next.handle(req);
        }
        let headers = new HttpHeaders();

        if (this._cookieService.get(environment.catappultTokenCookie)) {
            headers = headers.set("Authorization", `Bearer ${this._cookieService.get(environment.catappultTokenCookie)}`);
        } else {
            this.buildProtectedUrlRedirect();
            window.location.href = `${getPortalUrl()}/login`;
        }

        req = req.clone({
            headers: headers,
        });

        return next.handle(req).pipe(catchError((err) => this.handleError(err)));
    }

    private handleError(err: any): Observable<any> {
        let errorMsg;
        if (err.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMsg = [`An error occurred: ${err.error.message}`];
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            // errorMsg = [`Backend returned code ${err.status}, body was: ${err.error}`];
            errorMsg = {
                status: err.status,
                error: err.error,
            };
        }
        if (err.status === 401) {
            errorMsg = "Authentication failure";
            this.userService.logout();
            window.location.href = `${getPortalUrl()}/login`;
        } else if (err.status === 403) {
        }

        return observableThrowError(errorMsg);
    }

    buildProtectedUrlRedirect() {
        const today = new Date();
        const tomorrow = new Date();
        tomorrow.setDate(today.getDate() + 1);

        this._cookieService.set("protected_url_redirect", window.location.href, {
            domain: getDomainCookie(),
            expires: tomorrow,
        });
    }
}
