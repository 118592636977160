import { Injectable } from "@angular/core";
import { CountriesService } from "@app/core/services/countries.service";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class DistributionCountriesSubmissionService {
    refresh: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    countries: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    countryFilter: BehaviorSubject<string> = new BehaviorSubject<string>("");
    filteredCountries: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    regions: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

    constructor(private countriesService: CountriesService) { }

    init(distributionCountries: any) {
        this.countriesService.distributionCountriesData.subscribe({
            next: (countriesData) => {
                if (!countriesData) {
                    return;
                }

                this.filteredCountries.next(this.countriesService.distributionCountriesData.value)
                this.regions.next([
                    { name: "europeanUnion", selected: false, numberOfCountries: this.countriesService.countriesByRegionData.value?.["europeanUnion"]?.length },
                    { name: "asia", selected: false, numberOfCountries: this.countriesService.countriesByRegionData.value?.["asia"]?.length },
                    { name: "africa", selected: false, numberOfCountries: this.countriesService.countriesByRegionData.value?.["africa"]?.length },
                    { name: "americas", selected: false, numberOfCountries: this.countriesService.countriesByRegionData.value?.["americas"]?.length },
                    { name: "oceania", selected: false, numberOfCountries: this.countriesService.countriesByRegionData.value?.["oceania"]?.length },
                    { name: "europe", selected: false, numberOfCountries: this.countriesService.countriesByRegionData.value?.["europe"]?.length },
                ]);

                let filteredDistributionCountries = [];
                if (distributionCountries === "all") {
                    countriesData?.map((c) => {
                        filteredDistributionCountries.push(c.id.toLowerCase());
                    });
                }
                else if (typeof distributionCountries === "string" && distributionCountries) {
                    filteredDistributionCountries = distributionCountries?.split(",").map((element) => {
                        return element.toLowerCase();
                    });
                }

                this.countries.next(filteredDistributionCountries);
            },
        });
    }

    getFormData(): FormData {
        const formData = new FormData();

        const formattedDistributionCountries: any = (() => {
            if (this.countries.value?.length === 0) {
                return "";
            }
            if (this.countries.value?.length === this.countriesService.distributionCountriesData.value?.length) {
                return "all";
            }
            return this.countries.value?.join(",");
        })();

        formData.append("distributionCountries", formattedDistributionCountries);

        return formData;
    }

    reset() {
        this.countries.next(null);
    }
}
