import { Component, Input } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-button-dropdown',
  templateUrl: './button-dropdown.component.html',
  styleUrls: ['./button-dropdown.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({opacity: 0 }),
          animate('250ms', style({ opacity: 1 }))
        ]),
        transition(':leave', [
          style({ opacity: 1 }),
          animate('250ms', style({ opacity: 0 }))
        ])
      ]
    )
  ]
})
export class ButtonDropdownComponent {
  @Input() iconSrc: string;
  @Input() iconSrcMobile: string;
  @Input() showArrow:boolean;
  @Input() userDropdown:boolean = false;
  @Input() showDropdown: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() label: string;
  @Input() tooltip: string;
  @Input() dark = false;
  @Input() alignment: "left" | "right" = "right";

  isHovering: boolean;

  constructor() {}

  toggleDropdown() {
    this.showDropdown.next(!this.showDropdown.value);
  }

  closeDropdown() {
    if (this.showDropdown.value) {
      this.showDropdown.next(false);
    }
  }
}

@Component({
  selector: '[app-button-dropdown-item]',
  template: '<div class="menu-item"><ng-content></ng-content></div>',
  styleUrls: ['./button-dropdown.component.scss']
})
export class ButtonDropdownItemComponent {
}
