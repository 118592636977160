import { Injectable } from "@angular/core";
import { APIRequestsService } from "@app/core/services/api.requests.service";
import { CountriesService } from "@app/core/services/countries.service";
import { CountryNamePipe } from "@app/shared/pipes/pipes";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class RatesByCountryService {
    ratesByCountryList: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    countriesWithAutomaticVatList: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    optionSelected: BehaviorSubject<any> = new BehaviorSubject<any>("RATES");
    loadingRates: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    selectOptions: BehaviorSubject<any> = new BehaviorSubject<any>([
        {
            value: "RATES",
            label: "vatPolicy.dialog.all",
        },
        {
            value: "AUTOMATIC",
            label: "vatPolicy.dialog.weHandle",
        },
    ]);

    constructor(private requestService: APIRequestsService, private countriesService: CountriesService, private countryNamePipe: CountryNamePipe) {
        this.getRatesByCountry();
    }

    getRatesByCountry() {
        this.loadingRates.next(true);
        this.requestService.get(`/api/vatRates`).subscribe({
            next: (res: any) => {
                const formattedList = res.map((c) => {
                    const formattedRate: string = (() => {
                        if (c.rate === 0) {
                            return "-";
                        }
                        return c.rate + "%";
                    })();
                    return { id: c.country, country: this.countryNamePipe.transform(c.country, this.countriesService.countriesData.value), rate: formattedRate };
                });

                formattedList.sort((a, b) => {
                    return a.country < b.country ? -1 : a.country > b.country ? 1 : 0;
                });
                this.ratesByCountryList.next(formattedList);
                this.getCountriesWithAutomaticVat();
                this.loadingRates.next(false);
            },
        });
    }

    getCountriesWithAutomaticVat() {
        this.requestService.get(`/api/utils/consumables/countriesWithAutomaticVat`).subscribe({
            next: (res: any) => {
                const filteredList = this.ratesByCountryList.value.filter((c) => res.includes(c.id));
                this.countriesWithAutomaticVatList.next(filteredList);
            },
        });
    }
}
