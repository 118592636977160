import { Injectable } from "@angular/core";
import { RequestParameters, APIRequestsService } from "@app/core/services/api.requests.service";
import { UserService } from "@app/core/services/user.service";
import { BehaviorSubject } from "rxjs";
import { isEmpty } from "@app/core/services/utils.service";

@Injectable({
    providedIn: "root",
})
export class AccountHolderDetailsService {
    name: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    country: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    address: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    city: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    zipCode: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    vat: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    businessCertificate: any = null;
    checkingIsEU: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isEU: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    error: BehaviorSubject<string> = new BehaviorSubject<string>("");
    displayErrors: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    vatIsvalid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    isCompany: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private requestsService: APIRequestsService, private userService: UserService) {
        this.vat.subscribe({
            next: () => {
                if (!this.vat.value) {
                    this.vatIsvalid.next(false);
                } else {
                    this.requestsService.get(`/api/utils/vatValidation/${this.vat.value}/isValid`).subscribe({
                        next: (res: any) => {
                            this.vatIsvalid.next(res);
                        },
                        error: () => {
                            this.vatIsvalid.next(false);
                        },
                    });
                }
            },
        });
    }

    checkAccounHasAllData() {
        const url = "/api/wallet/withdrawRequests/hasValidAccountData?nocache=1";
        return this.requestsService.get(url);
    }

    checkIsEU(countryCode: string) {
        if (!countryCode) {
            this.isEU.next(false);
            return;
        }

        this.checkingIsEU.next(true);
        const url = `/api/countries/isEu?country=${countryCode}`;
        this.requestsService.get(url).subscribe({
            next: (res: boolean) => {
                if (res) {
                    if (!this.vat.value) {
                        this.vat.next(countryCode.toUpperCase());
                    }
                }
                this.isEU.next(res);
                this.checkingIsEU.next(false);
            },
            error: () => {
                this.isEU.next(false);
                this.checkingIsEU.next(false);
                this.vat.next(null);
            },
        });
    }

    nameIsValid() {
        return this.name.value && isNaN(this.name.value) && this.name.value.toString().length > 1;
    }

    addressIsValid() {
        return this.address.value && isNaN(this.address.value) && this.address.value.toString().length > 4;
    }

    zipCodeIsValid() {
        return this.zipCode.value && this.zipCode.value.toString().length > 3;
    }

    cityIsValid() {
        return this.city.value && isNaN(this.city.value) && this.city.value.toString().length > 2;
    }

    countryIsValid() {
        return this.country && this.country.value && this.country.value.id;
    }

    financeIsValid() {
        if (this.isCompany.value) {
            if (this.isEU.value) return this.vatIsvalid.value;
            if (!this.isEU.value) return !isEmpty(this.businessCertificate);
        }
        return true;
    }

    mandatoryFieldsAreValidCheck(): boolean {
        if (!this.nameIsValid()) return false;
        if (!this.addressIsValid()) return false;
        if (!this.zipCodeIsValid()) return false;
        if (!this.cityIsValid()) return false;
        if (!this.countryIsValid()) return false;
        if (!this.financeIsValid()) return false;
        return true;
    }

    save() {
        if (!this.mandatoryFieldsAreValidCheck()) {
            this.displayErrors.next(true);
            return;
        }

        const url = `/api/accounts/${this.userService.loggedAccount.value.uid}`;

        const params: RequestParameters[] = [];
        params.push({ label: "name", data: this.name.value });
        params.push({ label: "address", data: this.address.value });
        params.push({ label: "city", data: this.city.value });
        params.push({ label: "zipCode", data: this.zipCode.value });
        params.push({
            label: "country",
            data: this.country.value?.id ? this.country.value?.id : null,
        });
        if (this.vat.value) {
            params.push({ label: "vat", data: this.vat.value });
        }
        params.push({ label: "isCompany", data: this.isCompany.value });

        return this.requestsService.patch(url, params);
    }

    uploadAccountFile(accountUid: string, file: any) {
        const url = `/api/accounts/${accountUid}/files`;

        const params: RequestParameters[] = [];

        params.push({ label: "file", data: file[0] });
        params.push({ label: "fileName", data: `business_certificate.${file[0].name.split(".").pop()}` });

        return this.requestsService.post(url, params);
    }

    getBusinessCertificate(accountUid: string) {
        const url = `/api/accounts/${accountUid}/files/business_certificate?nocache=1`;
        return this.requestsService.get(url);
    }

    deleteBusinessCertificate(accountUid: string, fileName: string) {
        const url = `/api/accounts/${accountUid}/files/${fileName}`;
        return this.requestsService.delete(url);
    }

    isEmpty(obj: any) {
        return isEmpty(obj);
    }
}
