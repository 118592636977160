import { Injectable } from "@angular/core";
import { TransactionType, TRANSACTION_TYPES } from "@app/core/services/constants";
import { TimeFilteredService } from "@app/core/services/time-filtered-service";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class MobileFiltersService extends TimeFilteredService {
    transactionTypeOptions: BehaviorSubject<TransactionType[]> = new BehaviorSubject<TransactionType[]>(TRANSACTION_TYPES.slice());
    transactionType: BehaviorSubject<TransactionType> = new BehaviorSubject<TransactionType>(TRANSACTION_TYPES.slice()[0]);

    country: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    application: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    partner: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor() {
        super("today");
    }

    reset() {
        const to = new Date();
        to.setDate(to.getDate() - 1);
        const from = new Date(to.getTime());
        from.setDate(from.getDate() - 6);
        this.dateFrom.next(from);
        this.dateTo.next(to);

        this.country.next(null);
        this.application.next(null);
        this.partner.next(null);
    }
}
