import { Component, Input, EventEmitter, Output, ViewChild, ElementRef } from "@angular/core";
import { v4 as uuid } from "uuid";
import { getFileSizeMB } from "@app/core/services/utils.service";

@Component({
    selector: "app-image-uploader",
    templateUrl: "./image-uploader.component.html",
    styleUrls: ["./image-uploader.component.scss"],
})
export class ImageUploaderComponent {
    uploadIcon = "/assets/images/uploader-image3.svg";
    screenshotFallback = "/assets/images/appicon-fallback.svg";
    screenshotRemoveIcon = "/assets/images/dashboard/close-small-white.svg";
    uploaderId: string;

    @ViewChild("fileInput") fileInput: ElementRef;

    @Input() breakLineTitle: boolean;
    @Input() title: string;
    @Input() subtitle: string;
    @Input() acceptedTypes = "image/png, image/jpg, image/jpeg";
    @Input() errors: string[] = [];
    @Input() loading: boolean = false;
    @Input() srcBase64: string;
    @Input() imgSrc: string;
    @Input() simpleLayout: boolean = false;
    @Input() disabled: boolean = false;

    @Output() change = new EventEmitter();
    @Output() removeImage = new EventEmitter();

    constructor() {
        this.uploaderId = uuid();
    }

    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    selected(files: FileList, event: any): void {
        if (this.disabled) {
            return;
        }
        if (files.length > 0) {
            const onChange = this.change;
            const fileInput = this.fileInput;

            const filesList = [];
            let counter = 0;

            for (let index = 0; index < files.length; index++) {
                const element = files[index];

                this.getBase64(files[index]).then((base64) => {
                    const image = new Image();

                    image.onload = function () {
                        filesList.push({
                            plainFile: files[index],
                            size: getFileSizeMB(files[index]),
                            width: this["width"],
                            height: this["height"],
                            name: files[index].name,
                            base64,
                        });
                        counter++;

                        if (counter === files.length) {
                            onChange.emit(filesList);
                            fileInput.nativeElement.value = "";
                        }
                    };
                    image.src = URL.createObjectURL(files[index]);
                });
            }
        }
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    onRemoveImage(event: any, index: any) {
        event.preventDefault();
        event.stopPropagation();

        this.removeImage.emit(index);
    }

    onFileDropped(files: FileList): void {
        this.selected(files, null);
    }
}
