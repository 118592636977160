<ng-container>
    <div class="add-app-dialog" *ngIf="!(addAppService.loading | async) && !(addAppService.marketDataAvailable | async)">
        <h3 translate>submission.questionGooglePlay</h3>

        <mat-radio-group class="addAppRadio" color="primary" (ngModelChange)="isYourAppInGooglePlayOptionChange()" [(ngModel)]="isYourAppInGooglePlayOption">
            <mat-radio-button class="input-container" value="yes">
                <h4 translate>addAppDialog.yes</h4>

                <ng-container *ngIf="isYourAppInGooglePlayOption === 'yes'">
                    <div class="title">
                        <p class="xxs poppins-font margin-top" translate>addAppDialog.appGP</p>
                        <img src="/assets/images/info_tooltip_dark_blue.svg" matTooltip="{{ 'addAppDialog.yesToolTip' | translate }}" #tooltipYes="matTooltip" (click)="tooltipYes.toggle()" />
                    </div>

                    <app-text-input
                        class="input-text"
                        [showSpecificError]="!!(addAppService.googlePlayUrlInvalidInputError | async)"
                        (onChange)="updateAppPackageNameWithGooglePlayUrl($event)"
                        [big]="true"
                        placeholder="https://play.google.com/store/apps/details?id=com.example"
                    >
                    </app-text-input>

                    <p *ngIf="addAppService.googlePlayUrlInvalidInputError | async" class="semiBold red xxs error-msg-margins">
                        <span> {{ addAppService.googlePlayUrlInvalidInputError | async }} </span>
                    </p>

                    <p *ngIf="addAppService.appStatusError | async" class="semiBold red xxs error-msg-margins">
                        {{ addAppService.appStatusError | async }}
                        <span *ngIf="(addAppService.appStatusErrorCode | async) === 'APPLICATION-ALREADY-SUBMITTED'" (click)="goToSubmission()" class="xxs underlined">
                            <a class="pink" translate>addAppDialog.update</a>
                        </span>
                    </p>

                    <p class="xxxs poppins-font info-text" translate>addAppDialog.gpInfo</p>
                </ng-container>
            </mat-radio-button>
            <mat-radio-button class="input-container" value="no">
                <h4 translate>addAppDialog.no</h4>
                <ng-container *ngIf="isYourAppInGooglePlayOption === 'no'">
                    <div class="title">
                        <p class="xxs poppins-font margin-top" translate>addAppDialog.appPackageName</p>
                        <img src="/assets/images/info_tooltip_dark_blue.svg" matTooltip="{{ 'addAppDialog.noToolTip' | translate }}" #tooltipNo="matTooltip" (click)="tooltipNo.toggle()" />
                    </div>
                    <app-text-input
                        class="input-text"
                        [showSpecificError]="!!(addAppService.packageNameInvalidInputError | async)"
                        (onChange)="updateAppPackageName($event)"
                        [big]="true"
                        placeholder="com.example"
                    >
                    </app-text-input>
                    <p class="semiBold red xxs error-msg-margins" *ngIf="addAppService.packageNameInvalidInputError | async">
                        <span>
                            {{ addAppService.packageNameInvalidInputError | async }}
                        </span>
                    </p>
                    <p *ngIf="addAppService.appStatusError | async" class="semiBold red xxs error-msg-margins">
                        {{ addAppService.appStatusError | async }}
                        <span *ngIf="(addAppService.appStatusErrorCode | async) === 'APPLICATION-ALREADY-SUBMITTED'" (click)="goToSubmission()" class="xxs underlined">
                            <a class="pink" translate>addAppDialog.update</a>
                        </span>
                    </p>

                    <p class="xxxs poppins-font info-text" translate>addAppDialog.packageInfo</p>
                </ng-container>
            </mat-radio-button>
        </mat-radio-group>

        <div class="buttons">
            <app-button
                (onClick)="next()"
                [disabled]="(addAppService.googlePlayUrlInvalidInputError | async) || (addAppService.packageNameInvalidInputError | async) || !(addAppService.appPackageName | async)"
                label="{{ 'common.buttons.next' | translate }}"
            ></app-button>
        </div>
    </div>
    <div class="spinner" *ngIf="addAppService.loading | async">
        <app-loading-spinner align="horizontal" loadingMessage="{{ 'common.loading' | translate }}" size="big"></app-loading-spinner>
    </div>
    <div class="google-play-app" *ngIf="(addAppService.marketDataAvailable | async) && !(addAppService.loading | async)">
        <div class="data">
            <div class="img-version">
                <img [src]="addAppService.googlePlayAppIcon | async" />
                <p>{{ "addAppDialog.vername" | translate }}{{ addAppService.googlePlayVername | async }}</p>
            </div>
            <div class="info">
                <h4 [innerHTML]="'addAppDialog.is' | translate: { yourApp: (addAppService.googlePlayAppName | async) }"></h4>
            </div>
        </div>
        <div class="buttons">
            <app-button (onClick)="noItsNot()" [pinkOnWhite]="true" label="{{ 'addAppDialog.noItsNot' | translate }}"></app-button>
            <app-button (onClick)="yesItIs()" label="{{ 'addAppDialog.yesItIs' | translate }}"></app-button>
        </div>
    </div>
</ng-container>
