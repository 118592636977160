import { Injectable } from "@angular/core";
import { RequestParameters } from "@app/core/services/api.requests.service";
import { IndicativeService } from "@app/core/services/indicative.service";
import { UploaderRequestsService } from "@app/core/services/uploader.requests.service";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Subject } from "rxjs";
@Injectable({
    providedIn: "root",
})
export class FilesOwnershipViaSignApkService {
    signedBlankApk: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    isValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    downloadingBlankApk: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    step: BehaviorSubject<string> = new BehaviorSubject<string>("Step1");
    blankApkError: BehaviorSubject<string> = new BehaviorSubject<string>("");
    validatingBlankApk: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private translateService: TranslateService, private indicativeService: IndicativeService, private uploaderRequestService: UploaderRequestsService) {}

    reset() {
        this.signedBlankApk.next(null);
        this.isValid.next(true);
        this.step.next("Step1");
        this.blankApkError.next("");
    }

    getFormData(): FormData {
        const formData = new FormData();
        if (this.signedBlankApk?.value?.[0]) {
            formData.append("signedBlankApk", this.signedBlankApk?.value?.[0]);
        }

        return formData;
    }

    downloadUnsignedApk(packageName) {
        this.indicativeService.sendEvent("app_submission_form_interacting");
        this.indicativeService.sendEvent("app_submission_form_blank_apk_interacting");
        this.indicativeService.sendEvent("app_submission_form_blank_apk_step1_attempting");

        this.downloadingBlankApk.next(true);
        this.blankApkError.next("");

        this.downloadUnsignedBlankApk(packageName).subscribe({
            next: (res) => {
                this.indicativeService.sendEvent("app_submission_form_blank_apk_step1_success");

                this.step.next("Step2");
                this.downloadingBlankApk.next(false);
            },
            error: (err) => {
                this.indicativeService.sendEvent("app_submission_form_blank_apk_step1_fail");

                this.downloadingBlankApk.next(false);
            },
        });
    }

    onSignedBlankApkSelection(event: any) {
        this.indicativeService.sendEvent("app_submission_form_interacting");
        this.indicativeService.sendEvent("app_submission_form_blank_apk_interacting");
        this.indicativeService.sendEvent("app_submission_form_blank_apk_step3_attempting");

        this.validatingBlankApk.next(true);
        this.testUnsignedBlankApk(event).subscribe({
            next: (res) => {
                this.indicativeService.sendEvent("app_submission_form_blank_apk_step3_success");

                this.step.next("Step4");
                this.validatingBlankApk.next(false);
                this.isValid.next(true);
                this.signedBlankApk.next(event);
            },
            error: (err) => {
                this.indicativeService.sendEvent("app_submission_form_blank_apk_step3_fail");

                this.step.next("Step1");
                this.blankApkError.next(this.parseSignedBlankAPKError(err.error.code));
                this.validatingBlankApk.next(false);
            },
        });
    }

    parseSignedBlankAPKError(code: string): string {
        switch (code) {
            case "INVALID-FILE":
            case "INVALID-APK-FILE":
            case "INVALID-FILE-TOKEN":
            case "INVALID-FILE-TOKEN-DATA":
                return this.translateService.instant("upload.form.signedBlankApkErrors.invalidFile");
            case "APK-NOT-SIGNED":
                return this.translateService.instant("upload.form.signedBlankApkErrors.notSigned");
            default:
                return this.translateService.instant("upload.form.signedBlankApkErrors.genericError");
        }
    }

    yesISignedTheBlankAPK() {
        this.indicativeService.sendEvent("app_submission_form_interacting");
        this.indicativeService.sendEvent("app_submission_form_blank_apk_interacting");
        this.indicativeService.sendEvent("app_submission_form_blank_apk_step2_success");

        this.step.next("Step3");
        this.blankApkError.next("");
    }

    downloadUnsignedBlankApk(packageName: string) {
        const subj = new Subject();
        const url = "/api/blankApk";
        this.uploaderRequestService.get(url, [], true, null, "blob").subscribe({
            next: (file: File) => {
                if (file) {
                    const a = document.createElement("a");
                    a.style.display = "none";
                    document.body.appendChild(a);
                    const url = window.URL.createObjectURL(file);
                    a.href = url;
                    a.download = `Sign_blank_APK_to_certify_${packageName}.apk`;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                    subj.next(true);
                }
            },
            error: (error) => {
                subj.next(error.error && error.error.description ? error.error.description : JSON.stringify(error.error));
            },
        });
        return subj;
    }

    testUnsignedBlankApk(signedBlankApk: any) {
        const url = "/api/blankApk";

        const params: RequestParameters[] = [];

        params.push({ label: "file", data: signedBlankApk[0] });
        return this.uploaderRequestService.post(url, params);
    }
}
