import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ImageUploaderComponent } from "./image-uploader.component";
import { LoadingSpinnerModule } from "@app/shared/spinner/loading-spinner.module";
import { TranslateModule } from "@ngx-translate/core";
import { DirectivesModule } from "../directives/dnd.module";
import { ButtonModule } from "../button/button.module";
@NgModule({
    declarations: [ImageUploaderComponent],
    imports: [CommonModule, LoadingSpinnerModule, TranslateModule, DirectivesModule, ButtonModule],
    exports: [ImageUploaderComponent],
})
export class ImageUploaderModule {}
