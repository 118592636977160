<h3 translate>scheduledReleasesDialog.title</h3>
<p class="darkGray low-line-height subtitle" *ngIf="!(hideOptions | async)" translate>
    scheduledReleasesDialog.subTitle
    <label class="link" (click)="openAppRestrictions()" translate> scheduledReleasesDialog.here </label>
</p>
<div class="options immediatelly" *ngIf="!(hideOptions | async)">
    <div class="option-tooltip">
        <app-checkbox [color]="'primary'" [pinkTheme]="true" placeholder="{{ 'scheduledReleasesDialog.immediatelly' | translate }}" [checked]="(selectedOption | async) === 'now'" (changeEmitter)="updateOption('now')"> </app-checkbox>
        <img #tooltip="matTooltip" matTooltipPosition="above" matTooltip="{{ 'scheduledReleasesDialog.immediatellyTooltip' | translate }}" class="schedule-tooltip" src="assets/images/info_tooltip_dark_blue.svg" (click)="tooltip.toggle()" />
    </div>
    <div class="option-tooltip">
        <app-checkbox [pinkTheme]="true" [checked]="(selectedOption | async) === 'specific'" (changeEmitter)="updateOption('specific')" placeholder="{{ 'scheduledReleasesDialog.specific' | translate }}"> </app-checkbox>
        <img #tooltip="matTooltip" matTooltipPosition="above" matTooltip="{{ 'scheduledReleasesDialog.specificTooltip' | translate }}" class="schedule-tooltip" src="assets/images/info_tooltip_dark_blue.svg" (click)="tooltip.toggle()" />
    </div>
    <div class="option-tooltip">
        <app-checkbox *ngIf="data?.showManual" [pinkTheme]="true" [checked]="(selectedOption | async) === 'manual'" (changeEmitter)="updateOption('manual')" placeholder="{{ 'scheduledReleasesDialog.manual' | translate }}"> </app-checkbox>
        <img #tooltip="matTooltip" matTooltipPosition="above" matTooltip="{{ 'scheduledReleasesDialog.manualTooltip' | translate }}" class="schedule-tooltip" src="assets/images/info_tooltip_dark_blue.svg" (click)="tooltip.toggle()" />
    </div>
</div>

<ng-container *ngIf="(selectedOption | async) === 'specific'">
    <p class="darkGray low-line-height" translate>scheduledReleasesDialog.timezone</p>
    <div class="date-time-pickers">
        <app-date-picker class="desktop-version" [min]="today" [selectedDate]="data?.date ? data.date : tomorrow" [max]="maxDate" (selectedDateChange)="onSelectedDateChange($event)"> </app-date-picker>
        <app-date-picker class="mobile-version" [veryBig]="true" [min]="today" [selectedDate]="data?.date ? data.date : tomorrow" [max]="maxDate" (selectedDateChange)="onSelectedDateChange($event)"> </app-date-picker>
        <app-select class="desktop-version" [noMaxWidth]="true" [options]="timeOptions | async" [white]="true" [selectedOption]="selectedTime" (selectedOptionChange)="onSelectedTimeChange($event)" [roundedBlueBorder]="true" [selectedOptionImage]="'/assets/images/clock.svg'" [withSmallMinWidth]="true"> </app-select>
        <app-select class="mobile-version" [veryBig]="true" [noMaxWidth]="true" [options]="timeOptions | async" [white]="true" [selectedOption]="selectedTime" (selectedOptionChange)="onSelectedTimeChange($event)" [roundedBlueBorder]="true" [selectedOptionImage]="'/assets/images/clock.svg'" [withSmallMinWidth]="true"> </app-select>
    </div>
</ng-container>
<div class="buttons">
    <app-button label="{{ 'scheduledReleasesDialog.cancel' | translate }}" [pinkOnWhite]="true" (onClick)="closeDialog()" [small]="true"> </app-button>
    <app-button [roundedCorners]="true" [small]="true" (onClick)="closeDialogWithOption()" label="{{ 'scheduledReleasesDialog.done' | translate }}"> </app-button>
</div>
