<div class="content-d">
  <img src="assets/images/subscriptions/subscribe.png" />
  <div translate class="title">onOurSideDialog.title</div>
  <div class="bottom-margin">
    <p class="text" translate>
      onOurSideDialog.body
    </p>
  </div>
  <div class="buttons">
    <app-button
      class="button"
      (onClick)="gotIt()"
      label="{{ 'onOurSideDialog.gotIt' | translate }}"
    ></app-button>
  </div>
</div>