import { Injectable } from "@angular/core";
import { DataService } from "@app/core/services/data-service";
import { APIRequestsService } from "@app/core/services/api.requests.service";
import { BehaviorSubject } from "rxjs";
import { switchMap } from "rxjs/operators";
import { StatisticsFiltersService } from "../settings.service";
import { DataSetEntry } from "../utils";
import { StatisticsDownloadsTotalsDataService } from "./totals-data.service";

@Injectable({
    providedIn: "root",
})
export class StatisticsDownloadsChartDataService extends DataService {
    fetch = new BehaviorSubject<boolean>(true);
    loadingData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    labels: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    datasets: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

    constructor(
        public requestsService: APIRequestsService,
        public statisticsFiltersService: StatisticsFiltersService,
        private statisticsDownloadsTotalsDataService: StatisticsDownloadsTotalsDataService
    ) {
        super(1, !(window.location.href.endsWith("/") || window.location.href.endsWith("/statistics")));
    }

    init() {
        this.statisticsFiltersService.dateFilters.subscribe({
            next: (x: any) => {
                if (x) {
                    this.fetch.next(true);
                }
            },
        });

        this.statisticsFiltersService.dataFilters.subscribe({
            next: (x: any) => {
                if (x) {
                    this.fetch.next(true);
                }
            },
        });

        this.fetch
            .pipe(
                switchMap((x) => {
                    return this.getData();
                })
            )
            .subscribe({
                next: (data: any) => {
                    this.statisticsDownloadsTotalsDataService.data.next({
                        total: data.totals.total,
                    });

                    const labels = data["labels"];
                    const datasets = data.datasets.map((_element: any, _index: number) => {
                        const label = _element.label;
                        const values = [];
                        _element.data.forEach((dataItem) => {
                            values.push(dataItem.total);
                        });
                        return new DataSetEntry("Downloads", values);
                    });
                    this.labels.next(labels);
                    this.datasets.next(datasets);
                    this.loadingData.next(false);
                    this.statisticsDownloadsTotalsDataService.loadingData.next(false);
                },
                error: (_error: any) => {
                    this.labels.next([]);
                    this.datasets.next([]);
                    this.loadingData.next(false);
                    this.statisticsDownloadsTotalsDataService.loadingData.next(false);
                },
            });
    }

    getData() {
        this.loadingData.next(true);
        this.statisticsDownloadsTotalsDataService.loadingData.next(true);
        
        return this.getChart();
    }

    getChart() {
        return this.requestsService.get("/api/statistics/chart", [
            ...this.statisticsFiltersService.getDateFiltersRequestHeaders(),
            ...this.statisticsFiltersService.getDataFiltersRequestHeaders(false),
            {
                label: "type",
                data: "downloads",
            },
        ]);
    }
}
