import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject } from "rxjs";
import { UtilsService, ImageDataForValidation } from "@app/core/services/utils.service";
import { SelectEntry } from "@app/shared/select/select.component";
import { IndicativeService } from "@app/core/services/indicative.service";

@Injectable({
    providedIn: "root",
})
export class LanguagesMediaService {
    locales: BehaviorSubject<any> = new BehaviorSubject<any>({ en_GB: {} });
    invalidTitle = new BehaviorSubject<boolean>(false);
    invalidDescription = new BehaviorSubject<boolean>(false);
    invalidReleaseNotes = new BehaviorSubject<boolean>(false);
    displayLanguagesMedia = new BehaviorSubject<boolean>(true);
    screenShotsValidationErrorList: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    screenShotsValidationLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isValid = new BehaviorSubject<boolean>(true);

    acceptedImageTypes = "image/png, image/jpg, image/jpeg";

    selectedLocale: SelectEntry = { value: "en_GB", label: "English" };

    constructor(private translate: TranslateService, private utilsService: UtilsService, private indicativeService: IndicativeService) { }

    reset() {
        this.locales.next({ en_GB: {} });
        this.selectedLocale = { value: "en_GB", label: "English" };
        this.resetValidators();
        this.displayLanguagesMedia.next(true);
        this.isValid.next(true);
    }

    showLanguagesMedia(state: boolean) {
        this.indicativeService.sendEvent("app_submission_form_interacting");
        this.indicativeService.sendEvent("app_submission_form_languages_media_interacting");
        this.displayLanguagesMedia.next(state);
        this.isValid.next(!!this.validateForm());
    }

    resetValidators() {
        this.invalidTitle.next(false);
        this.invalidDescription.next(false);
        this.invalidReleaseNotes.next(false);
        this.screenShotsValidationErrorList.next([]);
        this.screenShotsValidationLoading.next(false);
    }

    onLanguageChange(option) {
        this.indicativeService.sendEvent("app_submission_form_interacting");
        this.indicativeService.sendEvent("app_submission_form_languages_media_interacting");

        if (!this.locales.value[option.value]) {
            let _locales = this.locales.value;
            _locales[option.value] = {};
            this.locales.next(_locales);
        }
        this.selectedLocale = option;
        this.resetValidators();
        this.validateActualLanguageMediaFieldsIfNotEmpty();
    }

    checkIfLocaleIsMandatoryAndCleanValidatorsIfEmpty() {
        if (this.selectedLocale.value !== "en_GB" && this.isEmptyLocale(this.locales.value[this.selectedLocale.value])) {
            this.resetValidators();
        }
    }

    updateTitle(event) {
        this.indicativeService.sendEvent("app_submission_form_interacting");
        this.indicativeService.sendEvent("app_submission_form_languages_media_interacting");
        if (this.locales.value[this.selectedLocale.value]["title"] == "") {
            this.invalidTitle.next(false);
        }
        this.updateInput("title", event);
        if (this.invalidTitle.value === true) {
            this.validateTitle();
        }
    }

    onTitleFocusOut() {
        this.validateTitle();
        this.checkIfLocaleIsMandatoryAndCleanValidatorsIfEmpty();
    }

    updateDescription(event) {
        this.indicativeService.sendEvent("app_submission_form_interacting");
        this.indicativeService.sendEvent("app_submission_form_languages_media_interacting");
        if (!this.locales.value[this.selectedLocale.value]["description"]) {
            this.invalidDescription.next(false);
        }
        this.updateInput("description", event);
        if (this.invalidDescription.value === true) {
            this.validateDescription();
        }
    }

    onDescriptionFocusOut() {
        this.validateDescription();
        this.checkIfLocaleIsMandatoryAndCleanValidatorsIfEmpty();
    }

    updateReleaseNotes(event) {
        this.indicativeService.sendEvent("app_submission_form_interacting");
        this.indicativeService.sendEvent("app_submission_form_languages_media_interacting");
        if (!this.locales.value[this.selectedLocale.value]["releaseNotes"]) {
            this.invalidReleaseNotes.next(false);
        }
        this.updateInput("releaseNotes", event);
        if (this.invalidReleaseNotes.value === true) {
            this.validateReleaseNotes();
        }
    }

    onReleaseNotesFocusOut() {
        this.validateReleaseNotes();
        this.checkIfLocaleIsMandatoryAndCleanValidatorsIfEmpty();
    }

    updateScreenshots(imageList) {
        this.indicativeService.sendEvent("app_submission_form_interacting");
        this.indicativeService.sendEvent("app_submission_form_languages_media_interacting");

        if (imageList.length > 0) {
            this.screenShotsValidationErrorList.next([]);
            this.screenShotsValidationLoading.next(true);

            imageList.forEach((img, index, imageListArray) => {
                const imageData = {
                    type: "screenshot",
                    fileMimeType: img.plainFile.type,
                    height: img.height,
                    width: img.width,
                    size: img.plainFile.size,
                };

                this.validateImageData(imageData).subscribe({
                    next: (response: any) => {
                        if (!response) {
                            this.screenShotsValidationErrorList.next([this.translate.instant("imageRules.default")]);
                        }
                        if (response && !response.isValid) {
                            response.rules.forEach((rule) => {
                                if (!rule.isValid) {
                                    let currentErrorList: string[] = this.screenShotsValidationErrorList.value;
                                    currentErrorList.push(this.utilsService.returnParsedImageValidationError(rule, imageData));
                                    this.screenShotsValidationErrorList.next(currentErrorList);
                                }
                            });
                        }
                        if (response && response.isValid) {
                            this.pushScreenshot(img, String(this.selectedLocale.value));
                        }
                        this.turnScreenShotValidationLoadingOffIfOnLastScreenshot(index, imageListArray);
                    },
                    error: () => {
                        this.screenShotsValidationErrorList.next([this.translate.instant("imageRules.default")]);
                        this.turnScreenShotValidationLoadingOffIfOnLastScreenshot(index, imageListArray);
                    },
                });
            });
        }
    }

    turnScreenShotValidationLoadingOffIfOnLastScreenshot(index, imageListArray) {
        if (index === imageListArray.length - 1) {
            this.screenShotsValidationLoading.next(false);
        }
    }

    validateImageData(imageData: ImageDataForValidation) {
        return this.utilsService.getValidationForImageData(imageData);
    }

    validateTitle() {
        this.invalidTitle.next(!this.titleValidator(this.locales.value[this.selectedLocale.value]["title"]));
    }

    titleValidator(title: string) {
        return title && title.length >= 1 && title.length < 100;
    }

    validateDescription() {
        this.invalidDescription.next(!this.descriptionValidator(this.locales.value[this.selectedLocale.value]["description"]));
    }

    validateReleaseNotes() {
        this.invalidReleaseNotes.next(!this.releaseNotesValidator(this.locales.value[this.selectedLocale.value]["releaseNotes"]));
    }

    descriptionValidator(text: string) {
        return text && text.length >= 1 && text.length < 10000;
    }

    releaseNotesValidator(text: string) {
        return text && text.length >= 1 && text.length < 20000;
    }

    validateScreenShots() {
        this.screenShotsValidationErrorList.next([]);
        if (!this.locales.value[this.selectedLocale.value]["screenshots"] || !this.locales.value[this.selectedLocale.value]["screenshots"].length) {
            this.screenShotsValidationErrorList.next([this.translate.instant("imageRules.missingImage")]);
            return;
        }
    }

    validateLanguageMediaAndChangeSelectedLocaleIfNeeded() {
        if (!this.isIncompleteOrInvalidLocale(this.locales.value[this.selectedLocale.value], this.selectedLocale.value === "en_GB" ? true : false)) {
            let incompleteLocaleSelectElement = this.findAndReturnIncompleteLocaleSelectElement();
            if (incompleteLocaleSelectElement) {
                this.selectedLocale = incompleteLocaleSelectElement;
            }
        }

        this.validateActualLanguageMediaFields();
    }

    findAndReturnIncompleteLocaleSelectElement(): SelectEntry {
        const localesObjectKeys = Object.keys(this.locales.value);

        for (let index = 0; index < localesObjectKeys.length; index++) {
            const key = localesObjectKeys[index];
            if (this.isIncompleteOrInvalidLocale(this.locales.value[key], key === "en_GB" ? true : false)) {
                return this.utilsService.locales.value.find((languageSelectData: SelectEntry) => languageSelectData.value === key);
            }
        }

        return null;
    }

    validateActualLanguageMediaFieldsIfNotEmpty() {
        this.validateLanguageMediaFieldsIfNotEmpty(this.locales.value[this.selectedLocale.value]);
    }

    validateLanguageMediaFieldsIfNotEmpty(locale) {
        if (locale["title"] && locale["title"].length) {
            this.validateTitle();
        }
        if (locale["description"] && locale["description"].length) {
            this.validateDescription();
        }
        if (locale["releaseNotes"] && locale["releaseNotes"].length) {
            this.validateReleaseNotes();
        }
    }

    validateActualLanguageMediaFields() {
        this.validateTitle();
        this.validateDescription();
        this.validateReleaseNotes();
        this.validateScreenShots();
    }

    isEmptyLocale(locale: any) {
        return (
            !locale ||
            ((!locale.title || !locale.title.length) &&
                (!locale.description || !locale.description.length) &&
                (!locale.releaseNotes || !locale.releaseNotes.length) &&
                (!locale.screenshots || !locale.screenshots?.length))
        );
    }

    isIncompleteOrInvalidLocale(locale: any, deepCheck: boolean) {
        if (deepCheck) {
            return (
                this.isEmptyLocale(locale) ||
                !locale.title ||
                !this.titleValidator(locale.title) ||
                !locale.description ||
                !this.descriptionValidator(locale.description) ||
                !locale.releaseNotes ||
                !this.releaseNotesValidator(locale.releaseNotes) ||
                !locale.screenshots ||
                !locale.screenshots?.length
            );
        }

        return (
            !this.isEmptyLocale(locale) &&
            (!locale.title ||
                !this.titleValidator(locale.title) ||
                !locale.description ||
                !this.descriptionValidator(locale.description) ||
                !locale.releaseNotes ||
                !this.releaseNotesValidator(locale.releaseNotes) ||
                !locale.screenshots ||
                !locale.screenshots?.length)
        );
    }

    pushScreenshot(image: string, locale: string) {
        let _locales = this.locales.value;
        if (_locales[locale] && !_locales[locale].screenshots) {
            _locales[locale].screenshots = [];
        }

        _locales[locale].screenshots.push(image);
        this.locales.next(_locales);
        this.isValid.next(!!this.validateForm());
    }

    removeScreenshot(index: number) {
        let _locales = this.locales.value;
        _locales[this.selectedLocale.value].screenshots.splice(index, 1);
        this.locales.next(_locales);
        this.isValid.next(!!this.validateForm());
        this.validateScreenShots();
        this.checkIfLocaleIsMandatoryAndCleanValidatorsIfEmpty();
    }

    updateInput(attr, newValue) {
        let _locales = this.locales.value;
        if (typeof newValue === "string") {
            newValue = newValue.trim();
        }

        _locales[this.selectedLocale.value][attr] = newValue;
        this.locales.next(_locales);
        this.isValid.next(!!this.validateForm());
    }

    validateForm() {
        if (!this.displayLanguagesMedia.value) {
            return true;
        }

        if (Object.keys(this.locales.value).length <= 0) {
            return false;
        }

        return !this.findAndReturnIncompleteLocaleSelectElement();
    }

    getFormData(): FormData {
        const formData = new FormData();
        if (!!this.isValid.value && !!this.displayLanguagesMedia.value) {
            Object.keys(this.locales.value).map((locale) => {
                if (!this.isIncompleteOrInvalidLocale(this.locales.value[locale], true)) {
                    formData.append(`locales[${locale}][title]`, this.locales.value[locale].title);
                    formData.append(`locales[${locale}][description]`, this.locales.value[locale].description);
                    formData.append(`locales[${locale}][news]`, this.locales.value[locale].releaseNotes);

                    const oldScreenshots: number[] = [];
                    this.locales.value[locale].screenshots
                        .filter((screenshot) => screenshot.id)
                        .map((screenshot) => {
                            oldScreenshots.push(screenshot.id);
                        });

                    if (oldScreenshots.length) {
                        formData.append(`locales[${locale}][screenshotIds]`, oldScreenshots.join(","));
                    }

                    const newScreenshots: any[] = [];
                    this.locales.value[locale].screenshots
                        .filter((screenshot) => !screenshot.id)
                        .map((screenshot) => {
                            newScreenshots.push(screenshot.plainFile);
                        });

                    for (let screenshotIndex in newScreenshots) {
                        formData.append(`locales[${locale}][screenshots][${screenshotIndex}]`, newScreenshots[screenshotIndex]);
                    }
                }
            });
        }

        return formData;
    }

    getFormDataForDraft(): FormData {
        const formData = new FormData();
        if (!!this.displayLanguagesMedia.value) {
            Object.keys(this.locales.value).map((locale) => {
                if (this.locales.value[locale].title) {
                    formData.append(`locales[${locale}][title]`, this.locales.value[locale].title);
                }
                if (this.locales.value[locale].description) {
                    formData.append(`locales[${locale}][description]`, this.locales.value[locale].description);
                }
                if (this.locales.value[locale].releaseNotes) {
                    formData.append(`locales[${locale}][news]`, this.locales.value[locale].releaseNotes);
                }



                if (this.locales.value[locale].screenshots) {
                    const oldScreenshots: number[] = [];
                    this.locales.value[locale].screenshots
                        .filter((screenshot) => screenshot.id)
                        .map((screenshot) => {
                            oldScreenshots.push(screenshot.id);
                        });

                    if (oldScreenshots.length) {
                        formData.append(`locales[${locale}][screenshotIds]`, oldScreenshots.join(","));
                    }
                    const newScreenshots: any[] = [];
                    this.locales.value[locale].screenshots
                        .filter((screenshot) => !screenshot.id)
                        .map((screenshot) => {
                            newScreenshots.push(screenshot.plainFile);
                        });

                    for (let screenshotIndex in newScreenshots) {
                        formData.append(`locales[${locale}][screenshots][${screenshotIndex}]`, newScreenshots[screenshotIndex]);
                    }
                }


            });
        }

        return formData;
    }
}
