import { Injectable } from "@angular/core";
import { APIRequestsService } from "@app/core/services/api.requests.service";
import { ALLOWED_PARTNER_ACCOUNT_ICONS } from "@app/core/services/constants";
import { BehaviorSubject } from "rxjs";
import { UserService } from "./user.service";

export interface AvailableDistributionChannelsListItem {
    name: string;
    displayName: string;
    icon: string;
    uid: string;
}

@Injectable({
    providedIn: "root",
})
export class AvailableDistributionChannelsService {
    accountUid: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    availableDistributionChannels: BehaviorSubject<AvailableDistributionChannelsListItem[]> = new BehaviorSubject<AvailableDistributionChannelsListItem[]>([]);

    constructor(private requestService: APIRequestsService, private userService: UserService) {
        this.userService.loggedAccount.subscribe({
            next: (account: any) => {
                if (account && account.uid && this.accountUid.value !== account.uid) {
                    this.accountUid.next(account.uid);
                    this.getData();
                }
            },
        });
    }

    getData() {
        this.requestService.get("/api/applications/availableDistributionChannels").subscribe({
            next: (res: any) => {
                const formattedResponse: AvailableDistributionChannelsListItem[] = res
                    .filter((item: any) => {
                        return item.displayName;
                    })
                    .map((item: any) => {
                        const icon: string = (() => {
                            for (const key of Object.keys(ALLOWED_PARTNER_ACCOUNT_ICONS)) {
                                if (item.displayName.toLowerCase().includes(key.toLocaleLowerCase())) {
                                    return ALLOWED_PARTNER_ACCOUNT_ICONS[key];
                                }
                            }
                            return "/assets/images/unknown-rectangle.svg";
                        })();

                        return {
                            name: item.name,
                            displayName: item.displayName,
                            icon,
                            uid: item.uid,
                        } as AvailableDistributionChannelsListItem;
                    });

                this.availableDistributionChannels.next(
                    formattedResponse.sort((a: AvailableDistributionChannelsListItem, b: AvailableDistributionChannelsListItem) => a.displayName.localeCompare(b.displayName))
                );
            },
        });
    }
}
