import { Injectable } from "@angular/core";
import { AvailableDistributionChannelsListItem, AvailableDistributionChannelsService } from "@app/core/services/available-distribution-channels.service";
import { BehaviorSubject } from "rxjs";

export interface SelectableAvailableDistributionChannelsListItem extends AvailableDistributionChannelsListItem {
    selected: boolean;
}

@Injectable({
    providedIn: "root",
})
export class DistributionChannelsService {
    loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    data: BehaviorSubject<string | string[]> = new BehaviorSubject<string | string[]>("");
    selectableList: BehaviorSubject<SelectableAvailableDistributionChannelsListItem[]> = new BehaviorSubject<SelectableAvailableDistributionChannelsListItem[]>([]);

    constructor(private availableDistributionChannelsService: AvailableDistributionChannelsService) {}

    init(value: string | string[]) {
        this.loading.next(true);

        this.data.next(value);

        this.availableDistributionChannelsService.availableDistributionChannels.subscribe({
            next: (availableDistributionChannels: AvailableDistributionChannelsListItem[]) => {
                if (availableDistributionChannels.length === 0) {
                    return;
                }

                this.selectableList.next(
                    availableDistributionChannels.map((item: AvailableDistributionChannelsListItem) => {
                        return {
                            ...item,
                            selected: (() => {
                                if (!this.data.value) {
                                    return false;
                                }

                                if (this.data.value === "all") {
                                    return true;
                                }

                                if (!Array.isArray(this.data.value)) {
                                    return false;
                                }

                                return this.data.value.includes(item.uid);
                            })(),
                        };
                    })
                );
                this.loading.next(false);
            },
        });
    }

    getFormData(): FormData {
        const formData = new FormData();

        const formattedDistributionChannels: any = (() => {
            const selectedUids: string[] = this.selectableList.value
                .filter((item: SelectableAvailableDistributionChannelsListItem) => {
                    return item.selected;
                })
                .map((item: SelectableAvailableDistributionChannelsListItem) => {
                    return item.uid;
                });

            if (selectedUids.length === 0) {
                return "";
            }

            if (selectedUids.length === this.availableDistributionChannelsService.availableDistributionChannels.value.length) {
                return "all";
            }

            return selectedUids.join(",");
        })();

        formData.append("distributionChannels", formattedDistributionChannels);

        return formData;
    }
}
