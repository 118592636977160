import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { DashboardTopTableEntry } from "../utils";
import { StatisticsFiltersService } from "../settings.service";
import { switchMap } from "rxjs/operators";
import { APIRequestsService, RequestParameters } from "@app/core/services/api.requests.service";
import { DataService } from "@app/core/services/data-service";
import { ALLOWED_PARTNER_ACCOUNT_ICONS } from "@app/core/services/constants";

@Injectable({
    providedIn: "root",
})
export class StatisticsDownloadsTopStoresDataService extends DataService {
    fetch = new BehaviorSubject<boolean>(true);
    loadingData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    topStores: BehaviorSubject<DashboardTopTableEntry[]> = new BehaviorSubject<DashboardTopTableEntry[]>([]);

    constructor(private statisticsFiltersService: StatisticsFiltersService, private requestsService: APIRequestsService) {
        super(1, !(window.location.href.endsWith("/") || window.location.href.endsWith("/statistics")));
    }

    init() {
        this.statisticsFiltersService.dateFilters.subscribe({
            next: (x: any) => {
                if (x) {
                    this.fetch.next(true);
                }
            },
        });

        this.statisticsFiltersService.dataFilters.subscribe({
            next: (x: any) => {
                if (x) {
                    this.fetch.next(true);
                }
            },
        });

        this.fetch
            .pipe(
                switchMap((x) => {
                    return this.getData();
                })
            )
            .subscribe({
                next: (data: any) => {
                    const top: DashboardTopTableEntry[] = [];
                    for (let key in data) {
                        const icon: string = (() => {
                            for (const partner of Object.keys(ALLOWED_PARTNER_ACCOUNT_ICONS)) {
                                if (data[key].identifiers.store.account.name.toLowerCase().includes(partner.toLocaleLowerCase())) {
                                    return ALLOWED_PARTNER_ACCOUNT_ICONS[partner];
                                }
                            }
                            return "/assets/images/unknown-square.svg";
                        })();

                        top.push(
                            new DashboardTopTableEntry(
                                top.length + 1,
                                data[key].identifiers.store.account.uid,
                                data[key].identifiers.store.account.name ? data[key].identifiers.store.account.name : data[key].identifiers.store.account.uid,
                                data[key].total,
                                null,
                                null,
                                icon
                            )
                        );
                    }
                    this.topStores.next(top);
                    this.loadingData.next(false);
                },
                error: (_error: any) => {
                    this.topStores.next([]);
                    this.loadingData.next(false);
                },
            });
    }

    getData() {
        this.loadingData.next(true);
        const parameters: RequestParameters[] = [
            ...this.statisticsFiltersService.getDateFiltersRequestHeaders(),
            ...this.statisticsFiltersService.getDataFiltersRequestHeaders(false),
            {
                label: "type",
                data: "downloads",
            },
            {
                label: "groupBy",
                data: "store",
            },
            {
                label: "sort",
                data: "revenue",
            },
            {
                label: "order",
                data: "DESC",
            },
            {
                label: "limit",
                data: 100,
            },
            {
                label: "offset",
                data: 0,
            },
        ];
        return this.requestsService.get("/api/statistics/top", parameters);
    }
    getDataForCSV(limit: number, offset: number) {
        const parameters: RequestParameters[] = [
            ...this.statisticsFiltersService.getDateFiltersRequestHeaders(),
            ...this.statisticsFiltersService.getDataFiltersRequestHeaders(false),
            {
                label: "type",
                data: "downloads",
            },
            {
                label: "groupBy",
                data: "store",
            },
            {
                label: "sort",
                data: "revenue",
            },
            {
                label: "order",
                data: "DESC",
            },
            {
                label: "limit",
                data: limit,
            },
            {
                label: "offset",
                data: offset,
            },
        ];
        return this.requestsService.get("/api/statistics/top", parameters, true);
    }
}
