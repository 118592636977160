
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ErrorBoxComponent } from './error-box.component';
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  imports:      [CommonModule, TranslateModule],
  declarations: [ ErrorBoxComponent ],
  exports:      [ ErrorBoxComponent ]
})
export class  ErrorBoxModule { }