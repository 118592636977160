import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "app-checkbox",
    templateUrl: "./checkbox.component.html",
    styleUrls: ["./checkbox.component.scss"],
})
export class CheckboxComponent {
    @Input() checked: boolean = false;
    @Input() disabled: boolean = false;
    @Input() placeholder: string = null;
    @Input() pinkTheme: boolean = false;
    @Input() reversed: boolean = false;
    @Input() greenTheme: boolean = false;
    @Output() changeEmitter = new EventEmitter<any>();

    change(evt) {
        this.changeEmitter.emit(evt);
    }
}
