<div
  class="button-container"
  (click)="toggleDropdown()"
  [class.scale]="!(showDropdown | async)"
>
  <div *ngIf="label" class="label-container">
    <img
      class="desktopIcon"
      *ngIf="userDropdown"
      (error)="img.src = 'assets/images/appicon-fallback.svg'"
      #img
      [src]="iconSrc"
    />
    <img
      *ngIf="iconSrcMobile && userDropdown"
      class="mobileIcon"
      (error)="img.src = 'assets/images/appicon-fallback.svg'"
      #img
      [src]="iconSrcMobile"
    />
  
    <ng-container *ngIf="showDropdown | async">
      <div
        class="dropdown-content"
        [class.left-alignment]="alignment === 'left'"
        [class.right-alignment]="alignment === 'right'"
        [@enterAnimation]
        [delayClickOutsideInit]="true"
        (clickOutside)="closeDropdown()"
      >
        <div class="user-drop" [ngclass]="{ 'menu-list': showArrow }">
          <ng-content></ng-content>
        </div>
      </div>
    </ng-container>
  </div>
</div>
