export const environment = {
    baseNodeUrlServices: "https://ws.catappult.io",
    baseUploaderNodeUrlServices: "https://uploader.catappult.io",
    basePortalUrl: "https://catappult.io",
    catappultTokenCookie: "CAT-TOKEN",
    clientKey: "live_CURXTJUT7RDSZI4JPZXPNYEYXAGBJ2BF",
    domainCookie: ".catappult.io",
    environment: "live",
    name: "production",
    production: true,
    timestamp: "1577105009578",
    adminConsoleUrl: "https://admin.catappult.io",
    partnersConsoleUrl: "https://partners.catappult.io",
    withdrawUnderMaintenance: false,
    uploaderUnderMaintenance: false,
    directLandingUrl: "https://direct.catappult.io",
    aptoideMmp: "https://aptoide-mmp.aptoide.com/api/v1",
    amazonPay: {
        merchantId: "A3VR0I1IOKZV2Z",
        sandbox: false,
        checkoutSessionConfigAlgorithm: "AMZN-PAY-RSASSA-PSS-V2",
        checkoutSessionConfigPublicKeyId: "LIVE-AGXSYZOJTIRRUH3YS7RKWXDM",
    },
};
