import { CommonModule, DecimalPipe } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatExpansionModule } from "@angular/material/expansion";
import { RouterModule } from "@angular/router";
import { AutocompleteModule } from "@app/shared/autocomplete/autocomplete.module";
import { ButtonModule } from "@app/shared/button/button.module";
import { MobileNavigationBarModule } from "@app/shared/mobile-navigation-bar/mobile-navigation-bar.module";
import { DropdownLanguagesComponent } from "@app/shared/dropdown-languages/dropdown-languages.component";
import { DropdownAccountsComponent } from "@app/shared/dropdown-accounts/dropdown-accounts.component";
import { ErrorBoxModule } from "@app/shared/error-box/error-box.module";
import { TotalPipe } from "@app/shared/pipes/pipes";
import { PipesModule } from "@app/shared/pipes/pipes.module";
import { LoadingSpinnerModule } from "@app/shared/spinner/loading-spinner.module";
import { TranslateModule } from "@ngx-translate/core";
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { ImageUploaderModule } from "./image-uploader/image-uploader.module";
import { MissingDataModalComponent } from "./missing-data-modal/missing-data-modal.component";
import { AccountSubscriptionModule } from "./notifications-dropdown/splash-screens/account-subscription/account-subscription.module";
import { ButtonDropdownModule } from "./button-dropdown/button-dropdown.module";
import { TooltipDirectiveModule } from "./tooltip/tooltip.directive.module";
import { AccountInformation } from "./account-information-form/account-information-form.module";

@NgModule({
  imports: [
    ButtonDropdownModule,
    PipesModule,
    NgClickOutsideDirective,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    TooltipDirectiveModule,
    AccountSubscriptionModule,
    ImageUploaderModule,
    AutocompleteModule,
    RouterModule,
    ButtonModule,
    MobileNavigationBarModule,
    LoadingSpinnerModule,
    ErrorBoxModule,
    CommonModule,
    TranslateModule,
    AccountInformation
  ],
  exports: [
    PipesModule,
    TooltipDirectiveModule,
    CommonModule,
    FormsModule,
    TranslateModule,
    ButtonDropdownModule,
    DropdownLanguagesComponent,
    MatExpansionModule,
    ImageUploaderModule,
    DropdownAccountsComponent
  ],
  declarations: [
    MissingDataModalComponent,
    DropdownLanguagesComponent,
    DropdownAccountsComponent
  ],
  providers: [DecimalPipe, TotalPipe],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}