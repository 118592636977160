import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { IndicativeService } from "@app/core/services/indicative.service";
import { UserService } from "@app/core/services/user.service";
import { SubmissionService } from "@app/modules/my-apps/submission/submission.service";
import { AddAppService } from "./add-app.service";

@Component({
  selector: "app-add-app-dialog",
  templateUrl: "./add-app-dialog.component.html",
  styleUrls: ["./add-app-dialog.component.scss"],
})
export class AddAppDialogComponent implements OnInit {
  isYourAppInGooglePlayOption: string = "yes";
  
  constructor(
    public userService: UserService,
    public addAppService: AddAppService,
    private dialogRef: MatDialogRef<AddAppDialogComponent>,
    public submission: SubmissionService,
    private indicativeService: IndicativeService
  ) { }

  ngOnInit(): void {
    this.indicativeService.sendEvent(`add_app_visited`);
    this.addAppService.resetService();
  }

  isYourAppInGooglePlayOptionChange() {
    this.indicativeService.sendEvent(`add_app_interacting`);

    if (this.isYourAppInGooglePlayOption === "no") {
      this.indicativeService.sendEvent(`add_app_yes`);
    }

    if (this.isYourAppInGooglePlayOption === "yes") {
      this.indicativeService.sendEvent(`add_app_no`);
    }

    this.addAppService.appPackageName.next("");
    this.addAppService.resetErrors();
  }

  updateAppPackageName(event: any) {
    this.indicativeService.sendEvent(`add_app_interacting`);
    this.indicativeService.sendEvent(`add_app_typing_app_package_name`);
    this.addAppService.updateAppPackageName(event);
  }

  updateAppPackageNameWithGooglePlayUrl(event: any) {
    this.indicativeService.sendEvent(`add_app_interacting`);
    this.indicativeService.sendEvent(`add_app_typing_app_package_name`);
    this.addAppService.updateAppPackageNameWithGooglePlayUrl(event);
  }

  next() {
    this.indicativeService.sendEvent(`add_app_attempting`);
    this.addAppService.startAppInfoFromPackageNameVerification();
  }

  noItsNot() {
    this.indicativeService.sendEvent(`add_app_google_play_no`);
    this.addAppService.noItsNot();
  }

  yesItIs() {
    this.indicativeService.sendEvent(`add_app_google_play_yes`);
    this.addAppService.yesItIs();
  }

  goToSubmission() {
    this.dialogRef.close();
    this.addAppService.goToSubmission();
  }

  close() {
    this.dialogRef.close();
  }
}
