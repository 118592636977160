import { Injectable } from "@angular/core";
import { APIRequestsService } from "@app/core/services/api.requests.service";
import { BehaviorSubject } from "rxjs";
import { ApplicationsPerformanceCardService } from "./cards/applications-performance/applications-performance.card.service";
import { ApplicationsListCardService } from "./cards/applications-list/applications-list.card.service";
import { WalletPreviewService } from "./cards/wallet-preview/wallet-preview.card.service";
import { ReachFurtherCardService } from "./cards/reach-further/reach-further.card.service";
import { SalesStatisticsPreviewCardService } from "./cards/sales-statistics-preview/sales-statistics-preview.card.service";
import { DownloadsStatisticsPreviewCardService } from "./cards/downloads-statistics-preview/downloads-statistics-preview.card.service";
import { CacheService } from "@app/core/services/cache.service";
export interface DashboardCardsListItem {
    name: string;
    data?: any;
}
@Injectable({
    providedIn: "root",
})
export class DashboardService {
    loadingDashboardCards: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    directToConsumer: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public dashboardCards: DashboardCardsListItem[] = [];

    constructor(
        private requestsService: APIRequestsService,
        private applicationsListCardService: ApplicationsListCardService,
        private applicationsPerformanceCardService: ApplicationsPerformanceCardService,
        private walletPreviewService: WalletPreviewService,
        private reachFurtherCardService: ReachFurtherCardService,
        private salesStatisticsPreviewCardService: SalesStatisticsPreviewCardService,
        private downloadsStatisticsPreviewCardService: DownloadsStatisticsPreviewCardService,
        private cacheService: CacheService
    ) {}

    init() {
        this.loadDashboardCards();
        this.cacheService.removeCacheEntries("/api/wallet");
        this.walletPreviewService.getBalance();
        this.cacheService.removeCacheEntries("/api/dashboard/developers/applicationsList");
        this.applicationsListCardService.getAllPackagesList();

        this.salesStatisticsPreviewCardService.init();
        this.salesStatisticsPreviewCardService.fetch.next(true);
        this.downloadsStatisticsPreviewCardService.init();
        this.downloadsStatisticsPreviewCardService.fetch.next(true);
        this.applicationsPerformanceCardService.getStatisticsSummary();
        this.reachFurtherCardService.checkPendingSubmissions();
    }

    loadDashboardCards() {
        this.requestsService.get("/api/dashboard/developers/cards").subscribe({
            next: (res: any) => {
                this.dashboardCards = res;

                const selfDistributionCard = this.dashboardCards.find((x) => x.name === "directToConsumer");

                if (selfDistributionCard && selfDistributionCard["data"]) {
                    this.directToConsumer.next(selfDistributionCard["data"]);
                }

                this.loadingDashboardCards.next(false);
            },
            error: (_error: any) => {
                this.loadingDashboardCards.next(false);
            },
        });
    }
}
