import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SubmissionService } from '@app/modules/my-apps/submission/submission.service';

@Component({
  selector: 'app-leaving-submission-confirmation-modal',
  templateUrl: './leaving-submission-confirmation-modal.component.html',
  styleUrl: './leaving-submission-confirmation-modal.component.scss'
})
export class LeavingSubmissionConfirmationModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public bodyText: string,
    private dialogRef: MatDialogRef<LeavingSubmissionConfirmationModalComponent>,
    public submissionService: SubmissionService
  ) { 

  }

  closeDialog(){
    this.dialogRef.close(false);

  }

  leave() {
    this.submissionService.saveAsDraft().subscribe({
      next: () => {
        this.dialogRef.close(true);
        this.submissionService.savingDraft.next(false);
      },
      error: () => {
        this.submissionService.savingDraft.next(false);

      },
    });;
  }

  cancel() {
    this.dialogRef.close(true);
  }
}
