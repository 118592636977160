import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent{
  @Input() align: 'overlay' | 'horizontal';
  @Input() size: string = 'normal';
  @Input() loadingMessage: string = 'Loading data ...';
  @Input() doNotAlignCenter: boolean = false;
  @Input() textColor: string = 'default';

  constructor() { }

}
