<div class="button-container" (click)="toggleDropdown()">
  <div *ngIf="!isMobile" class="language-ballon-white">
    <img [src]="'/assets/images/ballon-white.svg'" />
    <span class="no-select">{{ selectedLanguage.code }}</span>
  </div>

  <div
    *ngIf="showDropdown | async"
    class="dropdown-content"
    [@enterAnimation]
    [delayClickOutsideInit]="true"
    (clickOutside)="closeDropdown()"
  >
      <div 
        *ngIf="isMobile" 
        class="menu-item"
        (click)="onBackClick()"
      >
        <p class="back xxxs" translate><label><</label>common.back</p>
      </div>
      <div
        *ngFor="let language of languageOptions"
        class="menu-item"
        [class.selected]="selectedLanguage.id === language.id"
        (click)="switchLanguage(language)"
      >
        <p>
          {{ language.label }}
        </p>
        <img
          *ngIf="selectedLanguage.id === language.id"
          [src]="isMobile ? '/assets/images/checkmark_on.svg' : '/assets/images/selected-language.svg'"
        />
      </div>
  </div>
</div>