import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-error-box',
  templateUrl: './error-box.component.html',
  styleUrls: ['./error-box.component.scss']
})
export class ErrorBoxComponent{

  @Input() isMobile: boolean = false;
  @Input() contactSupport: boolean = true;
  @Input() white: boolean = false;
  @Output() tryAgainEmitter: EventEmitter<any> = new EventEmitter();

  constructor() { }

  public openIntercom() {
    (<any>window).Intercom("show");
  }

  tryAgain(){
    this.tryAgainEmitter.emit(true);
  }

}
