<div *ngIf="!hideUploader" class="container" [class.showError]="showError" appDnd (fileDropped)="onFileDropped($event)">
  <input
    type="file"
    #fileDropRef
    id="fileDropRef"
    accept="{{ acceptedFiles }}"
    onclick="this.value=null"
    (change)="fileBrowseHandler($event.target.files)"
    [disabled]="disabled"
    multiple
  />
  <div class="inside" [class.img-title]="horizontal">
    <img [class.small-img]="horizontal" [src]="imgSrc" />
    <div *ngIf="!(loading)">
      <h5 *ngIf="title">{{ title }}</h5>
      <p translate class="dnd-info blueDark">{{multiple ? 'upload.form.dndInfo' :'upload.form.dndInfoSingle'}}</p>
      <p class="accept-files">{{ acceptedFilesText }}</p>
    </div>
    <app-loading-spinner
      *ngIf="loading"
      align="horizontal"
      size="small"
    ></app-loading-spinner>
  </div>
</div>

<div *ngIf="uploadedFile" class="files-list">
  <div class="single-file" [class.white-background]="whiteBackgroundOnUploadedFile">
    <div class="file-icon" style="width: 50px">
      <img [src]="imgSrc" />
    </div>
    <div class="info">
      <p>
        {{ humanize(uploadedFile.name) }}
      </p>
    </div>

    <div class="delete">
      <img *ngIf="uploadedFile.downloadUrl" src="/assets/images/download-icon-appview.svg" class="pointer" (click)="downloadUploadedFile()">
      <svg *ngIf="!readOnly" (click)="deleteUploadedFile()"
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="18"
        viewBox="0 0 14 18"
      >
        <path
          fill="#B1B1B1"
          fill-rule="nonzero"
          d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v10zm3.17-7.83a.996.996 0 0 1 1.41 0L7 9.59l1.42-1.42a.996.996 0 1 1 1.41 1.41L8.41 11l1.42 1.42a.996.996 0 1 1-1.41 1.41L7 12.41l-1.42 1.42a.996.996 0 1 1-1.41-1.41L5.59 11 4.17 9.58a.996.996 0 0 1 0-1.41zM10.5 1L9.79.29C9.61.11 9.35 0 9.09 0H4.91c-.26 0-.52.11-.7.29L3.5 1H1c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z"
        />
      </svg>
    </div>
  </div>
</div>

<div *ngIf="uploadedFiles && uploadedFiles.length" class="files-list">
  <div class="single-file" [class.white-background]="whiteBackgroundOnUploadedFile" *ngFor="let file of uploadedFiles; let i = index">
    <div class="file-icon" style="width: 50px">
      <img [src]="imgSrc" />
    </div>
    <div class="info">
      <p class="name">
        {{ file?.name }}
      </p>
      <p class="size">
        {{ formatBytes(file?.size) }}
      </p>
    </div>

    <div *ngIf="!readOnly" class="delete" (click)="deleteUploadedFiles(i)">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="18"
        viewBox="0 0 14 18"
      >
        <path
          fill="#B1B1B1"
          fill-rule="nonzero"
          d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v10zm3.17-7.83a.996.996 0 0 1 1.41 0L7 9.59l1.42-1.42a.996.996 0 1 1 1.41 1.41L8.41 11l1.42 1.42a.996.996 0 1 1-1.41 1.41L7 12.41l-1.42 1.42a.996.996 0 1 1-1.41-1.41L5.59 11 4.17 9.58a.996.996 0 0 1 0-1.41zM10.5 1L9.79.29C9.61.11 9.35 0 9.09 0H4.91c-.26 0-.52.11-.7.29L3.5 1H1c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z"
        />
      </svg>
    </div>
  </div>
</div>

<div *ngIf="!(uploadedFile || (uploadedFiles && uploadedFiles.length))" class="files-list">
  <div class="single-file" [class.white-background]="whiteBackgroundOnUploadedFile" *ngFor="let file of files; let i = index">
    <div class="file-icon" style="width: 50px">
      <img [src]="imgSrc" />
    </div>
    <div class="info">
      <p class="name">
        {{ file?.name }}
      </p>
      <p class="size">
        {{ formatBytes(file?.size) }}
      </p>
    </div>

    <div *ngIf="!readOnly" class="delete" (click)="deleteFile(i)">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="18"
        viewBox="0 0 14 18"
      >
        <path
          fill="#B1B1B1"
          fill-rule="nonzero"
          d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v10zm3.17-7.83a.996.996 0 0 1 1.41 0L7 9.59l1.42-1.42a.996.996 0 1 1 1.41 1.41L8.41 11l1.42 1.42a.996.996 0 1 1-1.41 1.41L7 12.41l-1.42 1.42a.996.996 0 1 1-1.41-1.41L5.59 11 4.17 9.58a.996.996 0 0 1 0-1.41zM10.5 1L9.79.29C9.61.11 9.35 0 9.09 0H4.91c-.26 0-.52.11-.7.29L3.5 1H1c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z"
        />
      </svg>
    </div>
  </div>
</div>
