import { Component, OnDestroy, OnInit } from "@angular/core";
import { CountriesService } from "@app/core/services/countries.service";
import { AccountHolderDetailsService } from "@app/shared/account-information-form/account-information-form.service";
import { UserService } from "@app/core/services/user.service";
import { BehaviorSubject } from "rxjs";
import { IndicativeService } from "@app/core/services/indicative.service";

@Component({
    selector: "app-account-information-form",
    templateUrl: "./account-information-form.component.html",
    styleUrls: ["./account-information-form.component.scss"],
})
export class AccountHolderDetailsComponent implements OnInit, OnDestroy {
    loadingData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    businessCertificateErrorUploading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    clearBusinessCertificateFiles: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    gettingBusinessCertificate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    uploadingBusinessCertificate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        public accountHolderDetailsService: AccountHolderDetailsService,
        public countriesService: CountriesService,
        public userService: UserService,
        private indicativeService: IndicativeService
    ) {}

    ngOnInit(): void {
        this.loadingData.next(true);

        this.getBusinessCertificate();
        this.userService.loggedAccount.subscribe({
            next: (res) => {
                if (res) {
                    this.accountHolderDetailsService.name.next(res.name ? res.name : "");
                    this.accountHolderDetailsService.address.next(res.address ? res.address : "");
                    this.accountHolderDetailsService.zipCode.next(res.zipCode ? res.zipCode : "");
                    this.accountHolderDetailsService.city.next(res.city ? res.city : "");
                    this.accountHolderDetailsService.vat.next(res.vat ? res.vat : "");
                    this.accountHolderDetailsService.isCompany.next(!!(res.isCompany || res.isCompany === null));
                    this.countriesService.countriesAutocompleteData.subscribe({
                        next: (data: any) => {
                            const country = data.find((country) => {
                                return country?.id?.toUpperCase() === this.userService?.loggedAccount?.value?.country?.toUpperCase();
                            });

                            this.accountHolderDetailsService.country.next(country ? country : null);

                            if (country) {
                                this.accountHolderDetailsService.checkIsEU(country?.id);
                            }
                        },
                    });

                    this.loadingData.next(false);
                }
            },
        });
    }

    ngOnDestroy(): void {
        this.indicativeService.sendEvent(`account_subscription_payment_close_account_data_form`);
    }

    selectIndividual() {
        this.indicativeService.sendEvent(`account_subscription_payment_select_individual`);

        this.accountHolderDetailsService.error.next(null);
        this.accountHolderDetailsService.isCompany.next(false);
    }

    selectCompany() {
        this.indicativeService.sendEvent(`account_subscription_payment_select_company`);

        this.accountHolderDetailsService.error.next(null);
        this.accountHolderDetailsService.isCompany.next(true);

        if (this.accountHolderDetailsService.country.value) {
            this.accountHolderDetailsService.checkIsEU(this.accountHolderDetailsService.country.value["id"]);
        }
    }

    updateName(event: any) {
        this.indicativeService.sendEvent(`account_subscription_payment_typing_name`);

        this.accountHolderDetailsService.name.next(event);
    }

    updateAddress(event: any) {
        this.indicativeService.sendEvent(`account_subscription_payment_typing_address`);

        this.accountHolderDetailsService.address.next(event);
    }

    updateCity(event: any) {
        this.indicativeService.sendEvent(`account_subscription_payment_typing_city`);

        this.accountHolderDetailsService.city.next(event);
    }

    updateZipCode(event: any) {
        this.indicativeService.sendEvent(`account_subscription_payment_zipCode`);

        this.accountHolderDetailsService.zipCode.next(event);
    }

    updateVAT(event: any) {
        this.indicativeService.sendEvent(`account_subscription_payment_typing_VAT`);

        this.accountHolderDetailsService.vat.next(event);
    }

    updateCountry(event: any) {
        this.indicativeService.sendEvent(`account_subscription_payment_typing_country`);

        this.accountHolderDetailsService.error.next(null);
        this.accountHolderDetailsService.country.next(event);

        if (event) {
            this.accountHolderDetailsService.checkIsEU(event.id);
            return;
        }
    }

    downloadBusinessCertificate() {
        window.open(this.accountHolderDetailsService.businessCertificate?.downloadUrl, "_blank");
    }

    deleteBusinessCertificate() {
        this.accountHolderDetailsService.deleteBusinessCertificate(this.userService.loggedAccount.value?.uid, this.accountHolderDetailsService.businessCertificate?.name).subscribe({
            next: () => {
                this.accountHolderDetailsService.businessCertificate = null;
            },
        });
    }

    uploadBusinessCertificate(event: any) {
        if (!event || !event.length) {
            return;
        }

        this.indicativeService.sendEvent(`account_subscription_payment_upload_business_certificate`);

        this.clearBusinessCertificateFiles.next(true);
        this.clearBusinessCertificateFiles.next(false);
        this.businessCertificateErrorUploading.next(false);
        this.uploadingBusinessCertificate.next(true);
        this.accountHolderDetailsService.uploadAccountFile(this.userService.loggedAccount.value?.uid, event).subscribe({
            next: () => {
                this.getBusinessCertificate();
                this.uploadingBusinessCertificate.next(false);
            },
            error: () => {
                this.accountHolderDetailsService.businessCertificate = null;
                this.businessCertificateErrorUploading.next(true);
                this.uploadingBusinessCertificate.next(false);
            },
        });
    }

    getBusinessCertificate() {
        this.gettingBusinessCertificate.next(true);
        this.accountHolderDetailsService.getBusinessCertificate(this.userService.loggedAccount.value?.uid).subscribe({
            next: (businessCertificate: any) => {
                this.accountHolderDetailsService.businessCertificate = businessCertificate;
                this.gettingBusinessCertificate.next(false);
            },
            error: () => {
                this.accountHolderDetailsService.businessCertificate = null;
                this.gettingBusinessCertificate.next(false);
            },
        });
    }
}
