import { Injectable } from '@angular/core';
import { IndicativeService } from '@app/core/services/indicative.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilesUploadService {
  files: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  isValid = new BehaviorSubject<boolean>(true);

  constructor(
    private indicativeService: IndicativeService
  ) { }

  getFormData(): FormData {
    const formData = new FormData();
    this.files?.value?.forEach((f, index) => {
      formData.append(
        `files[${index}]`,
        this.files.value[index],
        this.files.value[index].name
      );
    });

    return formData;
  }

  updateFiles(event: any) {
    this.indicativeService.sendEvent("app_submission_form_interacting");
    this.indicativeService.sendEvent("app_submission_form_files_interacting");

    this.files.next(event);
    this.isValid.next(!!this.validateForm());
  }

  validateForm() {
    return (this.files.value && this.files.value.length > 0);
  }

  reset() {
    this.files.next([]);
    this.isValid.next(true);
  }


}
