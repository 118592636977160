import { Injectable } from "@angular/core";
import { AutocompleteEntry } from "@app/shared/autocomplete/autocomplete.component";
import { DataService } from "@app/core/services/data-service";
import { getFlagUrl } from "@app/core/services/utils.service";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject } from "rxjs";
import { shareReplay, switchMap } from "rxjs/operators";
import { APIRequestsService } from "./api.requests.service";
export interface Country {
    id: string;
    name: string;
    iconUrl: string;
}
@Injectable({
    providedIn: "root",
})
export class CountriesService extends DataService {
    fetchCountries = new BehaviorSubject<boolean>(true);
    fetchCountriesByRegion = new BehaviorSubject<boolean>(true);
    loadingCountriesData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    countriesData: BehaviorSubject<Country[]> = new BehaviorSubject<Country[]>([]);

    countriesByRegionData: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    countriesAutocompleteData: BehaviorSubject<AutocompleteEntry[]> = new BehaviorSubject<AutocompleteEntry[]>([]);

    fetchDistributionCountries = new BehaviorSubject<boolean>(true);
    loadingDistributionCountriesData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    distributionCountriesData: BehaviorSubject<Country[]> = new BehaviorSubject<Country[]>([]);
    distributionCountriesAutocompleteData: BehaviorSubject<AutocompleteEntry[]> = new BehaviorSubject<AutocompleteEntry[]>([]);

    constructor(private requestsService: APIRequestsService, private translateService: TranslateService) {
        super(1);
    }

    init() {
        this.translateService.onLangChange.subscribe({
            next: () => {
                this.fetchCountries.next(true);
                this.fetchCountriesByRegion.next(true);
            },
        });

        this.loadingCountriesData.next(true);
        this.loadingDistributionCountriesData.next(true);
        this.fetchCountries
            .pipe(
                switchMap((x) => {
                    return this.getCountries();
                })
            )
            .subscribe({
                next: (data: any) => {
                    this.countriesData.next(this.parseCountries(data));
                    this.countriesAutocompleteData.next(this.parseCountriesForAutocomplete(data));
                    this.loadingCountriesData.next(false);
                },
                error: (_error: any) => {
                    this.countriesData.next([]);
                    this.countriesAutocompleteData.next([]);
                    this.loadingCountriesData.next(false);
                },
            });
        this.fetchCountriesByRegion
            .pipe(
                switchMap((x) => {
                    return this.getDistributionCountriesByRegion();
                })
            )
            .subscribe({
                next: (data: any) => {
                    this.countriesByRegionData.next(data);
                },
                error: (_error: any) => {
                  this.countriesByRegionData.next(null);

                },
            });

        this.fetchDistributionCountries
            .pipe(
                switchMap((x) => {
                    return this.getDistributionCountries();
                })
            )
            .subscribe({
                next: (data: any) => {
                    this.distributionCountriesData.next(this.parseCountries(data));
                    this.distributionCountriesAutocompleteData.next(this.parseCountriesForAutocomplete(data));
                    this.loadingDistributionCountriesData.next(false);
                },
                error: (_error: any) => {
                    this.distributionCountriesData.next([]);
                    this.distributionCountriesAutocompleteData.next([]);
                    this.loadingDistributionCountriesData.next(false);
                },
            });
    }

    private getCountries() {
        this.loadingCountriesData.next(true);
        return this.requestsService.get("/api/countries").pipe(shareReplay());
    }

    private getDistributionCountries() {
        this.loadingDistributionCountriesData.next(true);
        return this.requestsService.get("/api/applications/availableCountries").pipe(shareReplay());
    }

    private getDistributionCountriesByRegion() {
        this.loadingDistributionCountriesData.next(true);
        return this.requestsService.get("/api/countriesByRegion").pipe(shareReplay());
    }

    private parseCountries(unparsedCountries) {
        const parsedCountries = [];
        unparsedCountries.forEach((element) => {
            parsedCountries.push({
                id: element,
                name: this.translateService.instant("countries." + element),
                iconUrl: getFlagUrl(element.toUpperCase()),
            } as Country);
        });
        parsedCountries.sort((a, b) => {
            return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        });
        return parsedCountries;
    }

    private parseCountriesForAutocomplete(unparsedCountries) {
        const parsedCountriesForAutocomplete = [];
        unparsedCountries.forEach((element) => {
            parsedCountriesForAutocomplete.push({
                id: element,
                value: element,
                label: this.translateService.instant("countries." + element),
                iconUrl: getFlagUrl(element.toUpperCase()),
            } as AutocompleteEntry);
        });
        parsedCountriesForAutocomplete.sort((a, b) => {
            return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
        });
        return parsedCountriesForAutocomplete;
    }
}
