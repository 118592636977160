<div class="content-d">
  <div translate class="title">leavingConfirmationDialog.title</div>
  <div class="bottom-margin">
    <p *ngIf="bodyText ==='willNotBeSaved'" class="text" translate>
      leavingConfirmationDialog.willNotBeSaved
    </p>
    <p *ngIf="bodyText ==='mayNotBeSaved'" class="text" translate>
      leavingConfirmationDialog.mayNotBeSaved
    </p>
  </div>
  <div class="buttons">
    <app-button
      class="button"
      (onClick)="cancel()"
      [small]="true"
      [pinkOnWhite]="true"
      label="{{ 'leavingConfirmationDialog.cancel' | translate }}"
    ></app-button>
    <app-button
      class="button"
      (onClick)="leave()"
      [small]="true"
      label="{{ 'leavingConfirmationDialog.leave' | translate }}"
    ></app-button>
  </div>
</div>
