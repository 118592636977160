import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { DashboardTopTableEntry } from "../utils";
import { StatisticsFiltersService } from "../settings.service";
import { switchMap } from "rxjs/operators";
import { DataService } from "@app/core/services/data-service";
import { APIRequestsService, RequestParameters } from "@app/core/services/api.requests.service";
import { ALLOWED_PARTNER_ACCOUNT_ICONS } from "@app/core/services/constants";

@Injectable({
    providedIn: "root",
})
export class StatisticsRevenueTopStoresDataService extends DataService {
    fetch = new BehaviorSubject<boolean>(true);
    loadingData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    topStores: BehaviorSubject<DashboardTopTableEntry[]> = new BehaviorSubject<DashboardTopTableEntry[]>([]);
    data: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private statisticsFiltersService: StatisticsFiltersService, private requestsService: APIRequestsService) {
        super(1, !(window.location.href.endsWith("/") || window.location.href.endsWith("/statistics")));
    }

    init() {
        this.statisticsFiltersService.dateFilters.subscribe({
            next: (x: any) => {
                if (x) {
                    this.fetch.next(true);
                }
            },
        });

        this.statisticsFiltersService.dataFilters.subscribe({
            next: (x: any) => {
                if (x) {
                    this.fetch.next(true);
                }
            },
        });

        this.statisticsFiltersService.revenueValueToDisplay.subscribe({
            next: (x: any) => {
                if (x) {
                    this.fetch.next(true);
                }
            },
        });

        this.fetch
            .pipe(
                switchMap((x) => {
                    return this.getData();
                })
            )
            .subscribe({
                next: (data: any) => {
                    this.data.next(data);
                    const top: DashboardTopTableEntry[] = [];
                    for (let key in data) {
                        const icon: string = (() => {
                            for (const partner of Object.keys(ALLOWED_PARTNER_ACCOUNT_ICONS)) {
                                if (data[key].identifiers.store.account.name?.toLowerCase().includes(partner.toLocaleLowerCase())) {
                                    return ALLOWED_PARTNER_ACCOUNT_ICONS[partner];
                                }
                            }
                            return "/assets/images/unknown-square.svg";
                        })();
                        top.push(
                            new DashboardTopTableEntry(
                                top.length + 1,
                                data[key].identifiers.store.account.uid,
                                data[key].identifiers.store.account.name ? data[key].identifiers.store.account.name : data[key].identifiers.store.account.uid,
                                data[key].volumeInUsd,
                                "USD",
                                null,
                                icon
                            )
                        );
                    }
                    this.topStores.next(top);
                    this.formatData();
                    this.loadingData.next(false);
                },
                error: (_error: any) => {
                    this.topStores.next([]);
                    this.loadingData.next(false);
                },
            });
    }

    formatData() {
        this.loadingData.next(true);

        if (this.data.value) {
            let top: DashboardTopTableEntry[] = [];
            const value: string = (() => {
                if (this.statisticsFiltersService.revenueValueToDisplay.value.value === "grossVolume") {
                    return "volume";
                }

                return this.statisticsFiltersService.revenueValueToDisplay.value.value;
            })();

            for (const key in this.data.value) {


                const icon: string = (() => {
                    for (const partner of Object.keys(ALLOWED_PARTNER_ACCOUNT_ICONS)) {
                        if (this.data.value[key].identifiers.store.account.name?.toLowerCase().includes(partner.toLocaleLowerCase())) {
                            return ALLOWED_PARTNER_ACCOUNT_ICONS[partner];
                        }
                    }
                    return "/assets/images/unknown-square.svg";
                })();


                top.push(
                    new DashboardTopTableEntry(
                        top.length + 1,
                        this.data.value[key].identifiers.store.account.uid,
                        this.data.value[key].identifiers.store.account.name ? this.data.value[key].identifiers.store.account.name : this.data.value[key].identifiers.store.account.uid,
                        this.data.value[key][value],
                        ["volume", "arpt", "arppu"].includes(value) ? "USD" : "",
                        null,
                        icon

                    )
                );
            }
            top = top.sort((a, b) => (a.value < b.value ? 1 : -1));

            this.topStores.next(top);
            this.loadingData.next(false);
        }
        this.loadingData.next(false);
    }

    getData() {
        this.loadingData.next(true);
        const parameters: RequestParameters[] = [
            ...this.statisticsFiltersService.getDateFiltersRequestHeaders(),
            ...this.statisticsFiltersService.getDataFiltersRequestHeaders(false),
            {
                label: "type",
                data: "revenue",
            },
            {
                label: "groupBy",
                data: "store",
            },
            {
                label: "order",
                data: "DESC",
            },
            {
                label: "limit",
                data: 100,
            },
            {
                label: "offset",
                data: 0,
            },
        ];
        const sortParameters: any = (() => {
            if (this.statisticsFiltersService.revenueValueToDisplay.value.value === "transactions") {
                parameters.push({
                    label: "sort",
                    data: "transactions",
                });
                return;
            }
            if (this.statisticsFiltersService.revenueValueToDisplay.value.value === "volume") {
                parameters.push({
                    label: "sort",
                    data: "usd_revenue",
                });
                return;
            }
            if (this.statisticsFiltersService.revenueValueToDisplay.value.value === "users") {
                parameters.push({
                    label: "sort",
                    data: "wallets",
                });
                return;
            }
            if (this.statisticsFiltersService.revenueValueToDisplay.value.value === "newUsers") {
                parameters.push({
                    label: "sort",
                    data: "newUsers",
                });
                return;
            }
            if (this.statisticsFiltersService.revenueValueToDisplay.value.value === "arppu") {
                parameters.push({
                    label: "sort",
                    data: "arppu",
                });
                parameters.push({
                    label: "amountType",
                    data: "global",
                });
                return;
            }
            if (this.statisticsFiltersService.revenueValueToDisplay.value.value === "grossVolume") {
                parameters.push({
                    label: "sort",
                    data: "usd_revenue",
                });
                parameters.push({
                    label: "amountType",
                    data: "global",
                });
                return;
            }
            if (this.statisticsFiltersService.revenueValueToDisplay.value.value === "arpt") {
                parameters.push({
                    label: "sort",
                    data: "arpt",
                });
                parameters.push({
                    label: "amountType",
                    data: "global",
                });
                return;
            }
        })();
        return this.requestsService.get("/api/statistics/top", parameters);
    }

    getDataForCSV(limit: number, offset: number) {
        const parameters: RequestParameters[] = [
            ...this.statisticsFiltersService.getDateFiltersRequestHeaders(),
            ...this.statisticsFiltersService.getDataFiltersRequestHeaders(false),
            {
                label: "type",
                data: "revenue",
            },
            {
                label: "groupBy",
                data: "store",
            },
            {
                label: "sort",
                data: "revenue",
            },
            {
                label: "order",
                data: "DESC",
            },
            {
                label: "limit",
                data: limit,
            },
            {
                label: "offset",
                data: offset,
            },
        ];
        return this.requestsService.get("/api/statistics/top", parameters, true);
    }
}
