import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";

export interface Country {
    id: string;
    name: string;
    iconUrl: string;
}

export interface App {
    name: string;
    packageName: string;
    iconUrl: string;
}

export class TotalsBoxData {
    id: string = null;
    title: string = null;
    type: string = "total";
    unit: string = null;
    current: number = 0;
    previous: number = 0;
    delta: number = 0;
    tooltip: string = null;
    showComparison: boolean = true;
    loading: boolean = false;
    children: TotalsBoxData[] = [];

    constructor(_id: string, _title: string, _type: string = null, _unit: string = null, _children: any[] = [], _showComparison: boolean = true, _tooltip: string = null) {
        this.id = _id;
        this.title = _title;
        this.type = _type ? _type : _id;
        this.unit = _unit;
        this.showComparison = _showComparison;
        this.tooltip = _tooltip;
        _children.forEach((element) => {
            this.children.push(new TotalsBoxData(element.id, element.title, null, _unit));
        });
    }

    update(_data: any, _showComparison: boolean = false) {
        this.current = _data[this.type];
        this.showComparison = _showComparison;
        if (this.showComparison) {
            this.previous = _data["previous_" + this.type];
            this.delta = _data["delta_" + this.type];
        }
        this.children.forEach((element) => {
            element.update(_data);
        });
    }

    reset() {
        this.current = 0;
        this.previous = 0;
        this.delta = 0;
        this.children.forEach((element) => {
            element.reset();
        });
    }
}
export class DashboardTopTableEntry {
    constructor(
        public id: number = 0,
        public name: string = null,
        public label: string = null,
        public value: number = 0,
        public unit: string = null,
        public unitIcon: string = null,
        public iconSrc: string = null
    ) {}
}

export class DataSetEntry {
    constructor(public label: string, public data: number[]) {}
}
export function updateEntry(_subject: BehaviorSubject<TotalsBoxData>, _data: any, _showComparison: boolean = false) {
    const data = cloneClassObject(_subject.value);
    data.update(_data, _showComparison);
    _subject.next(data);
    toggleEntryLoading(_subject, false);
}

export function resetEntry(_subject: BehaviorSubject<TotalsBoxData>) {
    const data = cloneClassObject(_subject.value);
    data.reset();
    _subject.next(data);
    toggleEntryLoading(_subject, false);
}
export function cloneClassObject(_object: any) {
    return Object.assign(Object.create(Object.getPrototypeOf(_object)), _object);
}
export function toggleEntryLoading(_subject: BehaviorSubject<TotalsBoxData>, _loading: boolean) {
    const data = _subject.value;
    data.loading = _loading;
    _subject.next(data);
}
