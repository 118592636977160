
<div class="spinner-container">
  <svg class="spinner" 
    [class.spinner--bigWithNoText]="size === 'bigWithNoText'"
    [class.spinner--big]="size === 'big'"
    [class.spinner--normal]="size === 'normal'"
    [class.spinner--small]="size === 'small'"
    [class.spinner--verySmall]="size === 'verySmall'"
    viewBox="0 0 50 50"
  >
    <circle class="background" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
    <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
  </svg>
  <p *ngIf="size === 'big'" [class.white]="textColor === 'white'">{{loadingMessage}}</p>
  <p *ngIf="size === 'normal' && loadingMessage" [class.white]="textColor === 'white'">{{loadingMessage}}</p>
</div>
