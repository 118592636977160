import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { NotificationsService } from "@app/core/services/notifications.service";

@Component({
    selector: "app-account-subscription",
    templateUrl: "./account-subscription.component.html",
    styleUrls: ["./account-subscription.component.scss"],
})
export class AccountSubscriptionComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private notificationService: NotificationsService, private dialogRef: MatDialogRef<AccountSubscriptionComponent>, private router: Router) {}

    later() {
        this.notificationService.updateNotificationStatus(this.data.id, "READ");
        this.dialogRef.close();
    }
    subscribe() {
        this.dialogRef.close();
        this.notificationService.updateNotificationStatus(this.data.id, "READ");
        this.router.navigate(["/payments"]);
    }
}
