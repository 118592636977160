import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject } from "rxjs";
import { UtilsService, ImageDataForValidation, validateEmailFormat, validateStandardUrl } from "@app/core/services/utils.service";
import { IndicativeService } from "@app/core/services/indicative.service";

@Injectable({
    providedIn: "root",
})
export class AppInformationService {
    displayAppInformation = new BehaviorSubject<boolean>(true);

    categoryLabel = new BehaviorSubject<string>(null);
    category = new BehaviorSubject<string>(null);
    subCategory = new BehaviorSubject<string>(null);
    invalidCategory = new BehaviorSubject<boolean>(false);

    ageRatingLabel = new BehaviorSubject<string>(null);
    ageRating = new BehaviorSubject<any>(null);
    invalidAgeRating = new BehaviorSubject<boolean>(false);

    website = new BehaviorSubject<string>(null);
    invalidWebsite = new BehaviorSubject<boolean>(false);

    supportEmailAddress = new BehaviorSubject<string>(null);
    invalidSupportEmailAddress = new BehaviorSubject<boolean>(false);

    privacyPolicyUrl = new BehaviorSubject<string>(null);
    invalidPrivacyPolicyUrl = new BehaviorSubject<boolean>(false);

    youtubeUrl = new BehaviorSubject<string>(null);
    invalidYoutubeUrl = new BehaviorSubject<boolean>(false);

    icon = new BehaviorSubject<any>(null);
    iconValidationErrorList: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    iconValidationLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    invalidIcon = new BehaviorSubject<boolean>(false);

    featureGraphic = new BehaviorSubject<any>(null);
    featureGraphicValidationErrorList: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    featureGraphicValidationLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    invalidFeatureGraphic = new BehaviorSubject<boolean>(false);

    isValid = new BehaviorSubject<boolean>(true);

    acceptedImageTypes = "image/png, image/jpg, image/jpeg";

    constructor(private translate: TranslateService, private utilsService: UtilsService, private indicativeService: IndicativeService) {}

    showAppInformation(state: boolean) {
        this.displayAppInformation.next(state);
        this.isValid.next(!!this.validateForm());
    }

    reset() {
        this.category.next(null);
        this.categoryLabel.next(null);
        this.subCategory.next(null);
        this.invalidCategory.next(null);

        this.ageRating.next(null);
        this.ageRatingLabel.next(null);
        this.invalidAgeRating.next(null);

        this.supportEmailAddress.next(null);
        this.invalidSupportEmailAddress.next(null);

        this.website.next(null);
        this.invalidWebsite.next(null);

        this.privacyPolicyUrl.next(null);
        this.invalidPrivacyPolicyUrl.next(null);

        this.youtubeUrl.next(null);
        this.invalidYoutubeUrl.next(null);

        this.icon.next(null);
        this.invalidIcon.next(null);
        this.iconValidationErrorList.next([]);
        this.iconValidationLoading.next(false);

        this.featureGraphic.next(null);
        this.invalidFeatureGraphic.next(null);
        this.featureGraphicValidationErrorList.next([]);
        this.featureGraphicValidationLoading.next(false);

        this.displayAppInformation.next(true);
        this.isValid.next(true);
    }

    updateCategory(evt) {
        this.indicativeService.sendEvent("app_submission_form_interacting");
        this.indicativeService.sendEvent("app_submission_form_app_information_interacting");

        this.categoryLabel.next(evt);
        this.category.next(evt?.category);
        this.subCategory.next(evt?.subCategory);
        this.validateCategory();
        this.isValid.next(!!this.validateForm());
    }

    updateAgeRating(evt) {
        this.indicativeService.sendEvent("app_submission_form_interacting");
        this.indicativeService.sendEvent("app_submission_form_app_information_interacting");

        this.ageRatingLabel.next(evt);
        this.ageRating.next(evt?.id);
        this.validateAgeRating();
        this.isValid.next(!!this.validateForm());
    }

    updateSupportEmailAddress(event) {
        this.indicativeService.sendEvent("app_submission_form_interacting");
        this.indicativeService.sendEvent("app_submission_form_app_information_interacting");

        if (!this.supportEmailAddress.value) {
            this.invalidSupportEmailAddress.next(false);
        }

        this.supportEmailAddress.next(event);
        if (this.invalidSupportEmailAddress.value) {
            this.validateSupportEmailAddress();
        }
        this.isValid.next(!!this.validateForm());
    }

    updateWebsite(event) {
        this.indicativeService.sendEvent("app_submission_form_interacting");
        this.indicativeService.sendEvent("app_submission_form_app_information_interacting");

        if (!this.website.value) {
            this.invalidWebsite.next(false);
        }
        this.website.next(event);
        if (this.invalidWebsite.value === true) {
            this.validateWebsite();
        }
        this.isValid.next(!!this.validateForm());
    }

    updatePrivacyPolicyUrl(event) {
        this.indicativeService.sendEvent("app_submission_form_interacting");
        this.indicativeService.sendEvent("app_submission_form_app_information_interacting");

        if (!this.privacyPolicyUrl.value) {
            this.invalidPrivacyPolicyUrl.next(false);
        }
        this.privacyPolicyUrl.next(event);
        if (this.invalidPrivacyPolicyUrl.value === true) {
            this.validatePrivacyPolicyUrl();
        }
        this.isValid.next(!!this.validateForm());
    }

    updateYoutubeUrl(event) {
        this.indicativeService.sendEvent("app_submission_form_interacting");
        this.indicativeService.sendEvent("app_submission_form_app_information_interacting");

        if (!this.website.value) {
            this.invalidYoutubeUrl.next(false);
        }
        this.youtubeUrl.next(event);
        if (this.invalidYoutubeUrl.value === true) {
            this.validateYoutubeUrlIfExists();
        }
        this.isValid.next(!!this.validateForm());
    }

    updateIcon(files: any[]) {
        this.indicativeService.sendEvent("app_submission_form_interacting");
        this.indicativeService.sendEvent("app_submission_form_app_information_interacting");

        if (files.length === 1) {
            this.iconValidationErrorList.next([]);

            const img = files[0];
            const imageData = {
                type: "icon",
                fileMimeType: img.plainFile.type,
                height: img.height,
                width: img.width,
                size: img.plainFile.size,
            };

            this.iconValidationLoading.next(true);
            this.validateImageData(imageData).subscribe({
                next: (response: any) => {
                    if (!response) {
                        this.iconValidationErrorList.next([this.translate.instant("imageRules.default")]);
                    }
                    if (response && !response.isValid) {
                        response.rules.forEach((rule) => {
                            if (!rule.isValid) {
                                this.icon.next(null);
                                let currentErrorList: string[] = this.iconValidationErrorList.value;
                                currentErrorList.push(this.utilsService.returnParsedImageValidationError(rule, imageData));
                                this.iconValidationErrorList.next(currentErrorList);
                            }
                        });
                    }
                    if (response && response.isValid) {
                        this.icon.next(files[0]);
                    }
                    this.iconValidationLoading.next(false);
                    this.isValid.next(!!this.validateForm());
                },
                error: () => {
                    this.icon.next(null);
                    this.iconValidationErrorList.next([this.translate.instant("imageRules.default")]);
                    this.iconValidationLoading.next(false);
                    this.isValid.next(!!this.validateForm());
                },
            });
        }
    }

    updateFeatureGraphic(files: any[]) {
        this.indicativeService.sendEvent("app_submission_form_interacting");
        this.indicativeService.sendEvent("app_submission_form_app_information_interacting");

        if (files.length === 1) {
            this.featureGraphicValidationErrorList.next([]);

            const img = files[0];
            const imageData = {
                type: "featureGraphic",
                fileMimeType: img.plainFile.type,
                height: img.height,
                width: img.width,
                size: img.plainFile.size,
            };

            this.featureGraphicValidationLoading.next(true);
            this.validateImageData(imageData).subscribe({
                next: (response: any) => {
                    if (!response) {
                        this.featureGraphicValidationErrorList.next([this.translate.instant("imageRules.default")]);
                    }
                    if (response && !response.isValid) {
                        response.rules.forEach((rule) => {
                            if (!rule.isValid) {
                                this.featureGraphic.next(null);
                                let currentErrorList: string[] = this.featureGraphicValidationErrorList.value;
                                currentErrorList.push(this.utilsService.returnParsedImageValidationError(rule, imageData));
                                this.featureGraphicValidationErrorList.next(currentErrorList);
                            }
                        });
                    }
                    if (response && response.isValid) {
                        this.featureGraphic.next(files[0]);
                    }
                    this.featureGraphicValidationLoading.next(false);
                    this.isValid.next(!!this.validateForm());
                },
                error: () => {
                    this.featureGraphic.next(null);
                    this.featureGraphicValidationErrorList.next([this.translate.instant("imageRules.default")]);
                    this.featureGraphicValidationLoading.next(false);
                    this.isValid.next(!!this.validateForm());
                },
            });
        }
    }

    validateCategory() {
        this.invalidCategory.next(!this.category.value || !this.subCategory.value);
    }

    validateAgeRating() {
        this.invalidAgeRating.next(!this.ageRating.value);
    }

    validateSupportEmailAddress() {
        this.invalidSupportEmailAddress.next(!validateEmailFormat(this.supportEmailAddress.value));
    }

    validateWebsite() {
        this.invalidWebsite.next(!validateStandardUrl(this.website.value));
    }

    validatePrivacyPolicyUrl() {
        this.invalidPrivacyPolicyUrl.next(!validateStandardUrl(this.privacyPolicyUrl.value));
    }

    validateYoutubeUrl() {
        this.invalidYoutubeUrl.next(!validateStandardUrl(this.youtubeUrl.value));
    }

    validateYoutubeUrlIfExists() {
        if (!this.youtubeUrl.value) {
            this.invalidYoutubeUrl.next(false);
            return;
        }
        this.validateYoutubeUrl();
    }

    validateIcon() {
        this.iconValidationErrorList.next([]);
        if (!this.icon.value) {
            this.invalidIcon.next(true);
            this.iconValidationErrorList.next([this.translate.instant("imageRules.missingImage")]);
            return;
        }
    }

    validateFeatureGraphic() {
        this.featureGraphicValidationErrorList.next([]);
        if (!this.featureGraphic.value) {
            this.invalidFeatureGraphic.next(true);
            this.featureGraphicValidationErrorList.next([this.translate.instant("imageRules.missingImage")]);
            return;
        }
    }

    validateImageData(imageData: ImageDataForValidation) {
        return this.utilsService.getValidationForImageData(imageData);
    }

    validateFields() {
        this.validateCategory();
        this.validateAgeRating();
        this.validateSupportEmailAddress();
        this.validateWebsite();
        this.validatePrivacyPolicyUrl();
        this.validateYoutubeUrlIfExists();
        this.validateIcon();
        this.validateFeatureGraphic();
    }

    validateForm() {
        if (!this.displayAppInformation.value) {
            return true;
        }

        let isValid =
            !this.invalidSupportEmailAddress.value &&
            this.supportEmailAddress.value &&
            !this.invalidWebsite.value &&
            this.website.value &&
            !this.invalidPrivacyPolicyUrl.value &&
            this.privacyPolicyUrl.value &&
            !this.invalidCategory.value &&
            this.category.value &&
            this.subCategory.value &&
            !this.invalidAgeRating.value &&
            this.ageRating.value &&
            !this.invalidYoutubeUrl.value &&
            !this.iconValidationErrorList.value.length &&
            this.icon.value &&
            !this.featureGraphicValidationErrorList.value.length &&
            this.featureGraphic.value;

        return !!isValid;
    }

    getFormData(): FormData {
        const formData = new FormData();
        if (!!this.displayAppInformation.value) {
            if (this.category.value) {
                formData.append("category", this.category.value);
            }
            if (this.subCategory.value) {
                formData.append("subCategory", this.subCategory.value);
            }
            if (this.ageRating.value) {
                formData.append("ageRating", this.ageRating.value);
            }
            if (this.supportEmailAddress.value) {
                formData.append("supportEmailAddress", this.supportEmailAddress.value);
            }
            if (this.website.value) {
                formData.append(
                    "websiteUrl",
                    this.website.value?.toString().includes("http://") || this.website.value?.toString().includes("https://") ? this.website.value : "http://" + this.website.value
                );
            }
            if (this.privacyPolicyUrl.value) {
                formData.append(
                    "privacyPolicyUrl",
                    this.privacyPolicyUrl.value?.toString().includes("http://") || this.privacyPolicyUrl.value?.toString().includes("https://")
                        ? this.privacyPolicyUrl.value
                        : "http://" + this.privacyPolicyUrl.value
                );
            }
            if (this.icon.value && this.icon.value.plainFile) {
                formData.append("icon", this.icon.value?.plainFile);
            }
            if (this.icon.value && this.icon.value.downloadUrl) {
                formData.append("icon", this.icon.value?.downloadUrl);
            }
            if (this.featureGraphic.value && this.featureGraphic.value.plainFile) {
                formData.append("featureGraphic", this.featureGraphic.value?.plainFile);
            }
            if (this.featureGraphic.value && this.featureGraphic.value.downloadUrl) {
                formData.append("featureGraphic", this.featureGraphic.value?.downloadUrl);
            }
            if (this.youtubeUrl?.value) {
                formData.append("youtubeUrl", this.youtubeUrl.value);
            }
        }

        return formData;
    }
}
