import { Injectable } from "@angular/core";
import {
  TransactionType,
  TRANSACTION_TYPES
} from "@app/core/services/constants";
import { TimeFilteredService } from "@app/core/services/time-filtered-service";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class WalletFiltersService extends TimeFilteredService {
  transactionTypes: BehaviorSubject<TransactionType[]> = new BehaviorSubject<
    TransactionType[]
  >(TRANSACTION_TYPES.slice());
  transactionTypeSelected: BehaviorSubject<TransactionType> = new BehaviorSubject<TransactionType>(
    TRANSACTION_TYPES.slice()[0]
  );

  constructor() {
    super("today");
  }
}
