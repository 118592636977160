import { Injectable } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { IndicativeService } from "@app/core/services/indicative.service";
import { UserService } from "@app/core/services/user.service";
import { validateStandardUrl } from "@app/core/services/utils.service";
import { ApiService } from "@app/modules/subscription-payments/api.service";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class OwnershipRequestService {
    displayOwnershipRequest: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    appTitle: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    invalidAppTitle: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    message: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    website: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    invalidWebsite: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    linkedIn: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    invalidLinkedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    otherAppStoresForm: FormGroup;

    isValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    submitting = new BehaviorSubject<boolean>(false);
    submitted = new BehaviorSubject<boolean>(false);
    packageName: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(private apiService: ApiService, private indicativeService: IndicativeService, private userService: UserService, private fb: FormBuilder) {
        this.userService.loggedAccount.subscribe({
            next: () => {
                this.reset();
            },
        });

        this.packageName.subscribe({
            next: () => {
                this.reset();
            },
        });
    }

    showOwnershipRequest(state: boolean) {
        this.displayOwnershipRequest.next(state);
        this.isValid.next(this.isFormValid());
    }

    reset() {
        this.appTitle.next(null);
        this.invalidAppTitle.next(false);
        this.message.next(null);
        this.website.next(null);
        this.invalidWebsite.next(false);
        this.linkedIn.next(null);
        this.invalidLinkedIn.next(false);
        this.createForm();
        this.isValid.next(false);
        this.submitting.next(false);
        this.submitted.next(false);
    }

    createForm() {
        this.otherAppStoresForm = this.fb.group({
            appStores: this.fb.array([this.fb.control(null)]),
        });
    }

    updateAppTitle(event) {
        this.indicativeService.sendEvent("app_submission_form_interacting");
        this.indicativeService.sendEvent("app_submission_form_ownership_request_interacting");

        if (!this.appTitle.value) {
            this.invalidAppTitle.next(true);
        }

        this.appTitle.next(event);
        if (this.invalidAppTitle.value === true) {
            this.validateAppTitle();
        }

        this.isValid.next(this.isFormValid());
    }

    updateMessage(event) {
        this.indicativeService.sendEvent("app_submission_form_interacting");
        this.indicativeService.sendEvent("app_submission_form_ownership_request_interacting");

        this.message.next(event);
        this.isValid.next(this.isFormValid());
    }

    updateWebsite(event) {
        this.indicativeService.sendEvent("app_submission_form_interacting");
        this.indicativeService.sendEvent("app_submission_form_ownership_request_interacting");

        if (!this.website.value) {
            this.invalidWebsite.next(false);
        }

        this.website.next(event);
        if (this.invalidWebsite.value === true) {
            this.validateWebsite();
        }

        this.isValid.next(this.isFormValid());
    }

    updateLinkedIn(event) {
        this.indicativeService.sendEvent("app_submission_form_interacting");
        this.indicativeService.sendEvent("app_submission_form_ownership_request_interacting");

        if (!this.linkedIn.value) {
            this.invalidLinkedIn.next(false);
        }

        this.linkedIn.next(event);
        if (this.invalidLinkedIn.value === true) {
            this.validateLinkedIn();
        }

        this.isValid.next(this.isFormValid());
    }

    updateAppStore(appStore: string, index: number) {
        this.indicativeService.sendEvent("app_submission_form_interacting");
        this.indicativeService.sendEvent("app_submission_form_ownership_request_interacting");
        const control = (<FormArray>this.otherAppStoresForm?.get("appStores"))?.controls[index];

        if (!control.value) {
            control.setErrors(null);
        }
        if (!control.valid) {
            control.setValue(appStore);
            this.validateAppStore(index);
        } else {
            control.setValue(appStore);
        }

        this.isValid.next(this.isFormValid());
    }

    isAppStoreInvalid(index: number): boolean {
        return !(<FormArray>this.otherAppStoresForm?.get("appStores"))?.controls[index].valid;
    }

    addAppStore() {
        (this.otherAppStoresForm?.get("appStores") as FormArray).push(this.fb.control(null));
        this.isValid.next(this.isFormValid());
    }

    removeAppStore(index: number) {
        (this.otherAppStoresForm?.get("appStores") as FormArray).removeAt(index);
        this.isValid.next(this.isFormValid());
    }

    getAppStoresFormControls(): AbstractControl[] {
        return (<FormArray>this.otherAppStoresForm?.get("appStores"))?.controls;
    }

    validateAppTitle() {
        this.invalidAppTitle.next(!this.appTitle.value);
        this.isValid.next(this.isFormValid());
    }

    validateMessage() {
        this.isValid.next(this.isFormValid());
    }

    validateWebsite() {
        this.invalidWebsite.next(this.website.value && !validateStandardUrl(this.website.value, true));
        this.isValid.next(this.isFormValid());
    }

    validateLinkedIn() {
        this.invalidLinkedIn.next(this.linkedIn.value && !validateStandardUrl(this.linkedIn.value, true));
        this.isValid.next(this.isFormValid());
    }

    validateAppStore(index: number) {
        const control = (<FormArray>this.otherAppStoresForm?.get("appStores"))?.controls[index];
        if (control.value && !validateStandardUrl(control.value, true)) {
            control.setErrors({ appStoresValidator: false });
        }
        this.isValid.next(this.isFormValid());
    }

    isFormValid() {
        if (!this.appTitle.value) {
            return false;
        }

        if (!this.displayOwnershipRequest.value) {
            return true;
        }

        if (!this.message.value && !this.website.value && !this.linkedIn.value && !(<FormArray>this.otherAppStoresForm?.get("appStores"))?.controls.some((control) => control.value)) {
            return false;
        }

        if (this.website.value && this.invalidWebsite.value) {
            return false;
        }

        if (this.linkedIn.value && this.invalidLinkedIn.value) {
            return false;
        }

        if ((<FormArray>this.otherAppStoresForm?.get("appStores"))?.controls.some((control) => control.value && !control.valid)) {
            return false;
        }

        return true;
    }

    getFormData(): FormData {
        const formData = new FormData();

        formData.append("applicationName", this.packageName.value);

        if (this.appTitle.value) {
            formData.append("appTitle", this.appTitle.value);
        }

        if (this.message.value) {
            formData.append("message", this.message.value);
        }

        if (this.website.value) {
            formData.append("website", this.website.value);
        }

        if (this.linkedIn.value) {
            formData.append("linkedIn", this.linkedIn.value);
        }

        const appStores = this.getAppStoresFormControls()
            .map((control) => control.value)
            .filter((value) => value);
        if (appStores.length > 0) {
            formData.append("otherAppStores", appStores.join(","));
        }

        return formData;
    }

    submit() {
        this.validateWebsite();
        this.validateLinkedIn();
        this.getAppStoresFormControls().forEach((control, index) => this.validateAppStore(index));
        if (!this.isValid.value) {
            return;
        }

        this.submitting.next(true);

        const formData = this.getFormData();

        this.apiService.submitOwnershipRequest(formData).subscribe({
            next: (response: any) => {
                this.submitting.next(false);
                this.submitted.next(true);
            },
            error: (error: any) => {
                this.submitting.next(false);
            },
        });
    }
}
