import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NavigationService } from "@app/core/services/navigation.service";
import { PermissionsService } from "@app/core/services/permissions.service";
import { BehaviorSubject } from "rxjs";

@Component({
    selector: "app-mobile-navigation-bar",
    templateUrl: "./mobile-navigation-bar.component.html",
    styleUrls: ["./mobile-navigation-bar.component.scss"],
})
export class MobileNavigationBarComponent {
    activatedRoute: BehaviorSubject<string> = new BehaviorSubject<string>("");

    showStatistics: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    showMyApps: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    showMyWallet: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(public router: Router, private navigationService: NavigationService, private permissionService: PermissionsService) {
        this.showStatistics.next(this.permissionService.checkMenuItemDashboardPermissions());
        this.showMyApps.next(this.permissionService.checkMenuItemMyAppsPermissions());
        this.showMyWallet.next(this.permissionService.checkMenuItemWalletPermissions());
    }

    navigateTo(route) {
        this.navigationService.closeAllNavigationDropdowns();
        this.router.navigate([route]);
    }
}
