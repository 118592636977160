import { Injectable } from "@angular/core";
import { APIRequestsService } from "@app/core/services/api.requests.service";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class WalletPreviewService {
    loadingBalance: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    errorBalance: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    balance: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private requestsService: APIRequestsService) {}

    getBalance() {
        this.loadingBalance.next(true);
        return this.requestsService.get("/api/wallet", [], true).subscribe({
            next: (res: any) => {
                this.balance.next(res?.balance?.usd);
                this.loadingBalance.next(false);
                this.errorBalance.next(false);
            },
            error: (_error: any) => {
                this.loadingBalance.next(false);
                this.errorBalance.next(true);
            },
        });
    }
}
