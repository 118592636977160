import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { IndicativeService } from "@app/core/services/indicative.service";
import { APIRequestsService } from "@app/core/services/api.requests.service";
import { isValidGooglePlayUrl, isValidPackageName } from "@app/core/services/utils.service";
import { AppsListService } from "@app/modules/my-apps/apps-list/apps-list.service";
import { SubmissionService } from "@app/modules/my-apps/submission/submission.service";
import { AppBeingProcessedDialogComponent } from "@app/shared/app-being-processed-dialog/app-being-processed-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AddAppService {
  dialogAddAppRef;

  loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  googlePlayUrlInvalidInputError: BehaviorSubject<string> = new BehaviorSubject<string>("");
  packageNameInvalidInputError: BehaviorSubject<string> = new BehaviorSubject<string>("");
  
  appPackageName: BehaviorSubject<string> = new BehaviorSubject<string>("");
  marketDataAvailable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  googlePlayAppIcon: BehaviorSubject<string> = new BehaviorSubject<string>("");
  googlePlayVername: BehaviorSubject<string> = new BehaviorSubject<string>("");
  googlePlayAppName: BehaviorSubject<string> = new BehaviorSubject<string>("");
  googlePlayAppSupportEmail: BehaviorSubject<string> = new BehaviorSubject<string>("");
  appStatusError: BehaviorSubject<string> = new BehaviorSubject<string>("");
  appStatusErrorCode: BehaviorSubject<string> = new BehaviorSubject<string>("");
  addingPackageSubscription;
  formCardsSubscription;

  constructor(
    private requestsService: APIRequestsService,
    private router: Router,
    private translate: TranslateService,
    private submissionService: SubmissionService,
    private dialog: MatDialog,
    private appsListService: AppsListService,
    private indicativeService: IndicativeService
  ) {}

  resetErrors() {
    this.googlePlayUrlInvalidInputError.next("");
    this.packageNameInvalidInputError.next("");
    this.appStatusError.next("");
    this.appStatusErrorCode.next("");
  }

  goToSubmission() {
    this.indicativeService.sendEvent(`add_app_done`);
    this.indicativeService.sendEvent(`add_app_submission_form`);

    this.router.navigate([`/myApps/submission`], {
      queryParams: { packageName: this.appPackageName.value },
    });
  }

  updateAppPackageNameWithGooglePlayUrl(event) {
    if (this.isInputNegligibleAndResetErrorsIfEmpty(event)) {
      this.appPackageName.next("");
      return;
    }

    const packageName: string = this.getPackageNameFromGooglePlayUrlUserInput(event);
    this.resetErrors();
    
    if (!packageName) {
      this.googlePlayUrlInvalidInputError.next(this.translate.instant("submission.appUrlError"));
      this.appPackageName.next("");
      return;
    }

    this.appPackageName.next(packageName);
  }

  updateAppPackageName(event) {
    if (this.isInputNegligibleAndResetErrorsIfEmpty(event)) {
      this.appPackageName.next("");
      return;
    }

    this.resetErrors();

    if (!isValidPackageName(event)) {
      this.packageNameInvalidInputError.next(this.translate.instant("submission.packageNameError"));
      this.appPackageName.next("");
      return;
    }

    this.appPackageName.next(event);
  }

  isInputNegligibleAndResetErrorsIfEmpty(event) {
    if (!event) {
      this.resetErrors();
      return true;
    }

    if (event.length <= 5) {
      return true;
    }
    return false;
  }

  getPackageNameFromGooglePlayUrlUserInput(input: string) {
    if (!input) {
      return null;
    }
  
    if (
      !isValidGooglePlayUrl(input) &&
      !isValidPackageName(input)
    ) {
      return null;
    }
  
    if (isValidGooglePlayUrl(input)) {
      const urlFromInput = new URL(input);
      if (!urlFromInput.searchParams.has("id")) {
        return null;
      }
  
      const packageName: string = urlFromInput.searchParams.get("id");
      if (!packageName) {
        return null;
      }
  
      return packageName;
    }
  
    if (isValidPackageName(input)) {
      return input;
    }
  
    return null;
  }

  startAppInfoFromPackageNameVerification() {
    this.loading.next(true);
    this.getAppSubmissionStatus();
  }

  getAppSubmissionStatus() {
    this.indicativeService.sendEvent(`add_app_checking_submission_status`);

    this.requestsService.get(`/api/applications/${this.appPackageName.value}/submissionStatus?nocache=1`).subscribe({
      next: () => {
        this.indicativeService.sendEvent(`add_app_can_submit`);
        this.getAppMarketInformation();
      },
      error: (err) => {
        this.indicativeService.sendEvent(`add_app_cannot_submit`);

        this.loading.next(false);

        if (err && err.error) {
          this.appStatusErrorCode.next(err.error.code);
        }

        if (err && err.error && err.error.code === "APPLICATION-OWNED-BY-OTHER-ACCOUNT") {
          this.appStatusError.next(this.translate.instant("addAppDialog.applicationOwnedByOtherAccount"));
          return;
        }

        if (err && err.error && err.error.code === "APPLICATION-ALREADY-SUBMITTED") {
          this.appStatusError.next(this.translate.instant("addAppDialog.applicationAlreadySubmitted"));
          return;
        }

        if (err && err.error && err.error.code === "APPLICATION-NOT-ALLOWED") {
          this.appStatusError.next(this.translate.instant("addAppDialog.applicationNotAllowed"));
          return;
        }

        this.appStatusError.next(this.translate.instant("addAppDialog.defaultError"));
      }
    });
  }

  getAppMarketInformation() {
    this.indicativeService.sendEvent(`add_app_checking_google_play`);

    this.requestsService.get(`/api/applications/${this.appPackageName.value}/marketInformation?nocache=1`).subscribe({
      next: (resp: any) => {
        this.indicativeService.sendEvent(`add_app_on_google_play`);

        if (resp) {
          this.marketDataAvailable.next(true);
          this.googlePlayAppIcon.next(resp.iconUrl);
          this.googlePlayAppName.next(resp.title);
          this.googlePlayVername.next(resp.vername);
          this.googlePlayAppSupportEmail.next(resp.supportEmailAddress);
        }

        this.loading.next(false);
      },
      error: (err) => {
        this.indicativeService.sendEvent(`add_app_not_on_google_play`);

        this.marketDataAvailable.next(false);
        this.loading.next(false);
        this.dialogAddAppRef.close();
        this.goToSubmission();
      }
    });
  }

  yesItIs() {
    this.indicativeService.sendEvent(`add_app_yes_it_is`);
    this.indicativeService.sendEvent(`add_app_getting_submission_form_cards`);

    this.loading.next(true);
    this.submissionService.addPackage(this.appPackageName.value);
    let formCardsConsumed = false;

    this.addingPackageSubscription = this.submissionService.addingPackage.subscribe({
      next: (addingPackage: any) => {
        if (!addingPackage) {
          this.formCardsSubscription = this.submissionService.formCards.subscribe({
            next: (formCards) => {
              if (!formCards || formCardsConsumed) {
                return;
              }
              if (formCards.length > 0) {
                formCardsConsumed = true;
                this.loading.next(false);
                this.dialogAddAppRef.close();
                this.goToSubmission();
                this.unsubscribeFromSubmissionSubscriptions();
                return;
              }
              if (formCards.length === 0) {
                this.indicativeService.sendEvent(`add_app_done`);
                this.indicativeService.sendEvent(`add_app_on_our_side`);
    
                formCardsConsumed = true;
                this.loading.next(false);
                this.dialogAddAppRef.close();
                const dialogRef = this.dialog.open(AppBeingProcessedDialogComponent, {
                  data: {
                    googlePlaySupportEmailAddress: this.googlePlayAppSupportEmail.value
                  },
                  width: "90dvw",
                  maxWidth: "550px"
                });
                dialogRef.afterClosed().subscribe({
                  next: () => {
                    this.appsListService.getAllList(true);
                    this.router.navigate(["/myApps"]);
                  }
                });
                this.unsubscribeFromSubmissionSubscriptions();
                return;
              }
            }
          });
        }
      }
    });
  }

  unsubscribeFromSubmissionSubscriptions() {
    if (this.addingPackageSubscription) {
      this.addingPackageSubscription.unsubscribe();
    }
    if (this.formCardsSubscription) {
      this.formCardsSubscription.unsubscribe();
    }
  }

  noItsNot() {
    this.indicativeService.sendEvent(`add_app_no_its_not`);
    this.dialogAddAppRef.close();
  }

  resetService() {
    this.loading.next(false);
    this.googlePlayUrlInvalidInputError.next("");
    this.packageNameInvalidInputError.next("");
    this.appPackageName.next("");
    this.marketDataAvailable.next(false);
    this.googlePlayAppIcon.next("");
    this.googlePlayVername.next("");
    this.googlePlayAppName.next("");
    this.googlePlayAppSupportEmail.next("");
    this.appStatusError.next("");
    this.appStatusErrorCode.next("");
  }

}
