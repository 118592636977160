import { Injectable } from "@angular/core";
import { RequestParameters, APIRequestsService } from "@app/core/services/api.requests.service";
import { DataSetEntry } from "@app/modules/statistics/services/utils";
import * as moment from "moment";
import { BehaviorSubject, forkJoin } from "rxjs";
import { switchMap } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class DownloadsStatisticsPreviewCardService {
    fetch = new BehaviorSubject<boolean>(true);
    loadingDownloadsData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    errorDownloadsData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    hasAppsWithBilling: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    downloadLabels: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    downloadDatasets: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    dashboardDownloadsTotalsData: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private requestsService: APIRequestsService) {}

    init() {
        this.fetch
            .pipe(
                switchMap((x) => {
                    return this.getDownloadsData();
                })
            )
            .subscribe({
                next: (data: any) => {
                    const growth = (data[0].totals.total / data[1].totals.total - 1) * 100;
                    this.dashboardDownloadsTotalsData.next({
                        total: data[0].totals.total,
                        growth: growth.toFixed(2),
                    });

                    const labels = data[0]["labels"];
                    const datasets = data[0].datasets.map((_element: any, _index: number) => {
                        const label = _element.label;
                        const values = [];
                        _element.data.forEach((dataItem) => {
                            values.push(dataItem.total);
                        });
                        return new DataSetEntry("Downloads", values);
                    });
                    this.downloadLabels.next(labels);
                    this.downloadDatasets.next(datasets);
                    this.loadingDownloadsData.next(false);
                    this.errorDownloadsData.next(false);
                },
                error: (_error: any) => {
                    this.downloadLabels.next([]);
                    this.downloadDatasets.next([]);
                    this.loadingDownloadsData.next(false);
                    this.errorDownloadsData.next(true);
                },
            });
    }

    getDownloadsData() {
        this.loadingDownloadsData.next(true);
        const now = new Date();
        let parameters: RequestParameters[] = [
            {
                label: "from",
                data: moment(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 8)).format("yyyy-MM-DD"),
            },
            {
                label: "to",
                data: moment(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1)).format("yyyy-MM-DD"),
            },
        ];
        let previousParameters: RequestParameters[] = [
            {
                label: "from",
                data: moment(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 15)).format("yyyy-MM-DD"),
            },
            {
                label: "to",
                data: moment(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 8)).format("yyyy-MM-DD"),
            },
        ];

        return forkJoin([
            this.requestsService.get("/api/statistics/chart", [
                ...parameters,

                {
                    label: "type",
                    data: "downloads",
                },
            ]),
            this.requestsService.get("/api/statistics/chart", [
                ...previousParameters,
                {
                    label: "type",
                    data: "downloads",
                },
            ]),
        ]);
    }
}
