import { Injectable } from "@angular/core";
import { RequestParameters, APIRequestsService } from "@app/core/services/api.requests.service";
import { UserService } from "@app/core/services/user.service";
import { AutocompleteEntry } from "@app/shared/autocomplete/autocomplete.component";
import { SelectEntry } from "@app/shared/select/select.component";
import { TimeFilteredService } from "@app/core/services/time-filtered-service";
import * as moment from "moment";
import { BehaviorSubject } from "rxjs";
import { ALLOWED_PARTNER_ACCOUNT_ICONS } from "@app/core/services/constants";

@Injectable({
    providedIn: "root",
})
export class StatisticsFiltersService extends TimeFilteredService {
    dataFilters: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    app: BehaviorSubject<AutocompleteEntry> = new BehaviorSubject<AutocompleteEntry>(null);
    country: BehaviorSubject<AutocompleteEntry> = new BehaviorSubject<AutocompleteEntry>(null);
    store: BehaviorSubject<AutocompleteEntry> = new BehaviorSubject<AutocompleteEntry>(null);
    yesterday: Date = new Date(moment().subtract(1, "days").toDate());

    revenueValuesToDisplay: SelectEntry[] = [
        { value: "volume", label: "statistics.volume", secondLabel: "" },
        { value: "grossVolume", label: "statistics.grossVolume", secondLabel: "" },
        { value: "users", label: "statistics.uniqueUsers", secondLabel: "" },
        { value: "transactions", label: "statistics.transactions", secondLabel: "" },
        { value: "arppu", label: "statistics.arppu", secondLabel: "" },
        { value: "arpt", label: "statistics.arpt", secondLabel: "" },
        { value: "newUsers", label: "statistics.newUsers", secondLabel: "" },
    ];

    revenueValueToDisplay: BehaviorSubject<SelectEntry> = new BehaviorSubject<SelectEntry>(this.revenueValuesToDisplay[0]);

    loadingStores: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    stores: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    storesAutocompleteData: BehaviorSubject<AutocompleteEntry[]> = new BehaviorSubject<AutocompleteEntry[]>([]);

    loadingApps: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    apps: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

    revenueAggregationOptions = [
        {
            label: "dashboard.day",
            value: "day",
        },
        {
            label: "dashboard.month",
            value: "month",
        },
    ];

    revenueAggregationSelectedOption: BehaviorSubject<any> = new BehaviorSubject<any>(this.revenueAggregationOptions[0]);

    constructor(public requestsService: APIRequestsService, private userService: UserService) {
        super("yesterday");
        this.userService.loggedUser.subscribe({
            next: (x: any) => {
                if (x) {
                    this.userSignupDate.next(moment(x.signupTimestamp).toDate());
                }
            },
        });
        this.revenueValueToDisplay.next(this.revenueValuesToDisplay[0]);
        this.loadApps();
        this.loadStores();

        this.dateFilters.subscribe({
            next: (dateRange: any) => {
                let diff = Math.abs(dateRange["to"].getTime() - dateRange["from"].getTime());
                var diffInDays = Math.ceil(diff / (1000 * 3600 * 24));

                if (dateRange["to"].getMonth() == dateRange["from"].getMonth() && dateRange["to"].getFullYear() == dateRange["from"].getFullYear()) {
                    this.revenueAggregationSelectedOption.next(this.revenueAggregationOptions[0]);
                }

                if (diffInDays > 92) {
                    this.revenueAggregationSelectedOption.next(this.revenueAggregationOptions[1]);
                }

                return false;
            },
        });
    }

    updateApp(_value: any) {
        if (_value == this.app.value) {
            return;
        }

        this.app.next(_value);
        this.notifyDataChange();
    }

    updateStore(_value: any) {
        if (_value == this.store.value) {
            return;
        }

        this.store.next(_value);
        this.notifyDataChange();
    }

    updateCountry(_value: any) {
        if (_value == this.country.value) {
            return;
        }

        this.country.next(_value);
        this.notifyDataChange();
    }

    updateRevenueAggregation(_value: any) {
        if (_value == this.revenueAggregationSelectedOption.value) {
            return;
        }

        this.revenueAggregationSelectedOption.next(_value);
    }

    private notifyDataChange() {
        this.revenueValueToDisplay.next(this.revenueValuesToDisplay[0]);
        this.dataFilters.next({
            country: this.country.value,
            app: this.app.value,
            store: this.store.value,
        });
    }

    getDataFiltersRequestHeaders(isCombinedRevenue: boolean) {
        let parameters: RequestParameters[] = [];
        if (this.app.value) {
            parameters.push({
                label: "packageNames",
                data: this.app.value.value,
            });
        }
        if (this.store.value) {
            parameters.push({
                label: "partnerAccountUids",
                data: this.store.value.value,
            });
        }
        if (this.country.value) {
            parameters.push({
                label: "countryCodes",
                data: this.country.value.value,
            });
        }
        if (this.revenueAggregationSelectedOption.value && isCombinedRevenue) {
            parameters.push({
                label: "aggregation",
                data: this.revenueAggregationSelectedOption.value.value,
            });
        }
        return parameters;
    }

    loadApps() {
        this.loadingApps.next(true);

        this.requestsService
            .get(`/api/applications`, [
                {
                    label: "accountApplicationStatus",
                    data: "APPROVED",
                },
                {
                    label: "withLiveVersions",
                    data: "true",
                },
            ])
            .subscribe({
                next: (response: any) => {
                    const formattedResponse = response.map((app: any, index) => {
                        return {
                            id: app?.accountApplication?.id,
                            label: app?.accountApplication?.extras?.title,
                            value: app?.application?.name,
                            iconUrl: app?.accountApplication?.extras?.iconUrl,
                        };
                    });

                    this.apps.next(formattedResponse);
                    this.loadingApps.next(false);
                },
                error: (error) => {
                    this.apps.next([]);
                    this.loadingApps.next(false);
                },
            });
    }

    loadStores() {
        this.loadingStores.next(true);

        this.requestsService.get(`/api/partners`).subscribe({
            next: (response: any) => {
                const formattedResponse = response
                    .map((partnerAccountDetails: any) => {
                        if (!partnerAccountDetails.name) {
                            return null;
                        }

                        const icon: string = (() => {
                            for (const key of Object.keys(ALLOWED_PARTNER_ACCOUNT_ICONS)) {
                                if (partnerAccountDetails.displayName.toLowerCase().includes(key.toLocaleLowerCase())) {
                                    return ALLOWED_PARTNER_ACCOUNT_ICONS[key];
                                }
                            }
                            return "/assets/images/unknown-square.svg";
                        })();

                        return {
                            uid: partnerAccountDetails.uid,
                            label: partnerAccountDetails.displayName,
                            icon: icon,
                            selected: false,
                        };
                    })
                    .filter((formattedResponseItem) => {
                        return formattedResponseItem !== null;
                    });

                formattedResponse.push({
                    uid: this.userService.loggedAccount.value.uid,
                    label: "Direct to Consumer (D2C)",
                    icon: "/assets/images/unknown-square.svg",
                    selected: false,
                });

                formattedResponse.sort((a, b) => a.label.localeCompare(b.label));

                this.stores.next(formattedResponse);
                this.storesAutocompleteData.next(
                    formattedResponse.map((item) => {
                        return {
                            id: item.uid,
                            label: item.label,
                            iconUrl: item.icon,
                            value: item.uid,
                        };
                    })
                );
                this.loadingStores.next(false);
            },
            error: (error) => {
                this.stores.next([]);
                this.loadingStores.next(false);
            },
        });
    }
}
