import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ButtonComponent } from "./button.component";
import { LottieComponent } from "ngx-lottie";

@NgModule({
    imports: [CommonModule, LottieComponent],
    declarations: [ButtonComponent],
    exports: [ButtonComponent],
})
export class ButtonModule {}
