import { Injectable } from "@angular/core";
import { APIRequestsService, RequestParameters } from "@app/core/services/api.requests.service";
import { BehaviorSubject, forkJoin } from "rxjs";
import { WalletFiltersService } from "../filters/filters.service";

@Injectable({
    providedIn: "root",
})
export class WalletTransactionsListService {
    limit: BehaviorSubject<number> = new BehaviorSubject<number>(25);
    offset: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    cursor: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    waitForAllFilters: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    loadingMoreTransactions: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    loadingTransactions: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    fetch: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    debits: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    credits: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    transactions: BehaviorSubject<number> = new BehaviorSubject<number>(null);

    country: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    application: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    transactionType: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    transactionsList: BehaviorSubject<any> = new BehaviorSubject<any>([]);

    onlySandbox: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private walletFiltersService: WalletFiltersService, private requestsService: APIRequestsService) {
        this.onlySandbox.subscribe({
            next: (res: boolean) => {
                this.fetch.next(true);
            },
        });

        this.walletFiltersService.dateFilters.subscribe({
            next: (x) => {
                this.fetch.next(true);
            },
        });
        this.country.subscribe({
            next: (x) => {
                this.fetch.next(true);
            },
        });
        this.application.subscribe({
            next: (x) => {
                this.fetch.next(true);
            },
        });
        this.transactionType.subscribe({
            next: (x) => {
                this.fetch.next(true);
            },
        });
        this.onlySandbox.subscribe({
            next: (x) => {
                this.fetch.next(true);
            },
        });
        this.waitForAllFilters.subscribe({
            next: (x) => {
                if (!x) {
                    this.fetch.next(true);
                }
            },
        });

        this.fetch.subscribe({
            next: (res) => {
                if (this.waitForAllFilters.value) {
                    return;
                }
                this.cursor.next(null);

                this.loadingTransactions.next(true);
                forkJoin([this.getTotals(), this.getData()]).subscribe({
                    next: (res: any) => {
                        this.debits.next(res[0].credit.usd);
                        this.credits.next(res[0].debit.usd);
                        this.transactions.next(res[1].count);
                        this.transactionsList.next(res[1].data);

                        this.loadingTransactions.next(false);
                        this.cursor.next(res[1].nextCursor);
                    },
                });
            },
        });
    }

    loadMore() {
        this.loadingMoreTransactions.next(true);
        this.getData().subscribe({
            next: (res: any) => {
                this.cursor.next(res.nextCursor);

                this.transactionsList.next(this.transactionsList.value.concat(res.data));
                this.loadingMoreTransactions.next(false);
            },
        });
    }

    getData() {
        const parameters: RequestParameters[] = this.walletFiltersService.getDateFiltersRequestHeaders();

        if (this.transactionType.value) {
            parameters.push({
                label: "type",
                data: this.transactionType.value.value,
            });
        }

        if (this.onlySandbox.value) {
            parameters.push({
                label: "paymentMethod",
                data: "sandbox",
            });
        }

        if (this.country.value && this.country.value.value !== null) {
            parameters.push({ label: "countryCode", data: this.country.value.value });
        }

        if (this.application.value && this.application.value.value !== null) {
            parameters.push({
                label: "packageName",
                data: this.application.value.value,
            });
        }

        parameters.push({ label: "offset", data: this.offset.value });
        parameters.push({ label: "limit", data: this.limit.value });
        parameters.push({ label: "cursor", data: this.cursor.value });
        return this.requestsService.get("/api/wallet/transactions", parameters);
    }

    getTotals() {
        const parameters: RequestParameters[] = this.walletFiltersService.getDateFiltersRequestHeaders();

        if (this.transactionType.value) {
            parameters.push({
                label: "type",
                data: this.transactionType.value.value,
            });
        }

        if (this.country.value && this.country.value.value !== null) {
            parameters.push({ label: "countryCode", data: this.country.value.value });
        }

        if (this.application.value && this.application.value.value !== null) {
            parameters.push({
                label: "packageName",
                data: this.application.value.value,
            });
        }

        return this.requestsService.get("/api/wallet/transactionsTotals", parameters);
    }

    getDataForCSV(offset: number, limit: number, cursor: String) {
        const parameters: RequestParameters[] = this.walletFiltersService.getDateFiltersRequestHeaders();

        if (this.transactionType.value && this.transactionType.value.value !== "all") {
            parameters.push({
                label: "type",
                data: this.transactionType.value.value,
            });
        }

        if (this.country.value && this.country.value.value !== null) {
            parameters.push({ label: "countryCode", data: this.country.value.value });
        }

        if (this.application.value && this.application.value.value !== null) {
            parameters.push({
                label: "packageName",
                data: this.application.value.value,
            });
        }

        parameters.push({ label: "offset", data: offset });
        parameters.push({ label: "limit", data: limit });
        parameters.push({ label: "cursor", data: cursor });

        if (this.onlySandbox.value) {
            parameters.push({
                label: "paymentMethod",
                data: "sandbox",
            });
        }

        return this.requestsService.get("/api/wallet/transactions", parameters);
    }

    resetFilters() {
        this.application.next(null);
        this.country.next(null);
        this.offset.next(0);
        this.limit.next(25);
    }
}
