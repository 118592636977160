import { Injectable } from "@angular/core";
import { AutocompleteEntry } from "@app/shared/autocomplete/autocomplete.component";
import { environment } from "@env/environment";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject } from "rxjs";
import { APIRequestsService } from "./api.requests.service";
import { UploaderRequestsService } from "./uploader.requests.service";

export interface ImageDataForValidation {
    type: string;
    fileMimeType: string;
    height: number;
    width: number;
    size: number;
}

@Injectable()
export class UtilsService {
    appcToUsdExchangeRate: BehaviorSubject<number> = new BehaviorSubject<number>(0.0);
    appcToEurExchangeRate: BehaviorSubject<number> = new BehaviorSubject<number>(0.0);

    // STATIC ASSETS
    categoriesAutoCompleteData = new BehaviorSubject<AutocompleteEntry[]>([]);
    locales = new BehaviorSubject<any[]>([]);
    maturityLevelsAutoCompleteData = new BehaviorSubject<AutocompleteEntry[]>([]);
    imageSettings = new BehaviorSubject<any>({});
    topAnchorPosition = new BehaviorSubject<number>(0);

    constructor(private requestsService: APIRequestsService, private translate: TranslateService, private uploaderRequestsService: UploaderRequestsService) {}

    init() {
        this.getStaticAssets();
        this.getAgeRatingOptions();
        this.getCategoryOptions();
        this.getLocales();
        this.getAppcToUsdExchangeRate();
        this.getAppcToEurExchangeRate();

        this.translate.onLangChange.subscribe({
            next: () => {
                this.getAgeRatingOptions();
                this.getCategoryOptions();
                this.getLocales();
            },
        });
    }

    convertCurrency(from: string, to: string) {
        return this.requestsService.get(`/api/wallet/currencyRate?from=${from}&to=${to}`);
    }

    getAppcToUsdExchangeRate() {
        const url = "/api/wallet/appcRate?currency=USD&nocache=1";

        this.requestsService.get(url).subscribe({
            next: (_response: any) => {
                this.appcToUsdExchangeRate.next(_response.rate);
            },
        });
    }

    getAgeRatingOptions() {
        const url = "/api/applications/availableAgeRatings";

        this.requestsService.get(url).subscribe({
            next: (_response: any) => {
                const maturityLevelsAutoCompleteData: AutocompleteEntry[] = _response.map((key) => {
                    return {
                        id: key,
                        label: this.translate.instant("ageRating." + [key.toLowerCase()]),
                    } as AutocompleteEntry;
                });
                this.maturityLevelsAutoCompleteData.next(maturityLevelsAutoCompleteData);
            },
        });
    }

    getLocales() {
        const url = "/api/applications/availableLocales";

        this.requestsService.get(url).subscribe({
            next: (_response: any) => {
                let response = _response;

                response = _response.map((l) => {
                    return {
                        label: this.translate.instant(`languagesList.${l}`),
                        value: l,
                    };
                });
                this.locales.next(response.sort((a, b) => a.label.localeCompare(b.label)));
            },
        });
    }

    getCategoryOptions() {
        const url = "/api/applications/availableCategories";

        this.requestsService.get(url).subscribe({
            next: (_response: any) => {
                const categoriesAutoCompleteData: AutocompleteEntry[] = [];
                _response.map((group) => {
                    group.children.map((sub) => {
                        categoriesAutoCompleteData.push({
                            id: `category.${group.name.toLowerCase()}.${sub.toLowerCase()}`,
                            label: this.translate.instant(`category.${group.name.toLowerCase()}.${sub.toLowerCase()}`),
                            category: group.name,
                            subCategory: sub,
                        } as AutocompleteEntry);
                    });
                });

                this.categoriesAutoCompleteData.next(categoriesAutoCompleteData);
            },
        });
    }

    getAppcToEurExchangeRate() {
        const url = "/api/wallet/appcRate?currency=EUR&nocache=1";

        this.requestsService.get(url).subscribe({
            next: (_response: any) => {
                this.appcToEurExchangeRate.next(_response.rate);
            },
        });
    }

    isMailValid(email) {
        let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(email);
    }

    getUploaderStatics() {
        const url = "/api/utils/statics?nocache=true";

        this.uploaderRequestsService.get(url).subscribe({
            next: (response: any) => {
                this.imageSettings.next(response);
            },
        });
    }

    getStaticAssets() {
        this.getUploaderStatics();
    }

    getValidationForImageData(imageData: ImageDataForValidation) {
        const url = `/api/utils/isValidImage?size=${imageData.size}&type=${imageData.type}&fileMimeType=${imageData.fileMimeType}&height=${imageData.height}&width=${imageData.width}`;

        return this.uploaderRequestsService.get(url);
    }

    returnParsedImageValidationError(rule, imageData) {
        switch (rule.name) {
            case "maximumSize":
                return this.translate.instant("imageRules.maximumSize", {
                    maximumSize: this.imageSettings.value[imageData.type].maxSizeInBytes,
                });
            case "height":
                return this.translate.instant("imageRules.height", {
                    height: this.imageSettings.value[imageData.type].heightInPixels,
                });
            case "width":
                return this.translate.instant("imageRules.width", {
                    width: this.imageSettings.value[imageData.type].widthInPixels,
                });
            case "minimumHeight":
                return this.translate.instant("imageRules.minimumHeight", {
                    minHeight: this.imageSettings.value[imageData.type].minHeightInPixels,
                });
            case "maximumHeight":
                return this.translate.instant("imageRules.maximumHeight", {
                    maxHeight: this.imageSettings.value[imageData.type].maxHeightInPixels,
                });
            case "minimumWidth":
                return this.translate.instant("imageRules.minimumWidth", {
                    minWidth: this.imageSettings.value[imageData.type].minWidthInPixels,
                });
            case "maximumWidth":
                return this.translate.instant("imageRules.maximumWidth", {
                    maxWidth: this.imageSettings.value[imageData.type].maxWidthInPixels,
                });
            case "mimeType":
                return this.translate.instant("imageRules.mimeType", {
                    mimeType: this.stringifyMimeTypesInErrorMessage(this.imageSettings.value[imageData.type].mimeTypes),
                });
            case "dimensionsRatio":
                return this.translate.instant("imageRules.dimensionsRatio");
            default:
                return this.translate.instant("imageRules.default");
        }
    }

    stringifyMimeTypesInErrorMessage(mimeTypes: string[]) {
        if (mimeTypes.length > 1) {
            return "";
        }
        if (mimeTypes.length === 1) {
            return mimeTypes[0];
        }
        if (mimeTypes.length >= 1) {
            let multipleMimeTypesString: string = "";
            for (let index = 0; index < mimeTypes.length; index++) {
                const mimeType = mimeTypes[index];
                if (index === mimeTypes.length - 2) {
                    multipleMimeTypesString += mimeType + " " + this.translate.instant("imageRules.or") + " ";
                } else if (index === mimeTypes.length - 1) {
                    multipleMimeTypesString += mimeType;
                } else {
                    multipleMimeTypesString += mimeType + ", ";
                }
            }
            return multipleMimeTypesString;
        }
    }

    getLocale() {
        switch (window.localStorage.getItem("usedLanguage")) {
            case "en_GB":
                return "en";
            case "zh_CN":
                return "cn";
            case "ko_KR":
                return "ko";
            case "ja_JP":
                return "jp";
            default:
                return "en";
        }
    }
}

export const parseYouTubeVideoUrl = (videoUrl: string) => {
    if (/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/.test(videoUrl)) {
        let ID = "";
        const url = videoUrl.replace(/(>|<)/gi, "").split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);

        if (url[2] !== undefined) {
            ID = url[2].split(/[^0-9a-z_\-]/i)[0];
        } else {
            ID = url[0]; // will not happenwhile the regex only tests for youtube urls... this was used only if user inserts id directly
        }
        return ID;
    } else {
        return "";
    }
};

export const getFileSizeMB = (file: File) => {
    return (file.size || 0) / 1024 / 1024;
};

export function toDataURL(url, callback) {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
        const reader = new FileReader();
        reader.onloadend = function () {
            callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
}

export function openUrlOnNewTab(url: string): void {
    window.open(url, "_blank");
}

export function isValidUrl(url: string): boolean {
    let urlObject: URL = null;

    try {
        urlObject = new URL(url);
    } catch (_) {
        return false;
    }

    return (
        (urlObject.protocol === "http:" && url.startsWith("http://") && url.length > 11 && url.includes(".")) ||
        (urlObject.protocol === "https:" && url.startsWith("https://") && url.length > 12 && url.includes("."))
    );
}

export function isValidGooglePlayUrl(url: string): boolean {
    return isValidUrl(url) && url.includes("https://play.google.com/");
}

export function isValidPackageName(packageName: string): boolean {
    return packageName.match(/^[a-zA-Z][a-zA-Z0-9_]*(\.[a-zA-Z][a-zA-Z0-9_]*)+$/) !== null;
}

export function validateStandardUrl(value: string, requiresHttps: boolean = false) {
    const url: URL = (() => {
        try {
            return new URL(value);
        } catch (e) {}
        return undefined;
    })();

    if (requiresHttps) {
        return url && url.protocol === "https:";
    }

    return url && ["http:", "https:"].includes(url.protocol);
}

export function validateGooglePlayUrl(url: string) {
    url = url.trim();
    return /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g.test(url) && url.includes("https://play.google.com/");
}

export function validateEmailFormat(email: string) {
    let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
}

export function copyTextToClipboard(copy: string) {
    const txtArea = document.createElement("textarea");

    txtArea.style.position = "fixed";
    txtArea.style.top = "0";
    txtArea.style.left = "0";
    txtArea.style.opacity = "0";
    txtArea.value = copy;
    document.body.appendChild(txtArea);
    txtArea.select();
    try {
        const successful = document.execCommand("copy");
        const msg = successful ? "successful" : "unsuccessful";
        if (successful) {
            return true;
        }
    } catch (err) {}
    document.body.removeChild(txtArea);
    return false;
}

export function updateIntercom() {
    if (typeof (<any>window).Intercom !== "undefined") {
        (<any>window).intercomSettings = {
            app_id: "t3lrdtj5",
            custom_launcher_selector: "#intercom-custom-launcher",
            alignment: "right",
            horizontal_padding: 1,
            vertical_padding: 1,
        };
        (<any>window).Intercom("update");
    }
}

export function humanize(str: string): string {
    var i,
        frags = str.split("_");
    for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(" ");
}

export function clone(_object: any) {
    return JSON.parse(JSON.stringify(_object));
}

export function cloneArray(_array: any[]) {
    if (_array && _array.length > 0) {
        return clone(_array);
    }
    return [];
}

export function cloneClassObject(_object: any) {
    return Object.assign(Object.create(Object.getPrototypeOf(_object)), _object);
}

export function getFlagUrl(_countryCode: string) {
    if (!_countryCode || _countryCode === "??" || _countryCode === "ZZ") {
        _countryCode = "UNKNOWN";
    }
    return `https://developers.catappult.io/assets/images/flags/${_countryCode.toUpperCase()}.svg`;
}

export function getPreviousPeriodDates(_from: Date, _to: Date) {
    const diff = Math.abs(_to.getTime()) - Math.abs(_from.getTime());
    const previous_to = new Date(_from.getTime());
    previous_to.setDate(previous_to.getDate() - 1);
    const previous_from = new Date(previous_to.getTime() - diff);

    return {
        from: previous_from,
        to: previous_to,
    };
}

export function convertDateToRequestParameterValue(_date: Date) {
    let dd = _date.getDate().toString();
    let mm = (_date.getMonth() + 1).toString();

    if (dd.length == 1) {
        dd = "0" + dd;
    }
    if (mm.length == 1) {
        mm = "0" + mm;
    }
    return _date.getFullYear() + "-" + mm + "-" + dd;
}

export function serializeObjectToQueryParams(obj: {}): string {
    const queryParameters = [];
    Object.keys(obj).map((key: string) => {
        obj[key];
        if (obj.hasOwnProperty(key) && isNotEmpty(obj[key])) {
            queryParameters.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
        }
    });
    return queryParameters.join("&");
}

export function isNotEmpty(object: any): boolean {
    return !isEmpty(object);
}

export function isEmpty(object: any): boolean {
    return object === null || object === undefined || object === "" || object === "null" || (typeof object === "object" && Object.keys(object).length === 0);
}

export class TableHeader {
    constructor(public index: number, public identifier: string, public label: string, public sortable: boolean) {}
}

export class TableRow {
    constructor(public index: number, public identifier: string, public columns: TableColumn[]) {}
}
export class TableColumn {
    constructor(
        public index: number,
        public headerIdentifier: string,
        public type: "text" | "number" | "icon" | "link" | "date" | "simpleLink" | "subscriptionStatus" | "checkbox",
        public data: any,
        public simpleLinkLabel?: string,
        public rawStatus?: string
    ) {}
}

export class TableRowAction {
    constructor(public identifier: string, public label: string, public iconUrl: string) {}
}
