import { Injectable } from "@angular/core";
import { DataService } from "@app/core/services/data-service";
import { BehaviorSubject } from "rxjs";
import { StatisticsFiltersService } from "../settings.service";

@Injectable({
    providedIn: "root",
})
export class StatisticsRevenueTotalsDataService extends DataService {
    fetch = new BehaviorSubject<boolean>(true);
    loadingData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    data: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    total: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private statisticsFiltersService: StatisticsFiltersService) {
        super(1, !(window.location.href.endsWith("/") || window.location.href.endsWith("/statistics")));
    }

    init() {
        this.statisticsFiltersService.dateFilters.subscribe({
            next: (x: any) => {
                if (x) {
                    this.fetch.next(true);
                }
            },
        });

        this.statisticsFiltersService.dataFilters.subscribe({
            next: (x: any) => {
                if (x) {
                    this.fetch.next(true);
                }
            },
        });

        this.statisticsFiltersService.revenueValueToDisplay.subscribe({
            next: (x: any) => {
                if (x) {
                    this.formatData();
                }
            },
        });

        this.data.subscribe({
            next: (x: any) => {
                if (x) {
                    this.formatData();
                }
            },
        });

        this.fetch.subscribe({
            next: (x: any) => {
                if (x) {
                    this.formatData();
                }
            },
        });
    }

    formatData() {
        this.loadingData.next(true);

        if (this.data.value) {
            this.total.next(this.data.value[this.statisticsFiltersService.revenueValueToDisplay.value.value]);
        }

        this.loadingData.next(false);
    }
}
