<div class="checkbox-container" [class.reversed]="reversed">
    <mat-checkbox *ngIf="pinkTheme" [color]="'primary'" (click)="$event.stopPropagation()" (change)="change($event)" [checked]="checked" [disabled]="disabled"> 
        <p *ngIf="placeholder"  [class.disabled]="disabled" class="xxs thin">{{ placeholder }}</p>

    </mat-checkbox>
    <div *ngIf="greenTheme" class="green-theme">
        <mat-checkbox [color]="'primary'" (change)="change($event)" (click)="$event.stopPropagation()"  [disabled]="disabled" [checked]="checked">
            <p *ngIf="placeholder"  [class.disabled]="disabled" class="xxs thin">{{ placeholder }}</p>
        </mat-checkbox>
    </div>
</div>
