<input [disabled]="disabled" #fileInput type="file" name="image-uploader" style="display: none" [id]="uploaderId" [accept]="acceptedTypes" (change)="selected($event.srcElement.files, $event)" />
<label [for]="uploaderId">
    <div *ngIf="!simpleLayout" appDnd (fileDropped)="onFileDropped($event)" class="uploader" [class.error]="errors.length">
        <ng-container *ngIf="!imgSrc && !srcBase64 && !loading">
            <img class="icon" [src]="uploadIcon" />
            <div class="text-container">
                <div *ngIf="title" translate class="title" [class.break-line-title]="breakLineTitle" [class.error]="errors.length">
                    {{ title }}
                </div>
                <div *ngIf="subtitle && !errors.length" translate class="subtitle">
                    {{ subtitle }}
                </div>
                <div *ngIf="errors.length" class="subtitle error">{{ "imageRules.errorPlaceholder" | translate }}:</div>
                <div *ngFor="let error of errors" class="error-text">
                    {{ error }}
                </div>
            </div>
        </ng-container>

        <app-loading-spinner *ngIf="loading" size="big" loadingMessage="{{ 'imageRules.loading' | translate }}"> </app-loading-spinner>

        <img *ngIf="(imgSrc || srcBase64) && !loading" class="image" [src]="imgSrc || srcBase64" (error)="img.src = 'assets/images/appicon-fallback.svg'" #img />
    </div>
    <div *ngIf="simpleLayout">
        <div *ngIf="!loading" class="simple-layout-container">
            <div class="choose-file-container">
                <div class="button-container">
                    <app-button [disabled]="disabled" [veryVerySmall]="true" [veryRoundedCorners]="true" label="{{ 'common.chooseFile' | translate }}"></app-button>
                </div>
                <div class="label-container">
                    <p *ngIf="!imgSrc && !srcBase64 && !errors.length" translate>common.noFileChosen</p>
                    <p *ngIf="errors.length" class="xxs red" translate>imageRules.errorPlaceholder</p>
                </div>
            </div>

            <div class="text-section">
                <p class="xxxs blueishGray" *ngIf="subtitle && !errors.length" [innerHTML]="subtitle"></p>
                <div *ngIf="errors.length" class="error-box">
                    <p class="xxxs red" *ngFor="let error of errors">&#x2022; {{ error }}</p>
                </div>
            </div>
        </div>
        <app-loading-spinner *ngIf="loading" loadingMessage="{{ 'imageRules.loading' | translate }}"> </app-loading-spinner>
    </div>
</label>
