import { Injectable } from "@angular/core";
import { UserService } from "./user.service";

enum IdPermissions {
    PACKAGES = "packages",
    WALLET = "wallet",
    USERS = "users",
    DASHBOARD = "dashboard",
    ADMINS = "admins",
    CHANGEACCOUNTOWNERSHIP = "changeAccountOwnerShip",
}
enum AccessLevels {
    ALL = "*",
    CREATE = "create",
    READ = "read",
    UPDATE = "update",
    DELETE = "delete",
}
@Injectable({
    providedIn: "root",
})
export class PermissionsService {
    walletAccessLevels: any;
    myAppsAccessLevels: any;

    canDashboard: boolean;
    canWallet: boolean;
    canMyApps: boolean;
    allowedPaths: any[] = [];
    constructor(private userService: UserService) {}

    checkMenuItemMyAppsPermissions() {
        if (!this.userService.loggedUser || !this.userService.loggedUser.value || !this.userService.loggedUser.value.permissions) {
            return false;
        }

        this.myAppsAccessLevels = this.userService.loggedUser.value.permissions.filter((perm: any) => {
            return perm.name === IdPermissions.PACKAGES;
        });

        if (this.myAppsAccessLevels.length) {
            return this.myAppsAccessLevels[0].accessLevels.includes(AccessLevels.ALL) || this.myAppsAccessLevels[0].accessLevels.includes(AccessLevels.CREATE);
        }

        return false;
    }

    checkMenuItemDashboardPermissions() {
        if (!this.userService.loggedUser || !this.userService.loggedUser.value || !this.userService.loggedUser.value.permissions) {
            return false;
        }

        const dashboardAccessLevels: any[] = this.userService.loggedUser.value.permissions.filter((perm: any) => {
            return perm.name === IdPermissions.DASHBOARD;
        });
        if (dashboardAccessLevels.length) {
            return (
                dashboardAccessLevels[0].accessLevels.includes(AccessLevels.ALL) ||
                dashboardAccessLevels[0].accessLevels.includes(AccessLevels.CREATE) ||
                dashboardAccessLevels[0].accessLevels.includes(AccessLevels.READ)
            );
        }

        return false;
    }

    checkMenuItemWalletPermissions() {
        if (!this.userService.loggedUser || !this.userService.loggedUser.value || !this.userService.loggedUser.value.permissions) {
            return false;
        }

        if (this.userService?.loggedAccount?.value?.hideWallet) {
            return false;
        }

        this.walletAccessLevels = this.userService.loggedUser.value.permissions.filter((perm: any) => {
            return perm.name === IdPermissions.WALLET;
        });
        if (this.walletAccessLevels.length) {
            return (
                this.walletAccessLevels[0].accessLevels.includes(AccessLevels.ALL) ||
                this.walletAccessLevels[0].accessLevels.includes(AccessLevels.CREATE) ||
                this.walletAccessLevels[0].accessLevels.includes(AccessLevels.READ)
            );
        }

        return false;
    }

    checkWalletChilds(subItem: string) {
        if (this.walletAccessLevels.length) {
            switch (subItem) {
                case "withdraw": {
                    return this.walletAccessLevels[0].accessLevels.includes(AccessLevels.ALL) || this.walletAccessLevels[0].accessLevels.includes(AccessLevels.CREATE);
                }
                case "balance": {
                    return (
                        this.walletAccessLevels[0].accessLevels.includes(AccessLevels.ALL) ||
                        this.walletAccessLevels[0].accessLevels.includes(AccessLevels.CREATE) ||
                        this.walletAccessLevels[0].accessLevels.includes(AccessLevels.READ)
                    );
                }
                default:
                    return false;
            }
        }
        return false;
    }

    allowedPlaces() {
        this.allowedPaths.push("dashboard");

        this.canDashboard = this.checkMenuItemDashboardPermissions();
        this.canMyApps = this.checkMenuItemMyAppsPermissions();
        this.canWallet = this.checkMenuItemWalletPermissions();

        if (this.canDashboard) {
            this.allowedPaths.push("statistics");
        }
        if (this.canMyApps) {
            this.allowedPaths.push("myApps");
        }

        if (this.canWallet) {
            this.allowedPaths.push("wallet");
        }

        this.allowedPaths.push("notifications");
        this.allowedPaths.push("settings");
        this.allowedPaths.push("payments");
        this.allowedPaths.push("underMaintenance");
    }
}
